/* eslint-disable no-unused-vars */
import axios from "axios"
import { BASE_URL } from '../../../../services/config'

const RESOURCE = '/admin/publicity'
const actions = {
    getPublicities({commit}) {
        const config = {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('token')}`,
            }
        }
        commit('loading/CHANGE_LOADING', true, { root: true })
        return new Promise((resolve, reject) => {
            axios.get(`${BASE_URL}${RESOURCE}`, config)
            .then(res => {
                commit('GET_PUBLICITIES', res.data)
                resolve(res)
            })
            .catch(err => {
                reject(err)
            })
            .finally(() => {commit('loading/CHANGE_LOADING', false, { root: true })})
        })
    },
    newPublicity({commit}, params) {
        const config = {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('token')}`,
            }
        }
        commit('loading/CHANGE_LOADING', true, { root: true })
        return new Promise((resolve, reject) => {
            axios.post(`${BASE_URL}${RESOURCE}`, params, config)
            .then(res => resolve(res))
            .catch(err => reject(err))
            .finally(() => {commit('loading/CHANGE_LOADING', false, { root: true })})
        })
    },
    destroyPublicity({commit},params){
        const config = {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('token')}`,
            }
        }
        commit('loading/CHANGE_LOADING', true, { root: true })
        return new Promise((resolve, reject) => {
            axios.delete(`${BASE_URL}${RESOURCE}/${params.id}`, config)
            .then(res => resolve(res))
            .catch(err => reject(err))
            .finally(() => {commit('loading/CHANGE_LOADING', false, { root: true })})
        })
    }
}

export default actions