import axios from "axios"
import { BASE_URL } from '../../../../services/config'

const RESOURCE = '/admin/empregos-users'

const actions = {
    getUsers ({commit}) {
        const config = {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('token')}`,
            }
        }
        commit('loading/CHANGE_LOADING', true, { root: true })
        return new Promise((resolve, reject) => {
            axios.get(`${BASE_URL}${RESOURCE}`, config)
            .then((res) => {
                commit('GET_USERS', res.data)
                resolve(res)
            })
            .catch(err => reject(err))
            .finally(() => {commit('loading/CHANGE_LOADING', false, { root: true })})
        })
    },
    destroyUser ({commit}, params) {
        const config = {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('token')}`,
            }
        }
        commit('loading/CHANGE_LOADING', true, { root: true })
        return new Promise((resolve, reject) => {
            axios.delete(`${BASE_URL}${RESOURCE}/${params.id}`, config)
                .then(res => {
                    resolve(res)
                })
                .catch(err => {
                    reject(err)
                })
                .finally(() => {commit('loading/CHANGE_LOADING', false, { root: true })})
        
        })
    }
}

export default actions;