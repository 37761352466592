<template>
    <div>
        <v-row wrap align="center" justify="space-between">
            <v-col cols="12" md="6">
				<v-btn
					router
					small
					elevation="4"
                    :to="{ name: 'admin.careers' }"
					dark
					color="red acent-3"
                    tile
				>
					Voltar			
				</v-btn>
            </v-col>
        </v-row>
        <career-page :career="career.careerPage" :content="career.vagas"/>
        <footer-component/>
        <v-row wrap align="center" justify="space-between">
            <v-col cols="12" md="6">
				<v-btn
					router
					small
					elevation="4"
                    @click="removeCareer(career.careerPage)"
					dark
					color="red acent-3"
                    tile
				>
					Apagar Pagina			
				</v-btn>
				<v-btn
                    :to="{ name: 'admin.careers.edit', params: {id: career.careerPage.id} }"
					router
					small
					elevation="4"
					dark
					color="red acent-3"
                    class="ml-4"
                    tile
				>
					Editar pagina			
				</v-btn>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import axios from 'axios'
import {BASE_URL} from '../../../services/config'
import navComponent from './layout/nav.vue'
import footerComponent from './layout/footer.vue'
import careerPage from './layout/careerPage.vue'
import Swal from 'sweetalert2'

export default {
    // eslint-disable-next-line vue/no-unused-components
    components: {navComponent, footerComponent, careerPage},
    props: {
        slug: {
            required: true
        }
    },
    data() {
        return {
            career: {
                careerPage: {},
                vagas: []
            }
        }
    },
    created() {
        this.getCarrerPage(this.slug)
    },
    methods: {
        async getCarrerPage(slug) {
            try {
                const config = {
                    headers: {
                        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
                    }
                }
                this.$store.dispatch('loading/showLoader')
                this.career = await (await axios.get(`${BASE_URL}/admin/careers/${slug}`, config)).data.data
                this.$store.dispatch('loading/closeLoader')
            } catch (error) {
                this.$store.dispatch('loading/closeLoader')
            }
        },
        async deletePage(id) {
            try {
                await this.$store.dispatch('empresas/deleteCareerPage', {id: id})
                Swal.fire({
                    title: "Sucesso!",
                    text: "Pagina de careiras apagada com sucesso!",
                    icon: "success",
                    confirmButtonText: "Ok",
                    confirmButtonColor: "#FF1744",
                }).then(() => {
                    this.$router.push({ name: "admin.careers" });
                });
            } catch (error) {
                console.log(error)
            }
        },
        removeCareer(career) {
			const warning = `Deseja remover a pagina de carreiras da ${career.empresa.name}?`;

			Swal.fire({
				title: "Atenção!",
				text: warning,
				icon: "warning",
				showCancelButton: true,
				confirmButtonText: "Sim",
				cancelButtonText: "Não",
				confirmButtonColor: "#FF1744",
				cancelButtonColor: "#FF1744",
			}).then((result) => {
				if (result.isConfirmed) {
					this.deletePage(career.id);
				}
			});
		}
    }
}
</script>

<style>

</style>