<template>
    <div>
        <v-row align="center">
			<v-col cols="6" md="6" class="pl-0 mb-6">
				<v-btn
					:to="{ name: !toAprove ? 'admin.vagas' : 'admin.vagas-toaprove'}"
					class="ml-0"
					tile
					elevation="4"
					dark
					color="red acent-3"
					small
				>
					<v-icon left small> mdi-arrow-left </v-icon>Voltar</v-btn
				>
			</v-col>
			<v-col cols="6" md="6" class="text-right">
				<v-avatar size="80" height="auto" v-if="content.image">
					<img :src="content.image" alt=""/>
				</v-avatar>
			</v-col>
		</v-row>
        <v-row justify="center">
			<v-col cols="10">
				<details-component :content="content"/>
			</v-col>
		</v-row>
        <v-row>
			<v-col cols="12" class="">
                
				<v-btn v-if="content.id"
					:to="{ name: 'admin.vagas.edit', params: {id: content.id, toAprove: toAprove} }"
					tile
					small
					elevation="4"
					dark
					color="red accent-3"
					><v-icon small class="mr-2">mdi-pencil-outline</v-icon> Editar</v-btn
				>
				<v-btn
					v-if="toAprove"
					@click.prevent="aproveContent(content.id)"
					tile
					small
					elevation="4"
					dark
					color="red accent-3"
					class="ml-4"
					><v-icon small class="mr-2">mdi-delete-alert-outline</v-icon>
					Aprovar vaga</v-btn
				>
				<v-btn
					@click.prevent="removeContentWarning(content)"
					tile
					small
					elevation="4"
					dark
					color="red accent-3"
					class="ml-4"
					><v-icon small class="mr-2">mdi-delete-alert-outline</v-icon>
					Apagar</v-btn
				>
			</v-col>
		</v-row>
    </div>
</template>

<script>
import DetailsComponent from '../general/DetailsComponent.vue'
import Swal from 'sweetalert2'
export default {
    components: {DetailsComponent},
    props: {
        id: {
            required: true
        },
        toAprove: {
            required: false,
			type: Boolean,
			default: false,
        }
    },
    data() {
        return {
            content: {
				image: "",
				titulo: "",
				instituicao: "",
				validade: "",
				categoria_id: "",
				local: "",
				fonte: "",
				conteudo: "",
			},
        }
    },
    created() {
        this.getVaga(this.id)
    },
    methods: {
        getVaga(id) {
            this.$store.dispatch("vagas/getVaga", id)
            .then((res) => {
                this.content = res.data.data
            })
        },
        removeContentWarning(content) {
			const warning = `Deseja remover o anúncio ${content.titulo}?`;

			Swal.fire({
				title: "Atenção!",
				text: warning,
				icon: "warning",
				showCancelButton: true,
				confirmButtonText: "Sim",
				cancelButtonText: "Não",
				confirmButtonColor: "#FF1744",
				cancelButtonColor: "#FF1744",
			}).then((result) => {
				if (result.isConfirmed) {
					this.removeContent(content.id);
				}
			});
		},
		removeContent(content_id) {
			
			this.$store
				.dispatch("vagas/removeVaga", content_id)
				.then(() => {
					Swal.fire({
						title: "Sucesso!",
						text: 'Removido com sucesso',
						icon: "success",
						confirmButtonText: "Ok",
						confirmButtonColor: "#FF1744",
					})
					.then( () => {
						this.$router.push({name: 'admin.vagas'})
					});
				});
		},
		aproveContent(content_id) {
			this.$store.dispatch('vagas/aproveRecruiterContent', {id: content_id, estado: true})
                .then(() => {
                    Swal.fire({
					title: "Sucesso!",
					text: "Vaga aprovado com sucesso!",
					icon: "success",
					confirmButtonText: "Ok",
					confirmButtonColor: "#FF1744",
                    }).then(() => {
                        this.getVaga(content_id)
                        this.toAprove = false
                    });
                })
		}
    }
}
</script>

<style>

</style>