<template>
    <v-container fluid fill-height style="background-color: #F0F2F5">
        <v-row wrap justify="center" align-center>
            <v-col cols="12" md="3">
                <v-card min-width="500px" >
                    <v-toolbar color="secondary" dark>
                        <v-row justify="center" align="center">
                            Recuperar senha
                        </v-row>
                    </v-toolbar>
                    <v-container>
                        <v-form @submit.prevent="forgotPassword">
                            <v-card-text>
                                <v-text-field v-model="formData.email" label="Email" />
							</v-card-text>
                            <v-card-actions>
                                <v-btn
								type="submit"
								small
								elevation="4"
								dark
                                color="secondary"
								>Enviar</v-btn
								>
								<v-spacer></v-spacer>
								<v-btn
								small
								elevation="4"
								outlined
								dark
								color="secondary"
								:to="{name: 'admin.login'}"
								>Cancelar</v-btn>
							</v-card-actions>
                        </v-form>
                    </v-container>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import Swal from "sweetalert2";
export default {
    data() {
        return {
            formData: {
                email: ""
            }
        }
    },
    methods: {
        forgotPassword() {
            this.$store
				.dispatch("auth/forgotPassword", this.formData)
				.then(() => {
					Swal.fire({
						title: "Sucesso!",
						text: "A sua senha foi redifinida, receberá um email com a nova senha dentro de instantes!",
						icon: "success",
						confirmButtonText: "OK",
						confirmButtonColor: "#FF1744",
					});

					this.$router.push({ name: "admin.login" });
				})
        }
    }
}
</script>

<style>

</style>