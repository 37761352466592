<template>
    <v-container>
        <v-row wrap justify="space-between">
			<v-col
				sm="12"
				md="12"
				class="mb-3"
			>
					<empregos-users-component :users="user"/>
                    <!--<test-component/>-->
			</v-col>
		</v-row>
    </v-container>
</template>

<script>
import empregosUsersComponent from './empregosUsersComponent.vue'
export default {
    components: {/*empregosUsersComponent*/ empregosUsersComponent},
    data(){
        return {
        }
    },
    created() {
        this.getEmpregosUsers()
    },
    methods: {

        getEmpregosUsers() {
            this.$store.dispatch("empregos/getUsers")
        }
    },
    computed: {
        user() {
            return this.$store.state.empregos.items
        }
    }
    /*watch: {
        user(user){
            console.log(user)
            return [
                {
                    'id': user.id,
                    'name': user.name,
                    'active': user.active,
                    'newsletter': user.newsletter,
                    'education': user.education == [] ? 'preenchido' : 'não preenchido'
                }
            ]
        }
    }*/
}
</script>

<style>

</style>