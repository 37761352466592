var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){return [_c('div',[_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitNotification.apply(null, arguments)}}},[_c('v-card',{attrs:{"max-height":"1000px"}},[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.notificationType === 1 ? 'Enviar newsletter!' : 'Notificar usuarios!'))])]),_c('v-card-subtitle',[_vm._v(" "+_vm._s(_vm.totalUsers)+" usuarios serão notificados ")]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('validation-provider',{attrs:{"name":"assunto","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{ref:"assunto",attrs:{"label":"Assunto","placeholder":"Assunto do email","error-messages":errors,"required":""},model:{value:(_vm.emailSubject),callback:function ($$v) {_vm.emailSubject=$$v},expression:"emailSubject"}})]}}],null,true)})],1)],1),(_vm.notificationType === 1)?_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"return-value":_vm.date,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){_vm.date=$event},"update:return-value":function($event){_vm.date=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"dense":"","label":"Selecione a Data","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.dateFilter),callback:function ($$v) {_vm.dateFilter=$$v},expression:"dateFilter"}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"dense":"","no-title":"","scrollable":""},model:{value:(_vm.dateFilter),callback:function ($$v) {_vm.dateFilter=$$v},expression:"dateFilter"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.menu = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.menu.save(_vm.date)}}},[_vm._v(" OK ")])],1)],1)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{staticClass:"mx-4",attrs:{"exact":"","small":"","elevation":"4","color":"red accent-3","dark":""},on:{"click":_vm.getEmpregos}},[_vm._v(" Gerar newsletter ")])],1)],1):_vm._e(),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"Título","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vue-editor',{ref:"conteudo",staticStyle:{"height":"350px"},attrs:{"placeholder":"Corpo do email","editor-toolbar":_vm.customToolbar,"aria-errormessage":errors,"required":""},model:{value:(_vm.emailBody),callback:function ($$v) {_vm.emailBody=$$v},expression:"emailBody"}})]}}],null,true)})],1)],1)],1),_c('v-card-actions',[_c('v-btn',{staticClass:"mx-4",staticStyle:{"margin-top":"50px"},attrs:{"type":"submite","exact":"","small":"","elevation":"4","color":"red accent-3","dark":""}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v(" mdi-send ")]),_vm._v(" Enviar notificação ")],1),_c('v-btn',{staticClass:"mx-4",staticStyle:{"margin-top":"50px"},attrs:{"exact":"","outlined":"","small":"","elevation":"4","color":"red accent-3","dark":""},on:{"click":_vm.closeDialog}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v(" mdi-cancel ")]),_vm._v(" Cancelar ")],1)],1)],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }