<template>
    <v-row justify="center">
		<v-col cols="12" sm="10" md="10">
            <career-form :empresas="empresas" :updating="false"/>
		</v-col>
	</v-row>
</template>

<script>
import {BASE_URL} from '../../../services/config'
import axios from 'axios'
import careerForm from './partials/careerForm.vue'
export default {
    components: {careerForm},
    data () {
        return {
            empresas: []
        }
    },
    created() {
        this.getEmpresas()
    },
    methods: {
        async getEmpresas() {
            try {
                const config = {
                    headers: {
                        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
                    }
                }
                this.$store.dispatch('loading/showLoader')
                const empresas = await axios.get(`${BASE_URL}/admin/empresa/all`, config)
                this.empresas = empresas.data.data
                console.log(this.empresas)
                this.$store.dispatch('loading/closeLoader')
            } catch (error) {
                console.log(error)
            }
        }
    }

}
</script>

<style>

</style>