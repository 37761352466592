<template>
    <v-app>
        <main-nav/>
        <v-main>
            <v-container class="mt-6" style="max-width:1161px;">
                <router-view ></router-view>
            </v-container>
        </v-main>
    </v-app>
</template>

<script>
import MainNav from './layout/nav/mainNav.vue'
export default {
    components: {MainNav}

}
</script>

<style>

</style>