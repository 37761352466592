<template>
    <v-container>
        <v-row wrap justify="space-between">
			<v-col
				sm="12"
				md="6"
				v-for="vaga in vagas.data"
				:content="vaga"
				:key="vaga.id"
				class="mb-3"
			>
					<card-component
						:content="vaga"
                        :toAprove="true"
						routeDetails="admin.vagas.show"
					></card-component>
			</v-col>
		</v-row>
		
		<v-row>
			<v-col xs="12">
				<v-pagination
					v-model="vagas.current_page"
					:length="vagas.last_page"
					@input="handleChangePage"
					color="error"
					total-visible="7"
				></v-pagination>
			</v-col>
		</v-row>
    </v-container>
</template>

<script>
import CardComponent from '../general/CardComponent.vue'
export default {
    components: {CardComponent},
    created() {
		this.getVagas(1)
	},
	methods: {
		getVagas(page) {
			
			this.$store.dispatch("vagas/getVagasNotAproved", {
				page
			})
			
			
		},
		handleChangePage(page) {
			if(this.search){
				this.$store.dispatch("vagas/filterVagasNotAproved", {
					page,
					filter: this.search
				})
			} else {
				this.getVagas(page);
			}
			
		},
		searchContentEvent(filter){
			this.$store.dispatch("vagas/filterVagasNotAproved", {
				page: 1,
				filter: filter
			}).then(() => {
				this.search = filter
				this.search_alert = true
			})
			console.log(filter)
		},
		resetSearchResults(){
			this.getVagas(1)
			this.search =""
			this.search_alert = false
		},
    },
	computed: {
		vagas() {
			return this.$store.state.vagas.items
		}
    }
}
</script>

<style>

</style>