import { BASE_URL } from '../../../services/config'
import axios from 'axios'

export default {
    namespaced: true,
    state: {
        user: {

        },
        token: '',
        authenticated: false,

    },
    mutations: {
        AUTH_USER_OK(state, payload) {
            state.user = payload.user,
            state.authenticated = true,
            state.token = payload.token
        },
        AUTH_LOGOUT(state) {
            state.user = {},
            state.token = '',
            state.authenticated = false
        },
        SET_USER(state, payload) {
            state.user = payload.user,
            state.authenticated = true,
            state.token = payload.token
        },
    },
    actions: {
        login ({commit}, params) {
            return new Promise((resolve, reject) => {
                commit('loading/CHANGE_LOADING', true, { root: true })
                    axios({
                        url: `${BASE_URL}/admin/login`,
                        data: params,
                        method: 'POST'
                    }).then(res => {
                        commit('AUTH_USER_OK', res.data)
                        sessionStorage.setItem('token', res.data.token)
                        resolve(res)
                    }).catch(err => {
                        console.log(err)
                        reject(err)
                    }).finally(() => {
                        commit('loading/CHANGE_LOADING', false, { root: true })
                    })
            })              

        },
        logout({commit}){
            commit('loading/CHANGE_LOADING', true, { root: true })
            const config = {
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem('token')}`,
                }
            }
            return new Promise((resolve, reject) => {
                axios.get(`${BASE_URL}/admin/logout`, config)
                .then(res => {
                    sessionStorage.removeItem('token')
                    commit('AUTH_LOGOUT')
                    resolve(res)
                })
                .catch(err => {
                    reject(err)
                })
                .finally(() => {
                    commit('loading/CHANGE_LOADING', false, { root: true })
                })
            })
        },
        checkAuthenticated({commit}) {
            commit('loading/CHANGE_LOADING', true, { root: true })
            const config = {
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem('token')}`,
                }
            }
            return new Promise((resolve, reject) => {
                axios.post(`${BASE_URL}/admin/me`, {},config)
                .then(res => {
                    //console.log(res.data)
                    const token = sessionStorage.getItem('token')
                    //console.log(token)
                    const data = {
                        user: res.data,
                        token: token
                    }
                    commit('SET_USER', data)
                    resolve(res)
                })
                .catch(err => {
                    sessionStorage.removeItem('token')
                    reject(err)
                })
                .finally(() => {
                    commit('loading/CHANGE_LOADING', false, { root: true })
                })
            })
        },
        forgotPassword({commit}, params) {
            commit('loading/CHANGE_LOADING', true, { root: true })
            return new Promise((resolve, reject) => {
                axios.post(`${BASE_URL}/admin/forgot_password`, params)
                .then(res => resolve(res))
                .catch(err => reject(err))
                .finally(() => {commit('loading/CHANGE_LOADING', false, { root: true })})
            })
        },
        changePassword({commit}, params) {
            commit('loading/CHANGE_LOADING', true, { root: true })
            const config = {
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem('token')}`,
                }
            }
            return new Promise((resolve, reject) => {
                axios.post(`${BASE_URL}/admin/change_password`, params, config)
                .then(res => resolve(res))
                .catch(err => reject(err))
                .finally(() => {commit('loading/CHANGE_LOADING', false, { root: true })})
            })
        }
    }
}