/* eslint-disable vue/require-v-for-key */
<template>
  <v-card>
    <v-card-actions>
      <v-row wrap>
        <v-col cols="2">
          <v-btn
            small
            elevation="4"
            dark
            color="red acent-3"
            tile
            style="margin-left: 25px;"
            @click="
              () => {
                dialogNewslleterNotification = true;
              }
            "
          >
            Enviar newsletter
          </v-btn>
        </v-col>
        <v-col cols="3">
          <v-btn
            small
            elevation="4"
            dark
            color="red acent-3"
            tile
            style="margin-left: 25px;"
            @click="whatsappSendNewsletter"
          >
            Enviar whatsapp newsletter
          </v-btn>
        </v-col>
        <v-col cols="2">
          <v-btn
            small
            elevation="4"
            dark
            color="red acent-3"
            tile
            @click="notifyUsers"
          >
            Notificar usuarios
          </v-btn>
        </v-col>
        <v-col cols="2">
          <v-btn
            small
            elevation="4"
            dark
            color="red acent-3"
            tile
            @click="
              () => {
                dialogNewsletter = true;
              }
            "
          >
            Gerar newsletter
          </v-btn>
        </v-col>
        <v-col cols="3">
          <v-btn
            small
            elevation="4"
            dark
            color="red acent-3"
            tile
            @click="
              () => {
                expiredDialog = true;
              }
            "
          >
            Notificar subscrição expirada
          </v-btn>
        </v-col>
        <!-- <v-col cols="3">
                    <v-btn 
                        small
                        elevation="4"
                        dark
                        color="red acent-3"
                        tile
                        @click="()=>{dialog1D = true}">
                        Notificar 1 dia subscrição
                    </v-btn>
                </v-col>
                <v-col cols="3">
                    <v-btn 
                        small
                        elevation="4"
                        dark
                        color="red acent-3"
                        tile
                        @click="()=>{dialog2D = true}">
                        Notificar 2 dia subscrição
                    </v-btn>
                </v-col>
                <v-col cols="3">
                    <v-btn 
                    small
                    elevation="4"
                    dark
                    color="red acent-3"
                    tile
                    @click="()=>{dialog3D = true}">
                        Notificar 3 dia subscrição
                    </v-btn>
                </v-col>
                <v-col cols="2">
                    <v-btn 
                        small
                        elevation="4"
                        dark
                        color="red acent-3"
                        tile
                        @click="()=>{dialog5D = true}">
                        Notificar 5 dia subscrição
                    </v-btn>
                </v-col> -->
      </v-row>

      <br />
    </v-card-actions>
    <v-row >
      <validation-observer ref="observer" v-slot="{}">
        <v-dialog v-model="dialogNewsletter" persistent width="800px">
          <v-card max-height="1000px">
            <v-card-title>
              <span class="text-h5">Newsletter</span>
            </v-card-title>
            <v-card-text>
              <v-row >
                <v-col cols="6">
                  <v-menu
                    ref="menu"
                    v-model="menu2"
                    :close-on-content-click="false"
                    :return-value.sync="date"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        dense
                        v-model="dateFilter2"
                        label="Selecione a Data"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      dense
                      v-model="dateFilter2"
                      no-title
                      scrollable
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="menu2 = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.menu.save(date)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="6">
                  <v-btn
                    exact
                    small
                    elevation="4"
                    color="red accent-3"
                    dark
                    class="mx-4"
                    @click="getConcursoCopy"
                  >
                    Gerar newsletter
                  </v-btn>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="10">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Título"
                    rules="required"
                  >
                    <v-textarea
                      dense
                      clear-icon="mdi-close-circle"
                      placeholder="newsletter para ser enviado pelo whatsapp, facebook ou linkedIn."
                      :aria-errormessage="errors"
                      v-model="newslleterCopy"
                      value="This is clearable text."
                      style="padding: 10px; height: 500px"
                    ></v-textarea>
                  </validation-provider>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-btn
                exact
                small
                elevation="4"
                color="red accent-3"
                dark
                class="mx-4"
                @click="closeDialogNewsletter"
              >
                Fechar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </validation-observer>
    </v-row>
    <!-- Send newsletter direct from the app -->
    <v-row>
      <validation-observer ref="observer" v-slot="{}">
        <v-dialog
          v-model="dialogNewslleterNotification"
          persistent
          width="800px"
        >
          <notification-form
            :users_id="users"
            :notificationType="1"
            :notify_type="1"
            @closeDialog="
              () => {
                dialogNewslleterNotification = false;
              }
            "
          />
        </v-dialog>
      </validation-observer>
    </v-row>
    <v-row>
      <validation-observer ref="observer" v-slot="{}">
        <v-dialog v-model="dialogEmail" persistent width="800px">
          <notification-form
            v-bind:users_id.sync="selected"
            :notificationType="2"
            :notify_type="2"
            @closeDialog="
              {
                dialogEmail = false;
              }
            "
          />
        </v-dialog>
      </validation-observer>
    </v-row>
    <v-row>
      <validation-observer ref="observer" v-slot="{}">
        <v-dialog v-model="expiredDialog" persistent width="800px">
          <notification-form
            v-bind:users_id.sync="users"
            :notificationType="3"
            :notify_type="2"
            period="expired"
            @closeDialog="
              {
                expiredDialog = false;
              }
            "
          />
        </v-dialog>
      </validation-observer>
    </v-row>
    <v-row>
      <validation-observer ref="observer" v-slot="{}">
        <v-dialog v-model="dialog1D" persistent width="800px">
          <notification-form
            v-bind:users_id.sync="users"
            :notificationType="3"
            :notify_type="2"
            period="1d"
            @closeDialog="
              {
                dialog1D = false;
              }
            "
          />
        </v-dialog>
      </validation-observer>
    </v-row>
    <v-row>
      <validation-observer ref="observer" v-slot="{}">
        <v-dialog v-model="dialog2D" persistent width="800px">
          <notification-form
            v-bind:users_id.sync="users"
            :notificationType="3"
            :notify_type="2"
            period="2d"
            @closeDialog="
              {
                dialog2D = false;
              }
            "
          />
        </v-dialog>
      </validation-observer>
    </v-row>
    <v-row>
      <validation-observer ref="observer" v-slot="{}">
        <v-dialog v-model="dialog3D" persistent width="800px">
          <notification-form
            v-bind:users_id.sync="users"
            :notificationType="3"
            :notify_type="2"
            period="3d"
            @closeDialog="
              {
                dialog3D = false;
              }
            "
          />
        </v-dialog>
      </validation-observer>
    </v-row>
    <v-row>
      <validation-observer ref="observer" v-slot="{}">
        <v-dialog v-model="dialog5D" persistent width="800px">
          <notification-form
            v-bind:users_id.sync="users"
            :notificationType="3"
            :notify_type="2"
            period="5d"
            @closeDialog="
              {
                dialog5D = false;
              }
            "
          />
        </v-dialog>
      </validation-observer>
    </v-row>
    <v-card-title>
      <v-text-field
        style="margin-top: 10px;"
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
      v-model="selected"
      :headers="headers"
      :items="filterUsers"
      :search="search"
      class="elevation-1"
      show-select
        
      
    >
      <template
        v-for="(col, index) in filters"
        v-slot:[`header.${index}`]="{ header }"
        
      >
        {{ header.text }}
        <v-menu
        
          :key="index"
          offset-y
          transition="slide-y-transition"
          :close-on-content-click="false"
          fixed
          style="position: absolute; right: 0;"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="indigo" icon v-bind="attrs" v-on="on">
              <v-icon small :color="filters[header.value].length ? 'red' : ''">
                mdi-filter-variant
              </v-icon>
            </v-btn>
          </template>
          <div style="background-color: white; width: 280px;">
            <v-list flat dense class="pa-0">
              <v-list-item>
                <div v-if="filters.hasOwnProperty(header.value)">
                  <v-autocomplete
                    multiple
                    dense
                    auto-select-first
                    clearable
                    chips
                    small-chips
                    color="light-blue lighten-3"
                    :items="columnValueList(header.value)"
                    append-icon="mdi-filter"
                    v-model="filters[header.value]"
                    :label="
                      filters[header.value] ? `filter_by: ${header.text}` : ''
                    "
                    hide-details
                  >
                    <template v-slot:selection="{ item, index }">
                      <v-chip small class="caption" v-if="index < 5">
                        <span>
                          {{ item }}
                        </span>
                      </v-chip>
                      <span v-if="index === 5" class="grey--text caption">
                        (+{{ filters[header.value].length - 5 }} others)
                      </span>
                    </template>
                  </v-autocomplete>
                </div>
              </v-list-item>
            </v-list>
          </div>
        </v-menu>
      </template>
      <template v-slot:top>
        <v-toolbar flat absolute>
          <v-dialog v-model="dialog" width="500px">
            <v-card>
              <v-card-title>
                <span class="text-h5">Preferences</span>
              </v-card-title>
              <v-card-subtitle>
                <span>{{ editedUser.email }}</span>
              </v-card-subtitle>
              <v-card-text>
                <v-container>
                  <v-checkbox
                    v-model="editedUser.verified"
                    color="primary"
                    label="Pode visualizar a base de dados de candidato"
                  >
                  </v-checkbox>
                  <v-checkbox
                    readonly
                    v-model="editedUser.newsletter"
                    color="primary"
                    label="Pode receber newslleter"
                  >
                  </v-checkbox>
                  <!-- <v-select
                    :items="months"
                    v-model="subscribeMonths"
                    label="Selecione os meses da subscrição"
                  >
                  </v-select> -->
                  <v-row>
                    <v-col cols="12">
                      <v-btn
                      small
                      elevation="4"
                      dark
                      color="red acent-3"
                      tile 
                      @click="mpesaDialog()">
                        Atualização de Subscrição
                      </v-btn>
                    </v-col>
                  </v-row>
                  
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-btn
                  small
                  elevation="4"
                  color="red accent-3"
                  dark
                  text
                  @click="submit"
                  >Submeter</v-btn
                >
                <v-btn
                  exact
                  outlined
                  small
                  elevation="4"
                  color="blue accent-3"
                  dark
                  class="mx-4"
                  text
                  @click="closeDialog"
                  >Cancel</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
        <v-toolbar flat absolute>
          <v-dialog v-model="dialogPayment" persistent width="260">
            <v-card color="#B61827" dark>
              <v-card-title class="text-h5">
                Atualização de subscrição
              </v-card-title>
              <img src="../../../../assets/pagarMpesa.png" width="250" alt="">

              <v-card-text>
                <p class="text-center" style="color: #fff;">Por favor aguarde o {{ mpesaContact
                }} confirmar o pagamento</p>
                <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
              </v-card-text>
            </v-card>
          </v-dialog>
        </v-toolbar>
        <v-toolbar flat>
          <v-dialog v-model="dialogMpesa" width="500" persistent>
            <v-card>
              <v-card-title>
                <span class="text-h6"> Pagamento via mpesa</span>
              </v-card-title>
              <v-card-subtitle>
                Atualizar subscrição para: {{emailContact}} 
              </v-card-subtitle>
              <v-card-text>
                <v-container>
                  <v-row wrap>
                    <v-col cols="12" md="12">
                      <v-select
                      v-model="plan_selected"
                      :items="plans"
                      item-text="name"
                      item-value="id"
                      label="Escolha o plano de subscrição"
                      ></v-select>
                    </v-col>
                  </v-row>
                  <v-row wrap>
                    <v-col cols="12" md="12">
                      <v-select
                      :items="packages"
                      :item-text="pack => `${pack.desc} (${pack.price} MT)`"
                      item-value="id"
                      v-model="package_selected"
                      label="escolha o pacote"></v-select>
                    </v-col>
                  </v-row>
                  <v-row wrap>
                    <v-col cols="12" md="12">
                      <v-select v-model="method_selected" :items="methods" item-text="name" item-value="id" label="escolha o metodo de pagamento"></v-select>
                    </v-col>
                  </v-row>
                  <v-row wrap>
                    <v-col cols="12" md="12" v-if="method_selected === 1">
                      <v-text-field
                      label="Numero do mpesa"
                      placeholder="O contacto do mpesa que será efetuado o pagamento"
                      v-model="mpesaContact"></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-btn
                  small
                  elevation="4"
                  color="red accent-3"
                  dark
                  text
                  @click="payMpesa"
                  >Efetuar pagamento</v-btn
                >
                <v-btn
                  exact
                  outlined
                  small
                  elevation="4"
                  color="blue accent-3"
                  dark
                  class="mx-4"
                  text
                  @click="closeDialogMpesa"
                  >Cancel</v-btn
                >
              </v-card-actions>
              
            </v-card>
          </v-dialog>
        </v-toolbar>
        <v-toolbar flat absolute>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title
                >Tem certeza de que deseja excluir este
                utilizador?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete"
                  >Cancelar</v-btn
                >
                <v-btn class="ma-1" color="error" text @click="deleteUser"
                  >Apagar</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>

      <template v-slot:item.edit="{ item }">
        <v-icon class="mr-2" @click="editUser(item)"> mdi-tune </v-icon>
      </template>
      <template v-slot:item.delete="{ item }">
        <v-icon @click="deleteItem(item)"> mdi-delete-forever </v-icon>
      </template>
    </v-data-table>
    <json-excel
      :data="users"
      :name="'empresasUsers' + Math.floor(Math.random() * 100) + '.xls'"
    >
      <v-btn small color="red accent-3" dark>
        <v-icon> mdi-download </v-icon>
        Export Excel
      </v-btn>
    </json-excel>
  </v-card>
</template>

<script>
import Swal from "sweetalert2";
import notificationForm from "./partials/notificationForm.vue";
import JsonExcel from "vue-json-excel";
import {
  ValidationProvider,
  ValidationObserver,
  setInteractionMode,
  extend,
} from "vee-validate";
import { required, digits, email, max, regex } from "vee-validate/dist/rules";
// eslint-disable-next-line no-unused-vars
import { VueEditor } from "vue2-editor";
import { BASE_URL } from "../../../../services/config";
import axios from "axios";

setInteractionMode("eager");

extend("digits", {
  ...digits,
  message: "{_field_} needs to be {length} digits. ({_value_})",
});

extend("required", {
  ...required,
  message: "{_field_} não pode estar vazio",
});

extend("max", {
  ...max,
  message: "{_field_} may not be greater than {length} characters",
});

extend("regex", {
  ...regex,
  message: "{_field_} {_value_} does not match {regex}",
});

extend("email", {
  ...email,
  message: "Email must be valid",
});
extend("url", {
  validate(value) {
    var pattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    ); // fragment locator
    return pattern.test(value);
  },
  message: "Introduz um link valido.",
});
export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    notificationForm,
    JsonExcel,
    // VueEditor
  },
  props: {
    users: {
      required: true,
      type: Array,
    },
  },
  data() {
    return {
      id: null,
      dialogDelete: false,
      dialogMpesa: false,
      dialogPayment: false,
      dialogEmail: false,
      dialogNewsletter: false,
      dialogNewslleterNotification: false,
      emailSubject: "",
      emailBody: "",
      newslleterCopy: "",
      dialog: false,
      expiredDialog: false,
      dialog1D: false,
      dialog2D: false,
      dialog3D: false,
      dialog5D: false,
      days: [],
      subscribe: "",
      dateFilter: "",
      dateFilter2: "",
      months: [1, 3, 12],
      search: "",
      selected: [],
      selectedUsers: [],
      users_id: [],
      emailsTets: "asdsdas,basdbsdb,asdsads \n\n ok ok",
      objectEmails: {},
      emails: [],
      emailsStr: "",
      method_selected: null,
      plan_selected: null,
      package_selected: null,
      mpesaContact: null,
      filters: { active: [], newsletter: [], whatsapp_notifications: [] },
      headers: [
        {
          text: "id",
          align: "start",
          sortable: false,
          value: "id",
        },
        { text: "Email", value: "email" },
        { text: "Nome", value: "name" },
        { text: "Contacto", value: "contacto" },
        { text: "Ativo", value: "active" },
        { text: "newsletter", value: "newsletter" },
        { text: "newsletter whatsapp", value: "whatsapp_notifications" },
        { text: "verificado", value: "verified" },
        { text: "Subscrição", value: "subscribe" },
        { text: "Ultimo acesso", value: "last_login" },
        { text: "convidado", value: "convidado" },
        { text: "preferencias", value: "edit", sortable: false },
        { text: "delete", value: "delete", sortable: false },
      ],
      customToolbar: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ["bold", "italic", "underline"],
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" },
        ],
        [{ list: "bullet" }, { list: "ordered" }, { list: "check" }],
        [{ color: [] }],
        ["link"],
      ],
      editedUser: {
        id: null,
        email: "",
        name: "",
        active: null,
        newsletter: null,
        verified: null,
        subscribe: null,
        whatsapp_details: {
          whatsapp: null,
          telefone: "",
          dias: "",
        },
      },
      mpesaDetailsPayment: {
        numero_telefone: "",
        valor: null,
        dias_subscricao: null
      },
      methods: [
        {
          id: 1,
          name: 'M-pesa'
        },
        {
          id: 2,
          name: 'Paypal'
        },
        {
          id: 3,
          name: 'Transferência Bancaria'
        },
        {
          id: 4,
          name: 'Númerario'
        },
      ],
      plans: [],
      packages: [],
      subscribeMonths: null,
      date: new Date().toISOString().substr(0, 10),
      menu: false,
      date2: new Date().toISOString().substr(0, 10),
      menu2: false,
    };
  },
  created() {
    this.getPlans()
  },
  computed: {
    filterUsers() {
      return this.users.filter((d) => {
        return Object.keys(this.filters).every((f) => {
          return this.filters[f].length < 1 || this.filters[f].includes(d[f]);
        });
      });
    },
    emailContact() {
      return this.editedUser.email === "empty" ? this.editedUser.contacto : this.editedUser.email
    }
  },
  methods: {
    getPlans() {
      this.$store.dispatch("empresas/getPlans")
        .then(res => {
          this.plans = res.data.data.plans
          console.log(res);
        })
    },
    columnValueList(val) {
      return this.users.map((d) => d[val]);
    },
    exportUsers() {
      let limite = 50;
      for (let i = 0; i < this.emails.length; i++) {
        if (i + 1 == limite) {
          this.emailsStr += "\n\n";
          limite += 50;
        }
        this.emailsStr += `${this.emails[i]}, `;
      }
      const currentdate = new Date();
      let blob = new Blob([this.emailsStr], { typy: "text/plain" });
      let link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      // eslint-disable-next-line no-unused-vars
      link.download =
        "users-" +
        currentdate.getDay() +
        "/" +
        currentdate.getMonth() +
        "/" +
        currentdate.getFullYear() +
        "@" +
        currentdate.getHours() +
        ":" +
        currentdate.getMinutes() +
        ":" +
        currentdate.getSeconds() +
        ".txt";
      link.click();
    },
    editUser(user) {
      this.editedUser = {
        id: user.id,
        email: user.email,
        nome: user.name,
        contacto: user.contacto,
        active: user.active,
        newsletter: user.newsletter == "desativado" ? false : true,
        verified: user.verified == "Sim" ? true : false,
        subscribe: user.subscribe === "Não paga" ? "" : user.subscribe,
        whatsapp_details: user.whatsapp_details
          ? user.whatsapp_details
          : {
              whatsapp: null,
              telefone: "",
              dias: "",
            },
      };
      this.days = user.whatsapp_details.dias
        ? typeof user.whatsapp_details.dias === String
          ? [user.whatsapp_details.dias]
          : user.whatsapp_details.dias
        : [];
      this.dialog = true;
      console.log(user);
    },
    closeDialog() {
      this.dialog = false;
      this.editedUser = {
        id: null,
        email: "",
        name: "",
        contacto: "",
        ativo: null,
        newsletter: null,
        verified: null,
        subscribe: null,
        whatsapp_details: {
          whatsapp: null,
          telefone: "",
          dias: "",
        },
      };
    },
    closeDialogMpesa() {
      this.dialogMpesa = false;
      this.mpesaDetailsPayment = {
        numero_telefone: "",
        valor: null,
        dias_subscricao: null
      }
      this.editedUser = {
        id: null,
        email: "",
        name: "",
        contacto: "",
        ativo: null,
        newsletter: null,
        verified: null,
        subscribe: null,
        whatsapp_details: {
          whatsapp: null,
          telefone: "",
          dias: "",
        },
      };
    },
    closeDialogNewsletter() {
      this.newslleterCopy = "";
      this.dialogNewsletter = false;
    },
    payMpesa(){


      const params = {
        method: this.method_selected,
        plan: this.plan_selected,
        package: this.package_selected,
        email: this.editedUser.email,
        contacto: this.editedUser.contacto,
        id: this.editedUser.id,
        mpesaContact: this.mpesaContact
      }
      if (!params.method || !params.plan || !params.package || (params.method === 1 && params.mpesaContact === null)) {
        
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Preencha todos os campos corretamente!',
          confirmButtonText: "Ok",
          confirmButtonColor: "#FF1744",
        }) 
      } else {

        if (params.method === 1) {
          this.dialogPayment = true;
        } else {
          this.$store.dispatch("loading/showLoader");
        }
        
        this.$store.dispatch("empresas/updateSubscription", params).then(() => {
          // console.log(res);
          this.closeDialogMpesa()
          this.$store.dispatch("loading/closeLoader");
          this.dialogPayment = false;
          Swal.fire({
            title: "Sucesso!",
            text: "Subscrição atualizada com sucesso",
            icon: "success",
            confirmButtonText: "Ok",
            confirmButtonColor: "#FF1744",
          }).then(() => {
            this.editedUser = {
              id: null,
              email: "",
              name: "",
              ativo: null,
              newsletter: null,
              verified: null,
            };
            
            this.$emit("getEmpresasUsers");
          })
        }).catch(() => {
            this.dialogPayment = false;
            this.$store.dispatch("loading/closeLoader");
            this.closeDialogMpesa()
          });
      }
      
      // console.log(params);
      // console.log(this.mpesaDetailsPayment)
      // console.log(this.editedUser)
    },
    submit() {
      const params = {
        id: this.editedUser.id,
        email: this.editedUser.email,
        // contacto: this.editedUser.contacto,
        verified: this.editedUser.verified,
        // subscribe_month: this.subscribeMonths,
      };
      
      console.log(params);

      this.$store.dispatch("empresas/verifyUser", params).then(() => {
        Swal.fire({
          title: "Sucesso!",
          text: "Permissões de utilizador alteradas com sucesso",
          icon: "success",
          confirmButtonText: "Ok",
          confirmButtonColor: "#FF1744",
        }).then(() => {
          this.editedUser = {
            id: null,
            email: "",
            name: "",
            ativo: null,
            newsletter: null,
            verified: null,
          };
          this.dialog = false;
          this.$emit("getEmpresasUsers");
        });
      });
    },
    async whatsappSendNewsletter() {
      this.$store.dispatch("loading/showLoader");
      const config = {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      };
      await axios.get(`${BASE_URL}/admin/notify_whatsapp`, config)
      Swal.fire({
        title: "Sucesso!",
        text: "Newsletter enviados pelo whatsapp  com sucesso",
        icon: "success",
        confirmButtonText: "OK",
        confirmButtonColor: "#FF1744",
      })
      this.$store.dispatch("loading/closeLoader");

    },
    submitNotification() {
      this.$store.dispatch("loading/showLoader");
      this.$refs.observer.validate();
      const id = this.selected.map((sel) => {
        return sel.id;
      });
      const params = {
        candidates_id: id,
        subject: this.emailSubject,
        body: this.emailBody,
      };
      const config = {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      };

      axios
        .post(`${BASE_URL}/admin/notify_empresas_users`, params, config)
        .then(() => {
          Swal.fire({
            title: "Sucesso!",
            text: "Usuarios notificados com sucesso.",
            icon: "success",
            confirmButtonText: "OK",
            confirmButtonColor: "#FF1744",
          }).then(() => {
            this.emailSubject = "";
            this.emailBody = "";
            this.dialogEmail = false;
          });
        })
        .finally(() => {
          this.$store.dispatch("loading/closeLoader");
        });
    },
    getConcurso() {
      this.$store.dispatch("loading/showLoader");
      const config = {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      };

      axios
        .get(
          `${BASE_URL}/admin/contents/filterByDate/2/${this.dateFilter}`,
          config
        )
        .then((res) => {
          let emailBodyGenerate = "<p>Veja os concursos disponíveis hoje</p>";
          // console.log(res.data.data)
          res.data.data.free_content.forEach((concurso) => {
            let link = `<p>‣ <a href="https://empresas.gugla.co.mz/contents/${concurso.id}/show" rel="noopener noreferrer" target="_blank" style="color: rgb(0, 71, 178);">${concurso.titulo}</a><span style="color: rgba(0, 0, 0, 0.87);"></span></p>`;
            emailBodyGenerate += link;
          });
          emailBodyGenerate += `<br/><p>Os concursos abaixo só podem ser visualizados pelos membros subscritos (<a href="https://empresas.gugla.co.mz/subscricao" style="color: rgb(0, 71, 178);">saiba como se tornar num membro subscrito</a>)</p>`;

          res.data.data.subscribe_content.forEach((concurso) => {
            let link = `<p>‣ <a href="https://empresas.gugla.co.mz/contents/${concurso.id}/show" rel="noopener noreferrer" target="_blank" style="color: rgb(0, 71, 178);">${concurso.titulo}</a><span style="color: rgba(0, 0, 0, 0.87);"></span></p>`;
            emailBodyGenerate += link;
          });

          emailBodyGenerate += `<p>‣ E mais;</p><p>&nbsp;</p><p>‣ Confira estes e outros novos concursos&nbsp;<a href="https://empresas.gugla.co.mz" rel="noopener noreferrer" target="_blank">Aqui</a></p>`;
          this.emailBody = emailBodyGenerate;
        })
        .finally(() => {
          this.$store.dispatch("loading/closeLoader");
        });
    },
    getConcursoCopy() {
      this.$store.dispatch("loading/showLoader");
      const config = {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      };

      axios
        .get(
          `${BASE_URL}/admin/contents/filterByDate/2/${this.dateFilter2}`,
          config
        )
        .then((res) => {
          let emailBodyGenerate = "Veja os concursos disponíveis hoje\n\n";
          res.data.data.free_content.forEach((concurso) => {
            let link = `‣  ${concurso.titulo} - https://empresas.gugla.co.mz/contents/${concurso.id}/show \n`;
            emailBodyGenerate += link;
          });

          emailBodyGenerate +=
            "\n Os Concursos abaixo só podem ser visualizados por membros subscritos ( saiba como ser subscrito em https://empresas.gugla.co.mz/subscricao )\n\n";
          res.data.data.subscribe_content.forEach((concurso) => {
            let link = `‣  ${concurso.titulo} - https://empresas.gugla.co.mz/contents/${concurso.id}/show \n`;
            emailBodyGenerate += link;
          });

          emailBodyGenerate += `‣  E mais; \n\n Confira estes e outros novos concursos Acessando https://empresas.gugla.co.mz`;
          this.newslleterCopy = emailBodyGenerate;
        })
        .finally(() => {
          this.$store.dispatch("loading/closeLoader");
        });
    },
    notifyUsers() {
      const id = this.selected.map((sel) => {
        return sel.id;
      });
      if (id.length !== 0) {
        this.users_id = this.selected;
        this.dialogEmail = true;
      } else {
        // console.log("nenhum selecionado")
        Swal.fire({
          title: "Aviso!",
          text: "Por favor selecione os usuarios para poder enviar uma notificação.",
          icon: "warning",
          confirmButtonText: "Ok",
          confirmButtonColor: "#FF1744",
        });
      }
    },
    closeDelete() {
      this.dialogDelete = false;
    },
    async deleteUser() {
      //    console.log(this.id);
      try {
        this.dialogDelete = false;
        await this.$store.dispatch("recruiters/destroyUser", { id: this.id });
        await Swal.fire({
          title: "Sucesso!",
          text: "Usuario apagado.",
          icon: "success",
          confirmButtonText: "Ok",
          confirmButtonColor: "#FF1744",
        });
        this.$store.dispatch("recruiters/getUsers");
      } catch (error) {
        console.log(error);
      }
    },
    deleteItem(item) {
      this.id = item.id;
      this.dialogDelete = true;
    },
    mpesaDialog(){
      this.dialog = false;
      this.dialogMpesa = true;
      // console.log(this.editedUser)
    }
  },
  watch: {
    selected(sel) {
      // this.emailsStr = ""
      // this.emails = sel.map((user) => {
      //     this.emailsStr += `${user.email},`
      //     return user.email
      // })
      //console.log(this.emails)
      this.selectedUsers = sel;
    },
    plan_selected(sel) {
      console.log(sel);
      console.log(this.plans.find(plan => plan.id == sel));
      this.packages = this.plans.find(plan => plan.id == sel).subscription_packages

    }
  },
};
</script>

<style></style>
