<template>
    <v-row justify="center">
		<v-col cols="12" sm="10" md="10">
            <career-form :empresas="empresas" :career="career" :updating="true"/>
		</v-col>
	</v-row>
</template>

<script>
import axios from 'axios'
import {BASE_URL} from '../../../services/config'
import careerForm from './partials/careerForm.vue'
export default {
    components: {careerForm},
    props: {
        id: {
            required: true
        }
    },
    data() {
        return {
            career: {},
            empresas: []
        }
    },
    created() {
        this.getCareerPage(this.id)
    },
    methods: {
        async getCareerPage(id){
            try {
                const config = {
                    headers: {
                        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
                    }
                }
                this.$store.dispatch('loading/showLoader')
                this.career = await (await axios.get(`${BASE_URL}/admin/careers/byId/${id}`, config)).data.data
                this.empresas = await (await axios.get(`${BASE_URL}/admin/empresa/all`, config)).data.data
                // console.log(this.career);
                // console.log(this.empresas);
                this.$store.dispatch('loading/closeLoader')
            } catch (error) {
                console.log(error);
            }
        }
    }
}
</script>

<style>

</style>