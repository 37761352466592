<template>
    <v-container>
        <v-row wrap justify="space-between">
			<v-col
				sm="12"
				md="6"
				v-for="concurso in concursos.data"
				:content="concurso"
				:key="concurso.id"
				class="mb-3"
			>
					<card-component
						:content="concurso"
                        :toAprove="true"
						routeDetails="admin.concursos.show"
					></card-component>
			</v-col>
		</v-row>
		
        <v-row>
			<v-col xs="12">
				<v-pagination
					v-model="concursos.current_page"
					:length="concursos.last_page"
					@input="handleChangePage"
					color="error"
					total-visible="7"
				></v-pagination>
			</v-col>
		</v-row>
    </v-container>
</template>

<script>
import CardComponent from '../general/CardComponent.vue'
export default {
    components: {CardComponent},
    data() {
        return {
			search: '',
			search_alert: false
        }
    },
    created() {
        this.getConcursos(1)
    },
    methods: {
        getConcursos(page) {
            this.$store.dispatch("concursos/getConcursosNotAproved", {
				page
			})
        },
		handleChangePage(page) {
			if(this.search){
				this.$store.dispatch("concursos/getConcursosNotAproved", {
					page,
					filter: this.search
				})
			}else {
				this.getConcursos(page);
			}
		},
		searchContentEvent(filter){
			this.$store.dispatch("concursos/getConcursosNotAproved", {
				page: 1,
				filter: filter
			}).then(() => {
				this.search = filter
				this.search_alert = true
			})
		},
		resetSearchResults(){
			this.getConcursos(1)
			this.search =""
			this.search_alert = false
		}
    },
	computed: {
		concursos() {
			return this.$store.state.concursos.items
		}
		
	}
}
</script>

<style>

</style>