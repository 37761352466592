<template>
    <validation-observer ref="observer" v-slot="{  }">
        <v-form @submit.prevent="onSubmit">
            <v-card>
                <v-card-title>
					<!--<h4 class="grey--text font-weight-regular">Vaga</h4>-->
					<v-chip small label dark :color="'green darken-1'">Vaga</v-chip>
				</v-card-title>
                <v-card-text>
					<v-row wrap v-if="empresa.image">
						<v-col cols="12" md="12" class="text-center">
							<v-avatar size="120">
								<img :src="empresa.image" alt="" />
							</v-avatar>
						</v-col>

					</v-row>
                    <!-- <v-row wrap v-if="imageUploadPreview">
						<v-col cols="12" md="12" class="text-center">
							<label for="file" class="inputFile">
								<v-avatar size="120">
									<img :src="imageUploadPreview" alt="" />
								</v-avatar>
							</label>
						</v-col>
					</v-row>
					<v-row wrap v-else>
						<v-col cols="12" md="12" class="text-center">
							<label for="file" class="inputFile">
								<v-avatar size="120">
									<img :src="require('../../../../../assets/img/add-camera.png')" alt="" />
								</v-avatar>
							</label>
						</v-col>
					</v-row>
					<v-row wrap>
						<v-col cols="12" md="12">
							<v-file-input
								ref="image_upload"
								dense
								truncate-length="1"
								label="Carregar Imagem"
								accept="image"
								@change="onFileChange"
								id="file"
								v-show="hidden"
							></v-file-input>
						</v-col>
					</v-row> -->
                    <v-row wrap>
						<v-col cols="12" md="12">
							<validation-provider
								v-slot="{ errors }"
								name="Título"
								rules="required"
							>
								<v-text-field
									ref="titulo"
									dense
									v-model="vaga.titulo"
									label="Título"
									placeholder="Título do anúncio"
									:error-messages="errors"
									required
								></v-text-field>
							</validation-provider>
						</v-col>
					</v-row>
                    <v-row wrap>
						<!-- <v-col cols="12" md="6">
							<validation-provider
								v-slot="{ errors }"
								name="Instituição"
								rules="required"
							>
								<v-text-field
									ref="instituicao"
									dense
									v-model="vaga.instituicao"
									label="Instituição"
									placeholder="Nome da instituição"
									:error-messages="errors"
									required
								></v-text-field>
							</validation-provider>
						</v-col> -->
						<v-col cols="12" md="6">
							<validation-provider
								v-slot="{ errors }"
								name="Intituicao"
								rules="required"
							>
								<!-- <v-select
									ref="Instituição"
									dense
									:error-messages="errors"
									v-model="selected_empresa"
									:items="empresas"
									item-text="nome"
									item-value="id"
									label="Instituição"
									class="mt-0"
								></v-select> -->
								<v-autocomplete
								v-model="selected_empresa"
								:items="empresas"
								item-text="nome"
								item-value="id"
								label="Instituição"
								:error-messages="errors"
								no-data-text="Nenhuma Instituição encontrada"
								class="mt-0"
								/>
							</validation-provider>
						</v-col>
						<v-col cols="12" md="6">
							<v-menu
								ref="menu"
								v-model="menu"
								:close-on-content-click="false"
								:return-value.sync="date"
								transition="scale-transition"
								offset-y
								min-width="auto"
							>
								<template v-slot:activator="{ on, attrs }">
									<v-text-field
										dense
										v-model="vaga.validade"
										label="Validade"
										prepend-icon="mdi-calendar"
										readonly
										v-bind="attrs"
										v-on="on"
									></v-text-field>
								</template>
								<v-date-picker
									dense
									v-model="vaga.validade"
									no-title
									scrollable
								>
									<v-spacer></v-spacer>
									<v-btn text color="primary" @click="menu = false">
										Cancel
									</v-btn>
									<v-btn text color="primary" @click="$refs.menu.save(date)">
										OK
									</v-btn>
								</v-date-picker>
							</v-menu>
						</v-col>
					</v-row>
                    <v-row wrap>
						<!-- <v-col cols="12" md="6">
							<validation-provider
							v-slot="{ errors }"
							name="fonte"
							rules="required"
							>
								
								<v-text-field
									ref="Fonte"
									dense
									:error-messages="errors"
									v-model="vaga.fonte"
									label="Fonte"
									placeholder="Fonte do anúncio"
								></v-text-field>
							</validation-provider>
						</v-col> -->
						<!-- <v-col cols="12" md="6">
							<validation-provider
								v-slot="{ errors }"
								name="Local"
								rules="required"
							>
								<v-select
                                    ref="local"
									dense
									:error-messages="errors"
									v-model="vaga.local"
									:items="locals"
									label="Local"
									class="mt-0"
                                >
                                </v-select>
							</validation-provider>
						</v-col> -->
						<v-col cols="12" md="12">
							<validation-provider
								v-slot="{ errors }"
								name="Local"
								rules="required"
							>
								<v-select
									ref="local"
									dense
									:error-messages="errors"
									v-model="selected_locals"
									:items="locals"
									item-text="name"
									item-value="id"
									multiple
									chips
									label="Local"
									class="mt-0"
								></v-select>
							</validation-provider>
						</v-col>
					</v-row>
                    <v-row wrap>
						<v-col md="12">
							<validation-provider
								v-slot="{ errors }"
								name="industrias"
								rules="required"
							>
								<v-autocomplete
								v-model="vaga.industria"
								:items="industrias"
								
								label="Industria"
								:error-messages="errors"
								no-data-text="Nenhuma industria encontrada"
								class="mt-0"
								/>
							</validation-provider>
						</v-col>
					</v-row>
                    <v-row wrap v-if="!anexoConteudoUploadPreview">
						<v-col md="12">
							<template>
								<v-card>
									<v-tabs
									v-model="tab"
									background-color=""
									centered
									
									icons-and-text>
										<v-tabs-slider></v-tabs-slider>
										<v-tab href="#tab-1">
											Conteudo não formatado
										</v-tab>
										<v-tab href="#tab-2">
											Conteudo formatado
										</v-tab>
									</v-tabs>
									<v-tabs-items v-model="tab">
										<v-tab-item value="tab-1">
											<v-textarea
												ref="conteudo"
												dense
												clearable
												clear-icon="mdi-close-circle"
												label="Descriçao"
												v-model="vaga.conteudo"
												value="This is clearable text."
												style="padding:10px; max-height:700px;"
											></v-textarea>
										</v-tab-item>
										<v-tab-item value="tab-2">
											<vue-editor
											ref="conteudo" 
											placeholder="Descrição do anuncio..." 
											style="height: 500px; margin-top: 50px;"
											:editor-toolbar="customToolbar"
											v-model="vaga.conteudo_formatado">
											</vue-editor>
										</v-tab-item>

									</v-tabs-items>
								</v-card>
							</template>
							<!--<v-textarea
								ref="conteudo"
								auto-grow
								dense
								clearable
								clear-icon="mdi-close-circle"
								label="Descriçao"
								v-model="vaga.conteudo"
								value="This is clearable text."
							></v-textarea>-->
							
						</v-col>
					</v-row>
					<v-row wrap v-if="anexoConteudoUploadPreview">
						<v-col cols="12" md="12" class="text-center">
							<!-- <v-avatar size="100">
								<img :src="anexoConteudoUploadPreview" alt="" />
							</v-avatar> -->
							<v-img>
								<img placeholder="Anexo" :src="anexoConteudoUploadPreview" class="anexo" alt="" />
							</v-img>
						</v-col>
					</v-row>
                    <v-row wrap style="margin-top:50px;">
						<v-col cols="12" md="12">
							<v-file-input
								ref="anexo_conteudo"
								dense
								clearable
								show-size
								small-chips
								truncate-length="20"
								label="Anexo"
								accept="image/*"
								@change="onAnexoConteudoFileChange"
							></v-file-input>
						</v-col>
					</v-row>
					<v-row wrap>
						<v-col cols="12" md="8">
							<validation-provider
								v-slot="{ errors }"
								name="Como candidatar-se"
								rules="required"
							>
								<v-select
								v-model="method"
								:items="formaList"
								:error-messages="errors"
								prepend-icon="mdi-none"
								label="Como candidatar-se"
								data-vv-name="select"
								required
								></v-select>
							</validation-provider>
						</v-col>
						<v-col cols="12">
							<validation-provider
								v-slot="{ errors }"
								name="Este campo"
								:rules="rule"
							>
								<v-text-field
								v-if="method"
								v-show="show"
								v-model="desc"
								:error-messages="errors"
								:prepend-icon="method == formaList[0] ? 'mdi-map-marker-outline' : 'mdi-link-variant'"
								:label="candidatar_label"
								/>
							</validation-provider>
						</v-col>
						<v-col cols="12">
							<validation-provider
								v-slot="{ errors }"
								name="Dica"
							>
								<v-textarea
								v-if="method"
								v-model="dica"
								:error-messages="errors"
								prepend-icon="mdi-tag-multiple-outline"
								label="Dica"
								style="height: 200px;"
								/>
							</validation-provider>
						</v-col>
						<v-col cols="12">
							<v-checkbox
							v-model="vaga.is_recomended"
							label="Vaga recomendada"
							></v-checkbox>
						</v-col>
						<!-- <v-col cols="12">
							<v-checkbox
							v-model="vaga.candidaturas"
							label="Ativar candidatura pela plataforma"
							></v-checkbox>
						</v-col> -->
					</v-row>

                </v-card-text>
                <v-card-actions>
					<v-btn type="submit" small elevation="4" color="red accent-3" dark>
						<v-icon small class="mr-2"> mdi-check </v-icon>
						{{ actionSubmit }}
					</v-btn>
					<v-btn
						:to="{ name: 'admin.vagas' }"
						exact
						outlined
						small
						elevation="4"
						color="red accent-3"
						dark
						class="mx-4"
					>
						<v-icon small class="mr-2"> mdi-cancel </v-icon>
						Cancelar
					</v-btn>
					<v-btn
						outlined
						small
						elevation="4"
						color="red accent-3"
						dark
						@click.prevent="clean"
						class="ml-0"
					>
						<v-icon small class="mr-2"> mdi-eraser </v-icon>
						Limpar
					</v-btn>
				</v-card-actions>
            </v-card>
        </v-form>
		<!--<div v-html="vaga.conteudo">
		</div>-->
		
    </validation-observer>
</template>

<script>
import {
	ValidationProvider,
	ValidationObserver,
	setInteractionMode,
	extend,
} from "vee-validate";
import { VueEditor  } from 'vue2-editor';
import { required, digits, email, max, regex } from "vee-validate/dist/rules";
import Swal from "sweetalert2";


setInteractionMode("eager");

extend("digits", {
	...digits,
	message: "{_field_} needs to be {length} digits. ({_value_})",
});

extend("required", {
	...required,
	message: "{_field_} não pode estar vazio",
});

extend("max", {
	...max,
	message: "{_field_} may not be greater than {length} characters",
});

extend("regex", {
	...regex,
	message: "{_field_} {_value_} does not match {regex}",
});

extend("email", {
	...email,
	message: "Email must be valid",
});
extend("url", {
	validate(value) {
		var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
		'((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
		'((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
		'(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
		'(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
		'(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
		return pattern.test(value);
	}, message: 'Introduz um link valido.'
});
export default {
    components: {
        ValidationProvider,
		ValidationObserver,
		VueEditor 
    },
    props: {
        vaga: {
            required: false,
            // eslint-disable-next-line vue/require-prop-type-constructor
            type: Object | Array,
            default: () => {
                return {
                    id: "",
					image: "",
					titulo: "",
					instituicao: "",
					is_recomended: false,
					candidaturas: false,
					industria: '',
					validade: "",
					categoria_id: 1,
					locals: [],
					local: "",
					fonte: "-",
					conteudo: "",
					conteudo_formatado: "",
					anexo_conteudo: ""
                }
            }
        },
        updating: {
			required: true,
			type: Boolean,
			default: false,
		},
        toAprove: {
            required: false,
			type: Boolean,
			default: false,
        }
    },
    data() {
        return {
            date: new Date().toISOString().substr(0, 10),
			menu: false,
			tab: null,
            image_upload: null,
            anexo_conteudo_upload: null,
			hidden: false,
			method: '',
			show: false,
			desc: '',
			dica: '',
			rule: '',
			selected_empresa: null,
			candidatar_label: '',
			selected_locals: [],
			empresa: {
				nome: '',
				image: '',
				id: null
			},
			formaList:[
				'Entregar candidatura no endereço físico',
				'Aceder o link',
				'Enviar candidatura por email',
				'Candidaturas pelo gugla'
			],
            // locals: [
            //     'Cabo Delgado',
            //     'Gaza',
            //     'Inhambane',
            //     'Manica',
            //     'Maputo',
            //     'Nampula',
            //     'Niassa',
            //     'Sofala',
            //     'Tete',
            //     'Zambézia'
            // ],
			industrias: [
            'Administração e Secretariado Cliente',
            'Aquisições e Procurement',
            'Assistente',          
            'Auditoria e Consultoria',
            'Comunicação Social',
            'Construção Civil',
            'Contabilidade e Finanças',
            'Design',
            'Direito e Justiça',
            'Electricidade',
            'Gestão de Projectos',
            'Gestão e Assessoria',
            'Gestão Financeira',
            'Gestor Provincial',
            'Higiene e Segurança',
            'Hotelaria e Turismo',
            'informática e Programação',
            'Manutenção e Técnica',
            'Marketing e Publicidade',
            'Mecânica',
            'Meio Ambiente',
            'Monitoria e Avaliação',
            'Oficial Técnico',
            'Operário',
            'Recursos Humanos',
            'Relações Públicas',
            'Saúde',
            'Seguros',
            'Serviços Gerais',
            'Serviços Sociais',
            'Supervisão e Coordenação',
            'Topografia e Geologia',
            'Transportes e Logística',
            'Vendas/Comércio',
			'Outro'
			],
			customToolbar: [
				[{header: [false, 1, 2, 3, 4, 5, 6]}],
				["bold", "italic", "underline"],
				[
					{ align: "" },
					{ align: "center" },
					{ align: "right" },
					{ align: "justify" }
				],
				[{list: 'bullet'}, {list: "ordered"}, { list: "check" }],
				[{color: []}],
				["link"]
			]
        }
    },
    created() {
		this.getCreateContent()
    },
    methods: {
        onSubmit() {
			this.$refs.observer.validate();

			const como_candidatar = {
				method: this.method,
				desc: this.desc,
				dica: this.dica
			}

			/*const conteudo = `${this.vaga.conteudo}/n
			Processo de candidatra/n
			Através do website ${como_candidatar.desc}/n
			Dica /n
			${como_candidatar.dica}`;*/
			let conteudo = ''
			if(this.method == this.formaList[0]) {
				conteudo = (this.vaga.conteudo + "\n" + "Processo de candidatura" + "\n"
				+ "Endereço: " + como_candidatar.desc);
			}
			if(this.method == this.formaList[1]){
				conteudo = (this.vaga.conteudo + "\n" + "Processo de candidatura" + "\n"
				+ "Através do website " + como_candidatar.desc);
			}
			if(this.method == this.formaList[2]){
				conteudo = (this.vaga.conteudo + "\n" + "Processo de candidatura" + "\n"
				+ "Envie um email para " + como_candidatar.desc);
			}
			if(this.method == this.formaList[3]){
				conteudo = (this.vaga.conteudo + "\n" + "Processo de candidatura" + "\n"
				+ "Candidaturas atravez do https://empregos.gugla.co.mz");
			}
			if(como_candidatar.dica){
				conteudo = (conteudo + "\n" + "Dica" + "\n" + como_candidatar.dica)
			}

			console.log(JSON.stringify(como_candidatar))
			// console.log(JSON.parse(JSON.stringify(como_candidatar)))
			//return
			
			// eslint-disable-next-line no-unreachable
			let action = this.updating
				? "vagas/updateVaga"
				: "vagas/storeVaga";

			const formData = new FormData();
			
			formData.append("id", this.vaga.id);
			formData.append("titulo", this.vaga.titulo);
			formData.append("instituicao", this.empresa.nome);
			//this.vaga.conteudo
			formData.append("conteudo", conteudo);
			formData.append("conteudo_formatado", this.vaga.conteudo_formatado);
			formData.append("validade", this.vaga.validade);
			formData.append("categoria_id", this.vaga.categoria_id);
			formData.append("local", this.vaga.local);
			formData.append("locals", JSON.stringify(this.selected_locals));
			formData.append("fonte", this.vaga.fonte);
			formData.append("como_candidatar", JSON.stringify(como_candidatar));
			formData.append('is_recomended', this.vaga.is_recomended);
			formData.append('candidaturas', this.vaga.candidaturas);
			formData.append("industria", this.vaga.industria);
			formData.append("image", this.empresa.image);

			// if (this.image_upload != null) {
			// 	formData.append("image", this.image_upload);
			// } else {
			// 	formData.append("image", this.vaga.image);
			// }

			if (this.anexo_conteudo_upload != null) {
				formData.append("anexo_conteudo", this.anexo_conteudo_upload);
			} else {
				formData.append("anexo_conteudo", this.vaga.anexo_conteudo);
			}
			
			this.$store.dispatch(action, formData).then(() => {
				Swal.fire({
					title: "Sucesso!",
					text: this.updating
						? "Actualizado com sucesso."
						: "Submetido com sucesso.",
					icon: "success",
					confirmButtonText: "Ok",
					confirmButtonColor: "#FF1744",
				}).then(() => {
					this.$router.push({ name: !this.toAprove ? "admin.vagas" : "admin.vagas-toaprove"});
				});
			});
        },
        onFileChange(e) {
			this.image_upload = e;
		},

		onAnexoConteudoFileChange(e) {
			this.anexo_conteudo_upload = e;
		},
        clean() {
			this.vaga.titulo = "";
			this.vaga.instituicao = "";
			this.vaga.validade = "";
			this.vaga.local = "";
			this.vaga.fonte = "";
			this.vaga.conteudo = "";
			this.$refs.observer.reset();
		},
		getCreateContent() {
			this.$store.dispatch("vagas/getCreateContent");
		},
    },
    computed: {
        actionSubmit() {
			return this.updating ? "Actualizar" : "Salvar";
		},

		// eslint-disable-next-line vue/return-in-computed-property
		imageUploadPreview() {
			if (this.image_upload) {
				return URL.createObjectURL(this.image_upload);
			} else if (this.vaga.image) {
				return this.vaga.image;
			}
			return
		},
		locals() {
			return this.$store.state.local.items.locals;
		},
		empresas() {
			return this.$store.state.local.items.empresas;
		},
		// eslint-disable-next-line vue/return-in-computed-property
		anexoConteudoUploadPreview() {
			if (this.anexo_conteudo_upload) {
				return URL.createObjectURL(this.anexo_conteudo_upload);
			} else if (this.vaga.anexo_conteudo) {
				return this.vaga.anexo_conteudo;
			}
			return;
		},
		
    },
	watch: {
		
	selected_empresa(selected){
		this.empresa = this.empresas.find(em => em.id === selected)
		console.log(this.empresa)
	},
		method(method) {
			//'Entregar candidatura no endereço físico',
			//	'Aceder o link',
			//	'Enviar candidatura por email'
			if(method == this.formaList[0]){
				this.show = true
				this.vaga.candidaturas = false
				this.candidatar_label = 'Endereço físico'
				this.rule = "required"
			}
			if(method == this.formaList[1]){
				this.show = true
				this.vaga.candidaturas = false
				this.candidatar_label = 'Link da candidatura'
				this.rule = "required|url"
			}
			if(method == this.formaList[2]){
				this.show = true
				this.vaga.candidaturas = false
				this.candidatar_label = 'Email para enviar a candidatura'
				this.rule = "required|email"
			}
			if(method == this.formaList[3]){
				this.candidatar_label = ""
				this.vaga.candidaturas = true
				this.show = false
			}
		},
		vaga(vaga) {
			if(vaga.como_candidatar) {
				console.log(vaga.como_candidatar)
				this.method = vaga.como_candidatar.method
				this.desc = vaga.como_candidatar.desc
				this.dica = vaga.como_candidatar.dica
			}
		}
		
	},
	concurso(concurso){
		this.selected_locals = concurso.locals.map((local) => local.id)
	},

}
</script>

<style>
.inputFile {
    cursor: pointer;
}

</style>