import axios from "axios"
import { BASE_URL } from '../../../../services/config'

const RESOURCE = '/admin/contents'


const actions = {
    getVagas({commit}, params) {
        const config = {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('token')}`,
            }
        }
        commit('loading/CHANGE_LOADING', true, { root: true })
        return new Promise((resolve, reject) => {
            axios.get(`${BASE_URL}${RESOURCE}/12/paginate/1?page=${params.page}`, config)
            .then(res => {
                commit('GET_VAGAS', res.data)
                resolve(res)
            })
            .catch(err => {
                console.log(err)
                reject(err)
            })
            .finally(() => {
                commit('loading/CHANGE_LOADING', false, { root: true })
            })
        })
    },
    getVagasNotAproved({commit}, params) {
        const config = {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('token')}`,
            }
        }
        commit('loading/CHANGE_LOADING', true, { root: true })
        return new Promise((resolve, reject) => {
            axios.get(`${BASE_URL}${RESOURCE}-not-aproved/12/paginate/1?page=${params.page}`, config)
            .then(res => {
                commit('GET_VAGAS', res.data)
                //console.log(res)
                resolve(res)
            })
            .catch(err => {
                console.log(err)
                reject(err)
            })
            .finally(() => {
                commit('loading/CHANGE_LOADING', false, { root: true })
            })
        })
    },
    filterVagas({commit}, params) {
        const config = {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('token')}`,
            }
        }
        commit('loading/CHANGE_LOADING', true, { root: true })
        return new Promise((resolve, reject) => {
            axios.get(`${BASE_URL}${RESOURCE}/12/paginate/1?page=${params.page}&titulo=${params.filter}`, config)
            .then(res => {
                commit('GET_VAGAS', res.data)
                resolve(res)
            })
            .catch(err => reject(err))
            .finally(() => commit('loading/CHANGE_LOADING', false, { root: true }))
        })
    },
    getVaga({commit}, id) {
        const config = {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('token')}`,
            }
        }
        commit('loading/CHANGE_LOADING', true, { root: true })
        return new Promise((resolve, reject) => {
            axios.get(`${BASE_URL}${RESOURCE}/${id}`, config)
            .then(res => resolve(res))
            .catch(err => reject(err))
            .finally(() => {
                commit('loading/CHANGE_LOADING', false, { root: true })
            })
        })
    },
    removeVaga({commit}, id) {
        const config = {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('token')}`,
            }
        }
        commit('loading/CHANGE_LOADING', true, { root: true })
        return new Promise((resolve, reject) => {
            axios.delete(`${BASE_URL}${RESOURCE}/${id}`, config)
            .then(res => {
                resolve(res)
            })
            .catch(err => {
                reject(err)
            })
            .finally(() => {
                commit('loading/CHANGE_LOADING', false, { root: true })
            })
        })

    },
    storeVaga({commit}, params) {
        commit('loading/CHANGE_LOADING', true, { root: true })
        const config = {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('token')}`,
            }
        }
        return new Promise((resolve, reject) => {
            axios.post(`${BASE_URL}${RESOURCE}`, params, config)
            .then(res => {
                resolve(res)
            })
            .catch(err => {
                reject(err)
            })
            .finally(() => {commit('loading/CHANGE_LOADING', false, { root: true })})
        })
    },
    updateVaga({commit}, params) {
        commit('loading/CHANGE_LOADING', true, { root: true })
        const config = {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('token')}`,
            }
        }
        return new Promise((resolve, reject) => {
            axios.post(`${BASE_URL}${RESOURCE}/${params.get('id')}`, params, config)
            .then(res => resolve(res))
            .catch(err => reject(err))
            .finally(() => {commit('loading/CHANGE_LOADING', false, { root: true })})
        })
    },
    getCreateContent({commit}) {
        commit('loading/CHANGE_LOADING', true, { root: true })
        const config = {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('token')}`,
            }
        }
        return new Promise((resolve, reject) => {
            axios.get(`${BASE_URL}${RESOURCE}/create`, config)
            .then(res => {
                let locals = res.data.data.locals
                let empresas = res.data.data.empresas
                commit('local/GET_LOCALS', locals, { root: true })
                commit('local/GET_EMPRESAS', empresas, { root: true })
                resolve(res)
            })
            .catch(err => {
                reject(err)
            })
            .finally(() => {
                commit('loading/CHANGE_LOADING', false, { root: true })
            })
        })
    },
    aproveRecruiterContent({commit}, params) {
        commit('loading/CHANGE_LOADING', true, { root: true })
        const config = {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('token')}`,
            }
        }
        return new Promise((resolve, reject) => {
            axios.post(`${BASE_URL}${RESOURCE}Aprove/${params.id}`, params, config)
                .then(res => resolve(res))
                .catch(err => reject(err))
                .finally(() => commit('loading/CHANGE_LOADING', false, { root: true }))
        })
    }
}

export default actions