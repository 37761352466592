<template>
     <Doughnut
        :chart-options="chartOptions"
        :chart-data="chartData"
        :chart-id="chartId"
        :dataset-id-key="datasetIdKey"
        :plugins="plugins"
        :css-classes="cssClasses"
        :styles="styles"
        :width="10"
        :height="5"
    />
</template>

<script>
import {Bar, Pie, Doughnut} from 'vue-chartjs/legacy'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ArcElement } from 'chart.js'
ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ArcElement)
export default {
     // eslint-disable-next-line vue/no-unused-components
     components: {Bar, Pie, Doughnut},
     props: {
          chartId: {
        type: String,
        default: 'bar-chart'
        },
        datasetIdKey: {
        type: String,
        default: 'label'
        },
        width: {
        type: Number,
        default: 15
        },
        height: {
        type: Number,
        default: 2
        },
        cssClasses: {
        default: '',
        type: String
        },
        styles: {
        type: Object,
        default: () => {}
        },
        plugins: {
        type: Object,
        default: () => {}
        },
        objectData: {
          type: Object,
          required: true
        },
        appName: {
          type: String,
          required: true
        }
     },
     data() {
          return {
               chartData: {
                    labels: [ '1D', '2D', '3D', '4D', '5D'/*, 'Não recebem newsletter' */],
                    datasets: [ { label: `${this.appName} (${this.objectData.total})`,  
                    backgroundColor: [
                        'rgb(255, 99, 132)',
                         'rgb(255, 205, 86)',
                         'rgba(153, 102, 255)',
                         'rgb(54, 162, 235)',
                         'rgba(75, 192, 192)',
                         // 'rgb(255, 205, 86, 0.2)'
                    ], 
                    // borderColor: [
                    //      'rgba(75, 192, 192)',
                    //      'rgb(255, 99, 132)',
                    //      'rgb(54, 162, 235)',
                    //      'rgb(255, 205, 86))',
                    //      'rgba(153, 102, 255))',
                         // 'rgb(255, 205, 86)'
                    // ],
                    borderWidth: 1,
                    data: [
                         this.objectData['1d'], 
                         this.objectData['2d'], 
                         this.objectData['3d'], 
                         this.objectData['4d'], 
                         this.objectData['5d'], 
                         // this.objectData.total_notReceiveNewsletter
                    ], hoverOffset: 1 } ]
               },
               chartOptions: {
                    responsive: true,
               }
          }
     }

}
</script>

<style>

</style>