<template>
    <v-container>
        <v-row wrap align="center" justify="space-between">
            <v-col cols="12" md="6">
				<v-chip small label dark color="#e61f31">Empresas esperando aprovação</v-chip>
            </v-col>
        </v-row>
        <v-row wrap justify="space-between">
            <!-- <v-col
				sm="12"
				md="6"
				v-for="empresa in empresas.data"
				:content="empresa"
				:key="empresa.id"
				class="mb-3"
			>
                <empresa-card :empresa="empresa" :toAprove="true"/>
            </v-col> -->
            <v-col cols="12">
                <empresa-table :empresas="empresas" :toAprove="true"/>
            </v-col>
        </v-row>
        <!-- <v-row>
			<v-col xs="12">
				<v-pagination
					v-model="empresas.current_page"
					:length="empresas.last_page"
					@input="handleChangePage"
					color="error"
					total-visible="7"
				></v-pagination>
			</v-col>
		</v-row> -->
    </v-container>
</template>

<script>
import empresaCard from './empresaCardComponent.vue'
import empresaTable from './empresaTableComponent.vue'
export default {
    // eslint-disable-next-line vue/no-unused-components
    components: {empresaCard, empresaTable},
    data() {
        return {
        }
    },
    created() {
        console.log('ok');
        this.getEmpresas(1)
    },
    computed: {
        empresas() {
            return this.$store.state.empresas.items
        }
    },
    methods: {
        getEmpresas(page) {
            this.$store.dispatch("empresas/getEmpresasNotAproved",{
                page
            })
        },
        handleChangePage(page){
            this.getEmpresas(page)
        }
    }
}
</script>

<style>

</style>