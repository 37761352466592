<template>
    <v-row justify="center">
		<v-col cols="12" sm="8" md="8">
            <empresa-form :updating="true" :empresa="empresa" :users="users"/>
        </v-col>
	</v-row>
</template>

<script>
import axios from 'axios'
import empresaForm from './partials/empresaForm.vue'
import {BASE_URL} from '../../../../services/config'
export default {
    components: {empresaForm},
    props: {
        id: {
            required: true
        }
    },
    data() {
        return {
            users: [],
            empresa: {
                id: "",
                image: "",
                nome: "",
                industria: "",
                email: "",
                telefone: "",
                provincia: "",
                distrito: "",
                localizacao: "",
                servicos: "",
                sobre_nos: "",
                email_cotacao: "",
                recruiter_id: null
            }
        }
    },
    created() {
        this.getEmpresa(this.id)
        this.getEmpresasUsers()
    },
    methods: {
        getEmpresa(id) {
            this.$store.dispatch('empresas/getEmpresa', id)
                .then( res => {
                    this.empresa = res.data
                    if (this.empresa.district) {
                        this.empresa.distrito = this.empresa.district.id
                        this.empresa.provincia = this.empresa.district.province.id
                    }
                })
        },
        getEmpresasUsers() {
            const config = {
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem('token')}`,
                }
            }
            this.$store.dispatch('loading/showLoader')
            axios.get(`${BASE_URL}/admin/empresas-users`, config)
            .then(res => {
                // console.log(res.data.data)
                this.users = res.data.data
            })
            .catch(err => {
                console.log(err)
            })
            .finally(() => {this.$store.dispatch('loading/closeLoader')})
        }
    }
}
</script>

<style>

</style>