<template>
    <v-row wrap>
        <v-col cols="12" style="margin-top: 60px;">
			<v-container fill-height>
                <v-row align="center" justify="center">
                    <v-col cols="10">
                        <v-img max-height="220px" :src="career.company_banner"/>
                    </v-col>
                </v-row>
            </v-container>
        </v-col>
        <v-col cols="12" :style="{backgroundColor: about_company.backgorundColor, marginTop: '80px', minHeight: '250px'}">
            <v-container fill-height>
                <v-row align="center" justify="center">
                    <v-col cols="8">
                        <div :style="{color: about_company.textColot}" v-html="about_company.text">
                        </div>
                    </v-col>
                </v-row>
            </v-container>
        </v-col>
        <v-col cols="12" style="margin-top: 70px;">
            <v-container fill-height style="max-width:1161px;">
                <v-row wrap>
                    <v-col cols="3" v-for="service in company_service" :key="service.title">
                        <v-card class="mx-auto" max-width="250px" elevation="0">
                            <v-card-text>
                                <div>
                                    {{service.title}}
                                </div>
                                <br/>
                                <div class="text--primary">
                                    {{service.desc}}
                                </div>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        <v-divider></v-divider>
        </v-col>
        <v-col cols="12" style="margin-top: 70px;">
            <v-container fill-height style="max-width:1161px;">
                <v-row wrap>
                    <v-col sm="12" 
                        md="6"
                        v-for="vaga in content"
                        :key="vaga.id"
                        class="col-md-5 mb-3">
                        <main-content-component :content="vaga" routeDetails="admin.vagas.show"/>
                    </v-col>
                </v-row>
            </v-container>
        </v-col>
    </v-row>
</template>

<script>
import  mainContentComponent from '../../content/general/CardComponent.vue'
export default {
    components: {mainContentComponent},
    props: {
        career: {
            required: true,
            type: Object,
            default() {
                return {
                    
                }
            }
        },
        content: {
            required: true,
            type: Array
        }
    },
    data() {
        return {
            about_company: {},
            company_service: []
        }
    },
    created() {
        // this.about_company = JSON.parse(this.career.about_company)
        console.log(typeof(this.career.about_company))
    },
    computed: {
        expired() {
			let today = new Date();
			let deadLineDate = new Date(this.content.validade);
			today.setHours(0, 0, 0, 0);
			deadLineDate.setHours(0, 0, 0, 0);
			//console.log(today, " -- " , deadLineDate )
			
			return today > deadLineDate ? true : false;
		}
    },
    watch: {
        career(career) {
            this.about_company = JSON.parse(career.about_company)
            this.company_service = JSON.parse(career.company_service)
            console.log(this.about_company)
        }
    },
	filters: {
		formatDate(date) {
			if (date) {
				let newDate = new Date(date);
				let newFormatedDate =
					newDate.getDate() +
					"-" +
					(newDate.getMonth() + 1) +
					"-" +
					newDate.getFullYear();
				return newFormatedDate;
			}

			return "";
		}
	}
}
</script>

<style>
.recomend-class{
	position: absolute;
	top: -10px;;
	right: -14px; /* Position them outside of the screen */
	width: 100px; /* Set a specific width */
	text-decoration: none; /* Remove underline */
	font-size: 20px; /* Increase font size */
	color: white; /* White text color */
}
.green-gugla {
	color: #76ff03;
}

.red-gugla {
	color: #ff1744;
}

.left-border-expired {
	/* red accent-3 */
	border-left: 4px solid #ff1744 !important;
}

.bottom-border-active {
	/* red accent-3 */
	border-bottom: 4px solid #ff1744 !important;
}

.left-border-active {
	/* light-green accent-4 */
	border-left: 4px solid #64dd17 !important;
	/*  */
}

.bottom-border-active {
	border-bottom: 4px solid #64dd17 !important;
}

.card {
	background-color: #fff;
	-moz-border-radius: 4px;
	-webkit-border-radius: 4px;
	border-radius: 4px;
	-moz-box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2),
		0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
	-webkit-box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2),
		0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
	box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
		0 1px 5px 0 rgba(0, 0, 0, 0.12);
	color: rgba(0, 0, 0, 0.87);
	/* margin: 8px;
	min-width: 290px; */
	overflow: hidden;
	position: relative;
}


.media--50x50 {
	height: 50px;
	width: 50px;
}

.media--50x50 > img {
	height: 100%;
	width: 50px;
}

.card-actions {
  position: absolute;
  bottom: 0;
}
</style>