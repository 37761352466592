<template>
    <v-card>
        <v-card-title>
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
            ></v-text-field>
        </v-card-title>
        <v-data-table
        :headers="headers"
        :items="records"
        :search="search"
        @click:row="viewEmpresaDetails"
        ></v-data-table>
    </v-card>
</template>

<script>
export default {
    props: {
        records: {
            required: true,
            type: Array
        },
    },
    data() {
        return {
            search: '',
            headers: [
                {
                    text: 'id',
                    align: 'start',
                    filterable: false,
                    value: 'id',
                },
                { text: 'nome',value: 'name'},
                { text: 'email', value: 'email' },
                { text: 'subscrição', value: 'subscribe'},
                { text: 'Tipo de concurso', value: 'content_status' },
            ],
        }
    },
}
</script>

<style>

</style>