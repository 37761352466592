<template>
    <v-container>
        <v-row>
            <v-col cols="12">
                <v-card>
                    <v-card-title>
                        <v-row>
                            <v-col cols="4" style="margin-top: 30px;">
                                <v-menu
                                    ref="menu"
                                    v-model="menu"
                                    :close-on-content-click="false"
                                    :return-value.sync="date"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            dense
                                            v-model="dateFilter"
                                            label="Selecione a Data"
                                            prepend-icon="mdi-calendar"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        dense
                                        v-model="dateFilter"
                                        no-title
                                        scrollable
                                    >
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="menu = false">
                                            Cancel
                                        </v-btn>
                                        <v-btn text color="primary" @click="$refs.menu.save(date)">
                                            OK
                                        </v-btn>
                                    </v-date-picker>
                                </v-menu> 
                            </v-col>
                            <v-col cols="3" style="margin-top: 30px;">
                                <v-btn small color="red accent-3" dark @click="getEmpresasViews(dateFilter)">Filtrar</v-btn>
                            </v-col>
                        </v-row>

                        <v-text-field
                            v-model="search"
                            append-icon="mdi-magnify"
                            label="Search"
                            single-line
                            hide-details
                        ></v-text-field>
                    </v-card-title>
                    <v-data-table
                    :headers="headers"
                    :items="empresasViews"
                    :search="search">
                    </v-data-table>
                    <json-excel
                    :data="empresasViews"
                    :name="'viewsConcursos' + Math.floor(Math.random() * 100) + '.xls'">
                        <v-btn small color="red accent-3" dark>
                            <v-icon>
                                mdi-download
                            </v-icon>
                            Export Excel
                        </v-btn>
                    </json-excel>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import axios from 'axios'
import {BASE_URL} from '../../../../services/config'
import JsonExcel from 'vue-json-excel'
export default {
    components: {JsonExcel},
    data() {
        return {
            search: '',
            headers: [
                {
                    text: 'ID empresa',
                    align: 'start',
                    filterable: false,
                    value: 'empresa_id',
                },
                { text: 'nome da empresa', value: 'empresa_name'},
                { text: 'nome',value: 'name'},
                { text: 'email', value: 'email' },
                { text: 'data de visualização', value: 'viewed_at'}
            ],
            empresasViews: [],
            date: new Date().toISOString().substr(0, 10),
			menu: false,
            dateFilter: ''
        }
    },
    created() {
        const today = new Date().toISOString().split('T')[0];
        this.getEmpresasViews(today)
    },
    methods: {
        async getEmpresasViews(data) {
            try {
                this.$store.dispatch('loading/showLoader')
                const config = {
                    headers: {
                        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
                    }
                }
                this.empresasViews = await (await axios.get(`${BASE_URL}/admin/empresa/views/${data}`, config)).data.data
                this.$store.dispatch('loading/closeLoader')
            } catch (error) {
                console.log(error);
                this.$store.dispatch('loading/closeLoader')
            }
        }
    }
}
</script>

<style>

</style>