<template>
    <v-card class="mx-auto d-flex flex-column" outlined elevation="1" min-height="120px">
        <v-list-item three-line :to="{name: 'admin.empresas.view', params: {id: empresa.id, toAprove: toAprove}}">
            <v-list-item-content>
                <v-list-item-title class="text-h5 mb-1">
                    {{empresa.nome}}
                </v-list-item-title>
                <div class="text-overline mb-1" v-if="empresa.district">{{empresa.district ? empresa.district.province.name : ""}}, {{ empresa.localizacao }}</div>
                <v-list-item-subtitle>{{empresa.industria}}</v-list-item-subtitle>
                    <v-list-item-subtitle>
						<v-icon>mdi-eye</v-icon>
						{{empresa.clicks}}
					</v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-avatar
                v-if="empresa.image"
                height="auto"
                tile
                size="80"
                color=""
            >
                <img :src="empresa.image" alt="">
            </v-list-item-avatar>
        </v-list-item>
    </v-card>
</template>

<script>
export default {
    props: {
        empresa: {
            type: Object,
            required: true
        },
        toAprove: {
            required: true,
			type: Boolean,
			default: false,
        }
    },
    data() {
        return {

        }
    }

}
</script>

<style>

</style>