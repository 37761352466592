<template>
  <v-app>
  
    <router-view></router-view>
    <loader/>
  </v-app>
</template>
<script>
import Loader from './components/layout/loader/loader.vue'
export default {
  name: 'App',
  components: { Loader },
  created() {
    if(sessionStorage.getItem('token')){
      this.$store.dispatch('auth/checkAuthenticated')
      .then(() => {
        this.$router.push({name: 'admin.dashboard'})
      })
      .catch(() => {
        this.$router.push({name: 'admin.login'})
      })
    }
  }
  }
</script>
<style lang="scss">

</style>
