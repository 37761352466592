export default {
    namespaced: true,
    
    state: {
        load: false
    },

    mutations: {
        CHANGE_LOADING(state, status) {
            state.load = status
        }
    },

    actions: {

        showLoader({commit}) {
            commit('CHANGE_LOADING', true)
        },
        
        closeLoader({commit}) {
            commit('CHANGE_LOADING', false)
        }
    }
}