<!-- eslint-disable no-unused-vars -->
<!-- eslint-disable no-unreachable -->
<template>
    <div>
        <validation-observer ref="observer" v-slot="{  }">
        <v-form @submit.prevent="submitNotification">
            <v-card max-height="1000px">
                <v-card-title>
                    <span class="text-h5">{{notificationType === 1 ? 'Enviar newsletter!' : 'Notificar usuarios!'}}</span>
                </v-card-title>
                <v-card-subtitle>
                    {{totalUsers}} usuarios serão notificados
                </v-card-subtitle>
                <v-card-text>
                    <v-row>
                        <v-col cols="8">
                            <validation-provider
                                v-slot="{ errors }"
                                name="assunto"
                                rules="required"
                            >
                                <v-text-field 
                                ref="assunto"
                                label="Assunto" 
                                v-model="emailSubject"
                                placeholder="Assunto do email"
                                :error-messages="errors"
                                required>
                                </v-text-field>
                            </validation-provider>
                        </v-col>
                    </v-row>
                    <v-row v-if="notificationType === 1">
                        <v-col cols="6">
                            <v-menu
                                ref="menu"
                                v-model="menu"
                                :close-on-content-click="false"
                                :return-value.sync="date"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        dense
                                        v-model="dateFilter"
                                        label="Selecione a Data"
                                        prepend-icon="mdi-calendar"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    dense
                                    v-model="dateFilter"
                                    no-title
                                    scrollable
                                >
                                    <v-spacer></v-spacer>
                                    <v-btn text color="primary" @click="menu = false">
                                        Cancel
                                    </v-btn>
                                    <v-btn text color="primary" @click="$refs.menu.save(date)">
                                        OK
                                    </v-btn>
                                </v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col cols="6">
                            <v-btn
                                exact
                                small
                                elevation="4"
                                color="red accent-3"
                                dark
                                class="mx-4"
                                @click="getConcurso"
                            >
                                Gerar newsletter
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <validation-provider
                                v-slot="{ errors }"
                                name="Título"
                                rules="required"
                            >
                                <vue-editor
                                ref="conteudo" 
                                placeholder="Corpo do email" 
                                style="height: 350px;"
                                :editor-toolbar="customToolbar"
                                v-model="emailBody"
                                :aria-errormessage="errors"
                                required>
                            </vue-editor>
                            </validation-provider>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-btn
                        type="submite"
                        exact
                        style="margin-top: 50px;"
                        small
                        elevation="4"
                        color="red accent-3"
                        dark
                        class="mx-4"
                    >
                        <v-icon small class="mr-2"> mdi-send </v-icon>
                        Enviar notificação
                    </v-btn>
                    <v-btn
                        exact
                        style="margin-top: 50px;"
                        outlined
                        small
                        elevation="4"
                        color="red accent-3"
                        dark
                        class="mx-4"
                        @click="()=>{$emit('closeDialog')}"
                    >
                        <v-icon small class="mr-2"> mdi-cancel </v-icon>
                        Cancelar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-form>
        </validation-observer>
    </div>
</template>

<script>
/* eslint-disable no-unreachable */
/* eslint-disable no-unused-vars */
import {
	ValidationProvider,
	ValidationObserver,
	setInteractionMode,
	extend,
} from "vee-validate";
import { required, digits, email, max, regex } from "vee-validate/dist/rules";
import { VueEditor  } from 'vue2-editor';
import {BASE_URL} from '../../../../../services/config'
import axios from 'axios'
import Swal from "sweetalert2";
export default {
    components: {
        ValidationProvider,
		ValidationObserver,
        VueEditor
    },
    props: {
        users_id: {
            required: false,
            type: Array
        },
        notificationType: {
            required: true,
            type: Number
        },
        period: {
            required: false,
            type: String,
            default() {
                return ""
            }
        },
        notify_type: {
            required: true,
            type: Number
        }
    },
    data() {
        return {
            emailSubject: "",
            emailBody: "",
            dateFilter: '',
            dateFilter2: '',
            objectEmails: {},
            totalUsers: {},
            notifications_id: [],
            filters: {active: [], newsletter: []},
            headers: [
                {
                    text: 'id',
                    align: 'start',
                    sortable: false,
                    value: 'id',
                },
                { text: "Email", value: "email"},
                { text: 'Nome', value: 'name'},
                { text: "Ativo", value: 'active'},
                { text: 'newsletter', value: 'newsletter'},
                {text: 'verificado', value: 'verified'},
                {text: 'Subscrição', value: 'subscribe'},
                { text: 'Actions', value: 'actions', sortable: false },
            ],
            customToolbar: [
				[{header: [false, 1, 2, 3, 4, 5, 6]}],
				["bold", "italic", "underline"],
				[
					{ align: "" },
					{ align: "center" },
					{ align: "right" },
					{ align: "justify" }
				],
				[{list: 'bullet'}, {list: "ordered"}, { list: "check" }],
				[{color: []}],
				["link"]
			],
            date: new Date().toISOString().substr(0, 10),
			menu: false,
            date2: new Date().toISOString().substr(0, 10),
			menu2: false,
        }
    },
    created() {
        if (this.notificationType === 1) {
            let datePeriod = new Date()
            this.totalUsers = this.users_id.filter(user => {
                    if (user.newsletter === "ativado" && new Date(user.subscribe).setUTCHours(0,0,0,0) > datePeriod.setUTCHours(0,0,0,0)) {
                        return user
                    }
                }).map(user => {
                            return user.id
                        }).length
            this.notifications_id = this.users_id.filter(user => {
                if (user.newsletter === "ativado" && new Date(user.subscribe).setUTCHours(0,0,0,0) > datePeriod.setUTCHours(0,0,0,0)) {
                    return user
                }
            }).map(user => user.id)
        }
        else if (this.notificationType === 2) {
            this.totalUsers = this.users_id.map(user => {
                return user.id
            }).length
            this.notifications_id = this.users_id.map(user => user.id)
        }
        else if (this.notificationType === 3) {
            let datePeriod = new Date()
            if (this.period === "expired") {
                this.totalUsers = this.users_id.filter(user => user.newsletter === "ativado" && new Date(user.subscribe).setUTCHours(0,0,0,0) < datePeriod.setUTCHours(0,0,0,0)).length
                this.notifications_id = this.users_id.filter(user => user.newsletter === "ativado" && new Date(user.subscribe).setUTCHours(0,0,0,0) < datePeriod.setUTCHours(0,0,0,0))
                                                .map(user => user.id)
                // this.setEmailBody(this.period)
                this.emailSubject = 'Gugla Empresas - Visualização de Concursos (Oportunidades de Negócio)'
                this.emailBody = `
                    <p>a sua subscrição para a visualização de concursos e recepção de notificações sobre novos concursos publicados está expirada. Por favor actualize a sua subscrição e continue a visualizar todos os concursos publicados na nossa plataforma. Não perca nenhuma oportunidade de negócio.</p><p><br></p><p>Actualize a sua subscrição em&nbsp;<a href="https://empresas.gugla.co.mz/subscricao" rel="noopener noreferrer" target="_blank" style="color: rgb(127, 133, 245);">https://empresas.gugla.co.mz/subscricao</a></p><p><br></p><p><strong>Precisa de suporte?</strong></p><p>Por favor contacte-nos pelos canais:</p><p>Email:&nbsp;<a href="mailto:comercial@marrar.co.mz" rel="noopener noreferrer" target="_blank" style="color: rgb(127, 133, 245);">comercial@marrar.co.mz</a></p><p>Contacto:&nbsp;+258 84 4181881 (Chamadas, SMS ou WhatsApp)</p>
                `
                // console.log(this.totalUsers);
            }
            else if (this.period === "1d") {
                let date_1d = new Date(datePeriod)
                date_1d.setDate(date_1d.getDate() + 1)
                this.totalUsers = this.users_id.filter(user => new Date(user.subscribe).setUTCHours(0,0,0,0) === date_1d.setUTCHours(0,0,0,0)).length
                this.notifications_id = this.users_id.filter(user => new Date(user.subscribe).setUTCHours(0,0,0,0) === date_1d.setUTCHours(0,0,0,0))
                                                .map(user => user.id)
                
                this.emailSubject = 'Gugla Empresas - Visualização de Concursos (Oportunidades de Negócio) - subscrição'
                this.emailBody = `
                    <p>a sua subscrição para a visualização de concursos e recepção de notificações sobre novos concursos publicados expira em 1 dia. Por favor actualize a sua subscrição e continue a visualizar todos os concursos publicados na nossa plataforma. Não perca nenhuma oportunidade de negócio.</p><p><br></p><p>Actualize a sua subscrição em&nbsp;<a href="https://empresas.gugla.co.mz/subscricao" rel="noopener noreferrer" target="_blank" style="color: rgb(127, 133, 245);">https://empresas.gugla.co.mz/subscricao</a></p><p><br></p><p><strong>Precisa de suporte?</strong></p><p>Por favor contacte-nos pelos canais:</p><p>Email:&nbsp;<a href="mailto:comercial@marrar.co.mz" rel="noopener noreferrer" target="_blank" style="color: rgb(127, 133, 245);">comercial@marrar.co.mz</a></p><p>Contacto:&nbsp;+258 84 4181881 (Chamadas, SMS ou WhatsApp)</p>
                `
                // this.setEmailBody(this.period)
                // console.log(this.notifications_id);
            }
            else if (this.period === "2d") {
                let date_2d = new Date(datePeriod)
                date_2d.setDate(date_2d.getDate() + 2)
                this.totalUsers = this.users_id.filter(user => new Date(user.subscribe).setUTCHours(0,0,0,0) === date_2d.setUTCHours(0,0,0,0)).length
                this.notifications_id = this.users_id.filter(user => new Date(user.subscribe).setUTCHours(0,0,0,0) === date_2d.setUTCHours(0,0,0,0))
                                                .map(user => user.id)
                // this.setEmailBody(this.period)
                this.emailSubject = 'Gugla Empresas - Visualização de Concursos (Oportunidades de Negócio) - subscrição'
                this.emailBody = `
                    <p>a sua subscrição para a visualização de concursos e recepção de notificações sobre novos concursos publicados expira em 2 dia. Por favor actualize a sua subscrição e continue a visualizar todos os concursos publicados na nossa plataforma. Não perca nenhuma oportunidade de negócio.</p><p><br></p><p>Actualize a sua subscrição em&nbsp;<a href="https://empresas.gugla.co.mz/subscricao" rel="noopener noreferrer" target="_blank" style="color: rgb(127, 133, 245);">https://empresas.gugla.co.mz/subscricao</a></p><p><br></p><p><strong>Precisa de suporte?</strong></p><p>Por favor contacte-nos pelos canais:</p><p>Email:&nbsp;<a href="mailto:comercial@marrar.co.mz" rel="noopener noreferrer" target="_blank" style="color: rgb(127, 133, 245);">comercial@marrar.co.mz</a></p><p>Contacto:&nbsp;+258 84 4181881 (Chamadas, SMS ou WhatsApp)</p>
                `
                // console.log(this.totalUsers);
            }
            else if (this.period === "3d") {
                let date_3d = new Date(datePeriod)
                date_3d.setDate(date_3d.getDate() + 3)
                this.totalUsers = this.users_id.filter(user => new Date(user.subscribe).setUTCHours(0,0,0,0) === date_3d.setUTCHours(0,0,0,0)).length
                this.notifications_id = this.users_id.filter(user => new Date(user.subscribe).setUTCHours(0,0,0,0) === date_3d.setUTCHours(0,0,0,0))
                                                .map(user => user.id)
                // this.setEmailBody(this.period)
                this.emailSubject = 'Gugla Empresas - Visualização de Concursos (Oportunidades de Negócio) - subscrição'
                this.emailBody = `
                    <p>a sua subscrição para a visualização de concursos e recepção de notificações sobre novos concursos publicados expira em 3 dia. Por favor actualize a sua subscrição e continue a visualizar todos os concursos publicados na nossa plataforma. Não perca nenhuma oportunidade de negócio.</p><p><br></p><p>Actualize a sua subscrição em&nbsp;<a href="https://empresas.gugla.co.mz/subscricao" rel="noopener noreferrer" target="_blank" style="color: rgb(127, 133, 245);">https://empresas.gugla.co.mz/subscricao</a></p><p><br></p><p><strong>Precisa de suporte?</strong></p><p>Por favor contacte-nos pelos canais:</p><p>Email:&nbsp;<a href="mailto:comercial@marrar.co.mz" rel="noopener noreferrer" target="_blank" style="color: rgb(127, 133, 245);">comercial@marrar.co.mz</a></p><p>Contacto:&nbsp;+258 84 4181881 (Chamadas, SMS ou WhatsApp)</p>
                `
                // console.log(this.totalUsers);
            }
            else if (this.period === "5d") {
                let date_5d = new Date(datePeriod)
                date_5d.setDate(date_5d.getDate() + 5)
                this.totalUsers = this.users_id.filter(user => new Date(user.subscribe).setUTCHours(0,0,0,0) === date_5d.setUTCHours(0,0,0,0)).length
                this.notifications_id = this.users_id.filter(user => new Date(user.subscribe).setUTCHours(0,0,0,0) === date_5d.setUTCHours(0,0,0,0))
                                                .map(user => user.id)
                this.emailSubject = 'Gugla Empresas - Visualização de Concursos (Oportunidades de Negócio) - subscrição'
                this.emailBody = `
                    <p>a sua subscrição para a visualização de concursos e recepção de notificações sobre novos concursos publicados expira em 5 dia. Por favor actualize a sua subscrição e continue a visualizar todos os concursos publicados na nossa plataforma. Não perca nenhuma oportunidade de negócio.</p><p><br></p><p>Actualize a sua subscrição em&nbsp;<a href="https://empresas.gugla.co.mz/subscricao" rel="noopener noreferrer" target="_blank" style="color: rgb(127, 133, 245);">https://empresas.gugla.co.mz/subscricao</a></p><p><br></p><p><strong>Precisa de suporte?</strong></p><p>Por favor contacte-nos pelos canais:</p><p>Email:&nbsp;<a href="mailto:comercial@marrar.co.mz" rel="noopener noreferrer" target="_blank" style="color: rgb(127, 133, 245);">comercial@marrar.co.mz</a></p><p>Contacto:&nbsp;+258 84 4181881 (Chamadas, SMS ou WhatsApp)</p>
                `
                // console.log(this.totalUsers);
            }
        }
    },
    watch: {
        // users_id(users) {
        //     if (this.notificationType === 1) {
        //         this.totalUsers = users.filter(user => user.newsletter === "ativado")
        //                                 .map(user => {
        //                                     return user.id
        //                                 }).length
        //     }
        //     else if (this.notificationType === 2) {
        //         this.totalUsers = users.map(user => {
        //             return user.id
        //         }).length
        //     }
        // }
    },
    methods: {
        setEmailBody(days) {

            
        },
        submitNotification() {
            this.$store.dispatch('loading/showLoader')
            this.$refs.observer.validate();
            let id = this.notifications_id
            // if(this.notificationType === 1) {
            //     id = this.users_id.filter(user => user.newsletter === "ativado")
            //                         .map(user => {
            //                             return user.id
            //                         })
            // }
            // else if (this.notificationType === 2) {
            //     id = this.users_id.map(user => {
            //         return user.id
            //     })
            // }
            const params = {
                candidates_id: id,
                subject: this.emailSubject,
                body: this.emailBody,
                notify_type: this.notify_type
            }
            const config = {
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem('token')}`,
                }
            }

            axios.post(`${BASE_URL}/admin/notify_empresas_users`, params, config)
                .then(() => {
                    Swal.fire({
                        title: "Sucesso!",
                        text: "Usuarios notificados com sucesso.",
                        icon: "success",
                        confirmButtonText: "OK",
                        confirmButtonColor: "#FF1744"
                    }).then(() => {
                        this.emailSubject = ""
                        this.emailBody = ""
                        this.$emit('closeDialog')
                    })
                })
                .finally(() => {this.$store.dispatch('loading/closeLoader')})

        },
        getConcurso() {
            this.$store.dispatch('loading/showLoader')
            const config = {
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem('token')}`,
                }
            }

            axios.get(`${BASE_URL}/admin/contents/filterByDate/2/${this.dateFilter}`, config)
                .then(res => {
                    let emailBodyGenerate = "<p>Veja os concursos disponíveis hoje</p>";
                    // console.log(res.data.data)
                    res.data.data.free_content.forEach(concurso => {
                        let link = `<p>‣ <a href="https://empresas.gugla.co.mz/contents/${concurso.id}/show" rel="noopener noreferrer" target="_blank" style="color: rgb(0, 71, 178);">${concurso.titulo}</a><span style="color: rgba(0, 0, 0, 0.87);"></span></p>`
                        emailBodyGenerate += link
                    })
                    emailBodyGenerate += `<br/><p>Os concursos abaixo só podem ser visualizados pelos membros subscritos (<a href="https://empresas.gugla.co.mz/subscricao" style="color: rgb(0, 71, 178);">saiba como se tornar num membro subscrito</a>)</p>`

                    res.data.data.subscribe_content.forEach(concurso => {
                        let link = `<p>‣ <a href="https://empresas.gugla.co.mz/contents/${concurso.id}/show" rel="noopener noreferrer" target="_blank" style="color: rgb(0, 71, 178);">${concurso.titulo}</a><span style="color: rgba(0, 0, 0, 0.87);"></span></p>`
                        emailBodyGenerate += link
                    })

                    emailBodyGenerate += `<p>‣ E mais;</p><p>&nbsp;</p><p>‣ Confira estes e outros novos concursos&nbsp;<a href="https://empresas.gugla.co.mz" rel="noopener noreferrer" target="_blank">Aqui</a></p>`
                    this.emailBody = emailBodyGenerate
                })
                .finally(() => {this.$store.dispatch('loading/closeLoader')})
        },
        closeDialog(){
            this.$emit('closeDialog')
        }
    }

}
</script>

<style>

</style>