<template>
	<v-row justify="center">
		<v-col cols="12" sm="8" md="8">
            <empresa-form :users="users" :updating="false"/>
		</v-col>
	</v-row>
</template>

<script>
import axios from 'axios'
import {BASE_URL} from '../../../../services/config'
import EmpresaForm from './partials/empresaForm.vue'
export default {
    components: {EmpresaForm},
    data(){
        return {
            users: []
        }
    },
    created() {
        this.getEmpresasUsers()
    },
    methods: {

        getEmpresasUsers() {
            const config = {
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem('token')}`,
                }
            }
            this.$store.dispatch('loading/showLoader')
            axios.get(`${BASE_URL}/admin/empresas-users`, config)
            .then(res => {
                // console.log(res.data.data)
                this.users = res.data.data
            })
            .catch(err => {
                console.log(err)
            })
            .finally(() => {this.$store.dispatch('loading/closeLoader')})
        }
    }
}
</script>

<style>

</style>