import Vue from "vue"
import Vuex from "vuex"
import loading from "./modules/loading/loading"
import auth from "./modules/auth/auth"
import vagas from "./modules/content/vagas/vagas"
import concursos from "./modules/content/concursos/concursos"
import publicity from "./modules/content/publicity/publicity"
import local from "./modules/local/local"
import admin from "./modules/users/admin/admin"
import empresas from "./modules/empresas/empresas"
import empregos from "./modules/users/empregos/empregos"
import recruiters from "./modules/users/recruiters/recruiters"

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        loading,
        auth,
        vagas,
        concursos,
        publicity,
        local,
        admin,
        empresas,
        empregos,
        recruiters
    }
})