<template>
    <v-card>
        <v-card-title>
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
            ></v-text-field>
        </v-card-title>
        <v-data-table
        :headers="headers"
        :items="users"
        :search="search"
        >
            <template v-slot:top>
                <v-toolbar flat>
                    <v-dialog v-model="dialogDelete" max-width="500px">
                        <v-card>
                            <v-card-title class="text-h6">Tem certeza de que deseja excluir este utilizador?</v-card-title>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="blue darken-1" text @click="closeDelete">Cancelar</v-btn>
                                <v-btn class="ma-1"
                                color="error"
                                text @click="deleteUser">Apagar</v-btn>
                                <v-spacer></v-spacer>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-toolbar>
            </template>
            <template v-slot:item.actions="{ item }">
                <v-icon
                    @click="deleteItem(item)"
                >
                    mdi-delete-forever
                </v-icon>
            </template>
        </v-data-table>
    </v-card>

</template>

<script>
import Swal from "sweetalert2";
export default {
    props: {
        users: {
            required: true,
            type: Array
        }
    },
    data() {
        return {
            dialog: false,
            dialogDelete: false,
            search: '',
            id: null,
            headers: [
                {
                    text: 'id',
                    align: 'start',
                    filterable: false,
                    value: 'id',
                },
                { text: 'email', value: 'email' },
                { text: 'nome', value: 'name' },
                { text: 'Estado', value: 'active' },
                { text: 'Tipo de conta', value: 'role_id' },
                { text: 'Delete', value: 'actions', sortable: false }
            ],
        }
    },
    methods: {
        closeDelete () {
            this.dialogDelete = false
        },
        async deleteUser () {
            try {
                this.dialogDelete = false
                await this.$store.dispatch("admin/destroyUser", {id: this.id})
                await Swal.fire({
                    title: "Sucesso!",
					text: "Usuario apagado.",
					icon: "success",
					confirmButtonText: "Ok",
					confirmButtonColor: "#FF1744",
                })
                await this.$store.dispatch("admin/getUsers")
            } catch (error) {
                console.log(error);
            }
        },
        deleteItem(item) {
            this.id = item.id
            this.dialogDelete = true 
        }
    }
}
</script>

<style>

</style>