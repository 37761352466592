<template>
    <validation-observer ref="observer" v-slot="{  }">
        <div>
            <v-form @submit.prevent="submitNotification">
                <v-card max-height="1000px">
                    <v-card-title>
                        <span class="text-h5">
                            Notificar empresas
                        </span>
                    </v-card-title>
                    <v-card-subtitle>
                        {{totalUsers}} usuarios serão notificados
                    </v-card-subtitle>
                    <v-card-text>
                        <v-row>
                            <v-col cols="8">
                                <validation-provider
                                    v-slot="{ errors }"
                                    name="assunto"
                                    rules="required"
                                >
                                    <v-text-field 
                                    ref="assunto"
                                    label="Assunto" 
                                    v-model="emailSubject"
                                    placeholder="Assunto do email"
                                    :error-messages="errors"
                                    required>
                                    </v-text-field>
                                </validation-provider>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <validation-provider
                                    v-slot="{ errors }"
                                    name="Título"
                                    rules="required"
                                >
                                    <vue-editor
                                    ref="conteudo" 
                                    placeholder="Corpo do email" 
                                    style="height: 350px;"
                                    :editor-toolbar="customToolbar"
                                    v-model="emailBody"
                                    :aria-errormessage="errors"
                                    required>
                                </vue-editor>
                                </validation-provider>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn
                            type="submite"
                            exact
                            style="margin-top: 50px;"
                            small
                            elevation="4"
                            color="red accent-3"
                            dark
                            class="mx-4"
                        >
                            <v-icon small class="mr-2"> mdi-send </v-icon>
                            Enviar notificação
                        </v-btn>
                        <v-btn
                            exact
                            style="margin-top: 50px;"
                            outlined
                            small
                            elevation="4"
                            color="red accent-3"
                            dark
                            class="mx-4"
                            @click="closeDialog"
                        >
                            <v-icon small class="mr-2"> mdi-cancel </v-icon>
                            Cancelar
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-form>
        </div>
    </validation-observer>
</template>

<script>
/* eslint-disable no-unreachable */
/* eslint-disable no-unused-vars */
import {
	ValidationProvider,
	ValidationObserver,
	setInteractionMode,
	extend,
} from "vee-validate";
import { required, digits, email, max, regex } from "vee-validate/dist/rules";
import { VueEditor  } from 'vue2-editor';
import {BASE_URL} from '../../../../../services/config'
import axios from 'axios'
import Swal from "sweetalert2";
export default {
    components: {
        ValidationProvider,
		ValidationObserver,
        VueEditor
    },
    props: {
        empresas_id: {
            required: true,
            type: Array
        }
    },
    data() {
        return {
            emailSubject: "",
            emailBody: "",
            totalUsers: [],
            customToolbar: [
				[{header: [false, 1, 2, 3, 4, 5, 6]}],
				["bold", "italic", "underline"],
				[
					{ align: "" },
					{ align: "center" },
					{ align: "right" },
					{ align: "justify" }
				],
				[{list: 'bullet'}, {list: "ordered"}, { list: "check" }],
				[{color: []}],
				["link"]
			],
        }
    },
    created() {
        this.totalUsers = this.empresas_id.length
    },
    methods: {
        async submitNotification() {
            // console.log("send notification")
            try {
                this.$store.dispatch('loading/showLoader')
                this.$refs.observer.validate();
                const empresas_id = this.empresas_id.map(empresa => empresa.id)
                const params = {
                    empresas_id: empresas_id,
                    subject: this.emailSubject,
                    body: this.emailBody
                }
                const config = {
                    headers: {
                        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
                    }
                }
                await axios.post(`${BASE_URL}/admin/empresa/notify/send-notification`, params, config)
                await Swal.fire({
                    title: "Sucesso!",
                    text: "Empresa(s) notificada(s) com sucesso.",
                    icon: "success",
                    confirmButtonText: "OK",
                    confirmButtonColor: "#FF1744"
                })
                this.emailSubject = ""
                this.emailBody = ""
                this.$emit('closeDialog')
                this.$store.dispatch('loading/closeLoader')
            } catch (error) {
                console.log(error);
            }
            
        },
        closeDialog(){
            this.users_id = []
            this.emailSubject = ""
            this.emailBody = ""
            this.$emit('closeDialog')
        }
    }
}
</script>

<style>

</style>