<template>
    <v-card>
        <v-card-title>
            <v-row>
                <v-col cols="3" style="margin-top: 30px;">
                    <v-menu
                        ref="menu"
                        v-model="menu"
                        :close-on-content-click="false"
                        :return-value.sync="date"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                dense
                                v-model="dateFilter"
                                label="Selecione a Data"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                            ></v-text-field>
                        </template>
                        <v-date-picker
                            dense
                            v-model="dateFilter"
                            no-title
                            scrollable
                        >
                            <v-spacer></v-spacer>
                            <v-btn text color="primary" @click="menu = false">
                                Cancel
                            </v-btn>
                            <v-btn text color="primary" @click="$refs.menu.save(date)">
                                OK
                            </v-btn>
                        </v-date-picker>
                    </v-menu> 
                </v-col>
                <v-col cols="2" style="margin-top: 30px;">
                    <v-btn small color="red accent-3" dark @click="emitGetLogd()">Filtrar</v-btn>
                </v-col>
                <v-col cols="5" style="margin-top: 15px;">
                    <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Pesquisar"
                        placeholder="pesquisar por email"
                        single-line
                        hide-details
                        @keypress.enter.prevent="searchEmail"
                    ></v-text-field>
                </v-col>
                <!-- <v-col cols="2" style="margin-top: 30px;">
                    <v-btn small color="red accent-3" dark @click="searchEmail()">Pesquisar</v-btn>
                </v-col> -->
                <v-col cols="2" style="margin-top: 30px;">
                    <v-btn small color="red accent-3" dark @click="allLogs()">ver todos pagamentos</v-btn>
                </v-col>
            </v-row>
        </v-card-title>
        <v-data-table
        :headers="headers"
        :items="logs"
        :search="search"
        class="elevation-1"
        >
        </v-data-table>
        <json-excel
        :data="logs"
        :name="'PaymentsLogs' + Math.floor(Math.random() * 100) + '.xls'">
            <v-btn small color="red accent-3" dark>
                <v-icon>
                    mdi-download
                </v-icon>
                Export Excel
            </v-btn>
        </json-excel>
    </v-card>
</template>

<script>
/* eslint-disable no-unused-vars */
import Swal from "sweetalert2";
import {BASE_URL} from '../../../services/config'
import axios from 'axios'
import JsonExcel from 'vue-json-excel'
export default {
    components: {JsonExcel},
    props: {
        logs: {
            required: true,
            default: Array
        }
    },
    data() {
        return {
            search: '',
            // filters: {active: [], newsletter: [],education: [], profissionalExperience: [], language: [], anexos: []},
            headers: [
                { text: "data de pagamento", align: 'start',value: "created_at"},
                { text: 'email ou contacto', value: 'recruiter_email'},
                { text: "nome do usuario", value: 'recruiter_nome'},
                { text: 'processador', value: 'processador'},
                {text: 'valor pago', value: 'valor'},
                {text: 'ultima subscrição', value: 'antiga_data'},
                {text: 'nova subscrição', value: 'nova_data'},
                {text: 'Serviço', value: 'tipo_subscricao'},
                {text: 'Método', value: 'method'},
                {text: 'Empresa', value: 'empresa'},
                {text: 'convidado pelo:', value: 'recruiter_email_convidou'}
            ],
            
			customToolbar: [
				[{header: [false, 1, 2, 3, 4, 5, 6]}],
				["bold", "italic", "underline"],
				[
					{ align: "" },
					{ align: "center" },
					{ align: "right" },
					{ align: "justify" }
				],
				[{list: 'bullet'}, {list: "ordered"}, { list: "check" }],
				[{color: []}],
				["link"]
			],
            date: new Date().toISOString().substr(0, 10),
			menu: false,
            dateFilter: ''

        }
    },
    computed: {
        // filterUsers() {
        //     return this.logs.filter((d) => {
        //         return Object.keys(this.filters).every((f) => {
        //             return this.filters[f].length < 1 || this.filters[f].includes(d[f]);
        //         });
        //     });
        // }
    },
    methods: {
         emitGetLogd() {
            // console.log(this.dateFilter);
            this.$emit('emitGetLogd', this.dateFilter, "")
        },
        searchEmail() {
            console.log(this.search);
            this.$emit('emitGetLogd', "", this.search)
        },
        allLogs() {
            this.search = ''
            this.dateFilter = ''
            this.$emit('emitGetLogd', "", "")
        }
    }

}
</script>

<style>

</style>