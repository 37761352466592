<template>
    <div>
        <v-row>
			<v-col cols="12" class="mb-6">
				<v-btn
					:to="{ name: !toAprove ? 'admin.concursos' : 'admin.concursos-toaprove' }"
					class="ml-0"
					tile
					elevation="4"
					dark
					color="red acent-3"
					small
				>
					<v-icon left small> mdi-arrow-left </v-icon>Voltar</v-btn
				>
			</v-col>
		</v-row>
        <v-row justify="center">
			<v-col cols="10">
				<details-component :content="content"/>
			</v-col>
		</v-row>
        <v-row>
			<v-col cols="12" class="">
                
				<v-btn v-if="content.id"
					:to="{ name: 'admin.concursos.edit', params: {id: content.id, toAprove: toAprove} }"
					tile
					small
					elevation="4"
					dark
					color="red accent-3"
					><v-icon small class="mr-2">mdi-pencil-outline</v-icon> Editar</v-btn
				>
				<v-btn
					v-if="toAprove"
					@click.prevent="aproveContent(content.id)"
					tile
					small
					elevation="4"
					dark
					color="red accent-3"
					class="ml-4"
					><v-icon small class="mr-2">mdi-delete-alert-outline</v-icon>
					Aprovar concurso</v-btn
				>
				<v-dialog v-model="dialog" width="1000">
					<template v-slot:activator="{ on, attrs }">
						<v-btn
							v-if="!toAprove"
							tile
							small
							elevation="4"
							dark
							color="red accent-3"
							class="ml-4"
							v-bind="attrs"
							v-on="on"
							><v-icon small class="mr-2">mdi-eye</v-icon>
							Visualizações</v-btn
						>
					</template>
					<v-card>
						<v-card-title class="text-h6 lighten-2" style="background-color: #e61f31; color: white;">
							Visualizações neste concurso
						</v-card-title>
						<v-card-text style="margin-top: 20px;">
							<views-records :records="content.record_contents"/>
						</v-card-text>
					</v-card>
				</v-dialog>
				
				<v-btn
					@click.prevent="removeContentWarning(content)"
					tile
					small
					elevation="4"
					dark
					color="red accent-3"
					class="ml-4"
					><v-icon small class="mr-2">mdi-delete-alert-outline</v-icon>
					Apagar</v-btn
				>
			</v-col>
		</v-row>
    </div>
</template>

<script>
import DetailsComponent from '../general/DetailsComponent.vue'
import viewsRecords from './viewsRecords.vue'
import Swal from 'sweetalert2'
export default {
    components: {DetailsComponent, viewsRecords},
    props: {
        id: {
            required: true
        },
        toAprove: {
            required: false,
			type: Boolean,
			default: false,
        }
    },
    data() {
        return {
            content: {
				image: "",
				titulo: "",
				instituicao: "",
				validade: "",
				categoria_id: "",
				local: "",
				fonte: "",
				conteudo: "",
				dialog: false
			},
        }
    },
    created() {
        this.getConcurso(this.id)
    },
    methods: {
        getConcurso(id) {
            this.$store.dispatch("concursos/getConcurso", id)
            .then((res) => {
                this.content = res.data.data
            })
        },
        removeContentWarning(content) {
			const warning = `Deseja remover o anúncio ${content.titulo}?`;

			Swal.fire({
				title: "Atenção!",
				text: warning,
				icon: "warning",
				showCancelButton: true,
				confirmButtonText: "Sim",
				cancelButtonText: "Não",
				confirmButtonColor: "#FF1744",
				cancelButtonColor: "#FF1744",
			}).then((result) => {
				if (result.isConfirmed) {
					this.removeContent(content.id);
				}
			});
		},
		removeContent(content_id) {
			
			this.$store
				.dispatch("concursos/removeConcurso", content_id)
				.then(() => {
					Swal.fire({
						title: "Sucesso!",
						text: 'Removido com sucesso',
						icon: "success",
						confirmButtonText: "Ok",
						confirmButtonColor: "#FF1744",
					})
					.then( () => {
						this.$router.push({name: 'admin.concursos'})
					});
				});
		},
		aproveContent(content_id) {
			this.$store.dispatch('concursos/aproveRecruiterContent', {id: content_id, estado: true})
                .then(() => {
                    Swal.fire({
					title: "Sucesso!",
					text: "Concurso aprovado com sucesso!",
					icon: "success",
					confirmButtonText: "Ok",
					confirmButtonColor: "#FF1744",
                    }).then(() => {
                        this.getConcurso(content_id)
                        this.toAprove = false
                    });
                })
		}
    }
}
</script>

<style>

</style>