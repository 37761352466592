<template>
    <v-container>
    <validation-observer ref="observer" v-slot="{  }">
        <v-form @submit.prevent="onSubmit">
            <v-card>
                <v-card-title>
					<v-chip small label dark :color="'green darken-1'">Criar pagina de careira</v-chip>
				</v-card-title>
                <v-card-text>
                    <v-row wrap v-if="imageUploadPreview">
						<v-col cols="12" md="12" class="text-center">
							<label for="file" class="inputFile">
								<v-avatar size="120">
									<img :src="imageUploadPreview" alt="" />
								</v-avatar>
							</label>
						</v-col>
					</v-row>
					<v-row wrap v-else>
						<v-col cols="12" md="12" class="text-center">
							<label for="file" class="inputFile">
								<v-avatar size="120">
									<img :src="require('../../../../assets/img/add-logo.png')" alt="" />
								</v-avatar>
							</label>
						</v-col>
					</v-row>
					<v-row wrap>
						<v-col cols="12" md="12">
							<v-file-input
								ref="image_upload"
								dense
								truncate-length="1"
								label="Carregar Imagem"
								accept="image/*"
								@change="onFileChange"
								id="file"
								v-show="hidden"
							></v-file-input>
							<!-- <input type="file" @change="onFileChange"> -->
						</v-col>
					</v-row>
                    <v-row wrap>
                        <v-col cols="12" md="12">
                            <v-autocomplete
								v-model="career.empresa_id"
								:items="empresas"
                                item-text="nome"
                                item-value="id"
								label="Nome da empresa"
								no-data-text="Nenhuma empresa encontrada"
								class="mt-0 required"
								/>
                        </v-col>
                    </v-row>
					<v-row wrap>
						<v-col cols="12" md="12">
							<v-file-input
								ref="image_upload"
								dense
								truncate-length="1"
                                counter
								label="Carregar Banner"
								accept="image/*"
								@change="onBannerChange"
                                class="dimensions"
							></v-file-input>
							<!-- <input type="file" @change="onFileChange"> -->
						</v-col>
					</v-row>
					<v-row wrap>
						<v-col cols="12" md="10">
                            <!-- <v-textarea
								ref="about_company"
                                :color="colorBackground"
                                solo
								dense
								clearable
								clear-icon="mdi-close-circle"
								label="Sobre a empresa"
								v-model="career.about_company"
								value="This is clearable text."
								style="padding:10px; max-height:700px;"
							></v-textarea> -->
                            <vue-editor
							ref="about_company" 
                            :color="colorBackground"
							placeholder="Descrição da empresa..." 
							style="max-height: 500px; margin-top: 50px;"
							:editor-toolbar="customToolbar"
							v-model="career.about_company">
							</vue-editor>
						</v-col>
                        <v-col cols="12" md="2">
                            <v-row wrap style="margin-top: 20px;">
                                <v-col cols="6" md="12">
                                    <v-menu offset-y top nudge-bottom="105" nudge-left="16" :close-on-content-click="false">
                                        <template v-slot:activator="{ on }">
                                            <v-btn
                                                :color="colorBackground"
                                                dark
                                                v-on="on"
                                                small
                                            >
                                                Cor do Fundo
                                            </v-btn>
                                        </template>
                                        <v-color-picker
                                            value="#7417BE"
                                            v-model="colorBackground"
                                            class="ma-2"
                                            swatches-max-height="300px"
                                        ></v-color-picker>
                                    </v-menu>
                                </v-col>
                                <v-col cols="6" md="12">
                                    <v-menu offset-y top nudge-bottom="105" nudge-left="16" :close-on-content-click="false">
                                        <template v-slot:activator="{ on }">
                                            <v-btn
                                                :color="colorTexto"
                                                dark
                                                v-on="on"
                                                small
                                            >
                                                Cor do Texto
                                            </v-btn>
                                        </template>
                                        <v-color-picker
                                            value="#7417BE"
                                            v-model="colorTexto"
                                            class="ma-2"
                                            swatches-max-height="300px"
                                        ></v-color-picker>
                                    </v-menu>
                                </v-col>
                            </v-row>
                        </v-col>
					</v-row>
                        <v-row wrap>
                            <v-col
                                cols="12"
                                md="6"
                                v-for="(serv, index) in company_service"
                                :key="index"
                            >
                                <v-card class="mb-4" tile elevation="0" outlined>
                                    <v-card-text>
                                        <v-row wrap>
                                            <v-col cols="12" md="12">
                                                <v-text-field
                                                v-model="serv.title"
                                                label="Titulo"
                                                solo
                                                dense
                                                required
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="12" md="12">
                                                <v-textarea
                                                v-model="serv.desc"
                                                label="Descrição"
                                                solo
                                                required
                                                ></v-textarea>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                    <v-card-actions>
                                        <v-btn
                                        depressed
                                        rounded
                                        outlined
                                        color="secondary"
                                        small
                                        @click.prevent="removeCompanyService(index)"
                                        v-show="index || (!index && company_service.length > 1)"
                                        >Remover card</v-btn
                                        >
                                        <v-btn
                                        rounded
                                        color="secondary"
                                        small
                                        @click.prevent="addCompanyService(index)"
                                        v-show="index == company_service.length - 1"
                                        >Novo card</v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-col>
                        </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-btn type="submit" small elevation="4" color="red accent-3" dark>
						<v-icon small class="mr-2"> mdi-check </v-icon>
						{{actionSubmit}}
					</v-btn>
					<v-btn
						:to="{ name: 'admin.careers' }"
						exact
						outlined
						small
						elevation="4"
						color="red accent-3"
						dark
						class="mx-4"
					>
						<v-icon small class="mr-2"> mdi-cancel </v-icon>
						Cancelar
					</v-btn>
                </v-card-actions>
            </v-card>
        </v-form>
    </validation-observer>
    </v-container>
</template>

<script>
/* eslint-disable no-unused-vars */
/* eslint-disable vue/no-unused-components */
/* eslint-disable vue/require-prop-type-constructor */
import {
	ValidationProvider,
	ValidationObserver,
	setInteractionMode,
	extend,
} from "vee-validate";
import { VueEditor  } from 'vue2-editor';
import { required, digits, email, max, regex } from "vee-validate/dist/rules";
import Swal from "sweetalert2";


setInteractionMode("eager");

extend("digits", {
	...digits,
	message: "{_field_} needs to be {length} digits. ({_value_})",
});

extend("required", {
	...required,
	message: "{_field_} não pode estar vazio",
});

extend("max", {
	...max,
	message: "{_field_} may not be greater than {length} characters",
});

extend("regex", {
	...regex,
	message: "{_field_} {_value_} does not match {regex}",
});

extend("email", {
	...email,
	message: "Email must be valid",
});
export default {
    components: {
        ValidationProvider,
		ValidationObserver,
		VueEditor 
    },
    props: {
        career: {
            required: false,
            type: Object | Array,
            default: () => {
                return {
                    id: "",
                    company_logo: "",
                    company_banner: "",
                    about_company: "",
                    company_service: "",
                    empresa_id: null

                }
            }
        },
        empresas: {
            type: Array,
            required: true
        },
        updating: {
			required: true,
			type: Boolean,
			default: false,
		}
    },
    data() {
        return {
            company_logo: null,
            hidden: false,
            company_banner: null,
            colorBackground: '',
            colorTexto: '',
            company_service: [
                {
                    title: "",
                    desc: "",
                }
            ],
            customToolbar: [
				[{header: [false, 1, 2, 3, 4, 5, 6]}],
				["bold", "italic", "underline"],
				[
					{ align: "" },
					{ align: "center" },
					{ align: "right" },
					{ align: "justify" }
				],
				[{list: 'bullet'}, {list: "ordered"}, { list: "check" }],
				[{color: []}],
				["link"]
			]
        }
    },
    methods: {
        onFileChange(e) {
			this.company_logo = e;
		},
        onBannerChange(e) {
            this.company_banner = e
        },
        addCompanyService() {
            this.company_service.push({
                title: "",
                desc: ""
            })
        },
        removeCompanyService(index) {
            console.log(index)
            this.company_service.splice(index, 1)
        },
        async onSubmit() {
            try {
                let action = this.updating
				? "empresas/updateCareerPage"
				: "empresas/createCareerPage";
                const about_company = {
                    text: this.career.about_company,
                    textColot: this.colorTexto,
                    backgorundColor: this.colorBackground
                }

                const formData = new FormData()
                formData.append("id", this.career.id);
                if(this.company_logo !== null) {
                    formData.append('company_logo', this.company_logo)
                }
                if(this.company_banner !== null) {
                    formData.append('company_banner', this.company_banner)
                }
                formData.append('about_company', JSON.stringify(about_company))
                formData.append('company_service', JSON.stringify(this.company_service))
                formData.append('empresa_id', this.career.empresa_id)
                await this.$store.dispatch(action, formData)
                Swal.fire({
                    title: "Sucesso!",
                    text: "Pagina de careiras criada com sucesso!",
                    icon: "success",
                    confirmButtonText: "Ok",
                    confirmButtonColor: "#FF1744",
                }).then(() => {
                    this.$router.push({ name: "admin.careers" });
                });
            } catch (error) {
                console.log(error)
            }
            
        }
    },
    computed: {
        // eslint-disable-next-line vue/return-in-computed-property
        imageUploadPreview() {
            if(this.company_logo){
                return URL.createObjectURL(this.company_logo);
            } else if (this.career.company_logo) {
                return this.career.company_logo
            }
            return
        },
        colorS() {
            console.log(this.colorBackground);
            return this.colorBackground
        },
        actionSubmit() {
			return this.updating ? "Actualizar" : "Criar empresa";
		}
    },
    watch: {
        career(career) {
            const about_company = JSON.parse(career.about_company)
            this.career.about_company = about_company.text
            this.colorTexto = about_company.textColot
            this.colorBackground = about_company.backgorundColor
            this.company_service = JSON.parse(career.company_service)
        }
    }
}
</script>

<style>
.required label::after {
    content: " *";
    color: red;
}
.opcional label::after {
    content: " (opcional)";
    color: rgb(145, 145, 145);
}
.dimensions label::after {
    content: " (max-height 220px)";
    color: rgb(145, 145, 145);;
}
</style>