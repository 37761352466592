<template>
    <v-card>
        <v-card-title>
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
            ></v-text-field>
        </v-card-title>
        <v-data-table
        :headers="headers"
        :items="publicities"
        :search="search" 
        >
            <template v-slot:top>
                <v-toolbar flat>
                    <v-dialog v-model="dialogDelete" max-width="500px">
                        <v-card>
                            <v-card-title class="text-h5">Tem certeza de que deseja excluir esta publicidade?</v-card-title>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                                <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                                <v-spacer></v-spacer>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-toolbar>
            </template>
            <template v-slot:item.actions="{ item }">
                <v-icon
                    @click="deleteItem(item)"
                >
                    mdi-delete-forever
                </v-icon>
            </template>
        </v-data-table>
    </v-card>
</template>

<script>
import Swal from "sweetalert2";
export default {
    props: {
        publicities: {
            required: true,
            type: Array
        }
    },
    data() {
        return {
            dialog: false,
            dialogDelete: false,
            search: '',
            id: null,
            headers: [
                {
                    text: "titulo",
                    align: "start",
                    sortable: false,
                    value: "titulo"
                },
                { text: 'URL do Banner', value: 'url' },
                { text: 'Imagem web', value: 'image_web' },
                { text: 'Imagem Mobile', value: 'image_mobile' },
                { text: 'clicks', value: 'clicks' },
                { text: 'Ações', value: 'actions', sortable: false }
            ]
        }
    },
    methods: {
        closeDelete () {
            this.dialogDelete = false
        },
        deleteItemConfirm () {
            this.dialogDelete = false
            this.$store.dispatch("publicity/destroyPublicity", {
                id: this.id
            })
            .then(() => {
                Swal.fire({
					title: "Sucesso!",
					text: "Publicidade apagada.",
					icon: "success",
					confirmButtonText: "Ok",
					confirmButtonColor: "#FF1744",
				}).then(() => {
                    this.$store.dispatch("publicity/getPublicities")
                })
            })
        },
        deleteItem(item) {
            this.id = item.id
            this.dialogDelete = true 
        }
    }
}
</script>

<style>

</style>