<template>
    <v-container>
        <v-row>
            <v-col 
                sm="12"
                md="12"
                class="mb-3"
            >
                <views-records-table :content="concursosViews" @emitGetConcurso="getConcursosViews"/>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import ViewsRecordsTable from '../general/ViewsRecordsTable.vue'
import axios from 'axios'
import {BASE_URL} from '../../../../services/config'
export default {
    components: {ViewsRecordsTable},
    data() {
        return {
            concursosViews: [],
            date: new Date().toISOString().substr(0, 10),
			menu: false,
            // dateFilter: ''
        }
    },
    created() {
        const today = new Date().toISOString().split('T')[0];
        
        // console.log(today);
        this.getConcursosViews(today)
    },
    methods: {
        async getConcursosViews(data) {
            try {
                this.$store.dispatch('loading/showLoader')
                const config = {
                    headers: {
                        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
                    }
                }
                this.concursosViews = await(await axios.get(`${BASE_URL}/admin/contents/concursos/views/${data}`, config)).data.data
                this.$store.dispatch('loading/closeLoader')
            } catch (error) {
                console.log(error);
                this.$store.dispatch('loading/closeLoader')
            }
        }
    }
}
</script>

<style>

</style>