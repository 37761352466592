<template>
	<v-row justify="center">
		<v-col cols="12" sm="8" md="8">
			<vaga-form :updating="false"/>
		</v-col>
	</v-row>
</template>

<script>
import VagaForm from './partials/vagaForm.vue'
export default {
    components: {VagaForm}
}
</script>

<style>

</style>