<template>
    <v-row justify="center">
		<v-col cols="12" sm="8" md="8">
			<concurso-form :updating="false"/>
		</v-col>
	</v-row>
</template>

<script>
import ConcursoForm from './partials/concursoForm.vue'
export default {
    components: {ConcursoForm}
}
</script>

<style>

</style>