<template>
    <v-card class="mx-auto" max-width="300px" style="margin-top: 20px;" elevation="0">
        <v-row align="center" justify="center">
            <v-col cols="10">
                <v-list-item three-line :to="{name: 'site.careers_page.details', params: {slug: career.slug}}">
                    <v-list-item-avatar class="profile" color="" size="90" height="auto" tile>
                        <v-img :src="career.company_logo"></v-img>
                    </v-list-item-avatar>
                </v-list-item>
            </v-col>
            <!-- <v-col cols="10" style="margin-top: -30px;">
                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-subtitle>
                            {{career.empresa.nome}}
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </v-col> -->
        </v-row>
    </v-card>
</template>

<script>
export default {
    props: {
        career: {
            required: true,
            type: Object
        }
    }

}
</script>

<style>

</style>