<template>
    <v-container>
        <v-row wrap>
            <v-col cols="12" class="mb-3">
                <payment-logs-component :logs="logs" @emitGetLogd="getPaymentsLogs"/>
            </v-col>
        </v-row>
    </v-container>
</template>


<script>
/* eslint-disable no-unused-vars */
import PaymentLogsComponent from './PaymentLogsComponent.vue'
import Swal from "sweetalert2";
import {BASE_URL} from '../../../services/config'
import axios from 'axios'
export default {
    components: {PaymentLogsComponent},
    data() {
        return {
            logs: [],
            date: new Date().toISOString().substr(0, 10),
			menu: false,
        }
    },
    created() {
        const today = new Date().toISOString().split('T')[0];
        this.getPaymentsLogs()
    },
    methods: {
        async getPaymentsLogs(date = "", email = "") {
            try {
                const config = {
                    headers: {
                        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
                    }
                }
                this.$store.dispatch('loading/showLoader')
                this.logs = await (await axios.get(`${BASE_URL}/admin/payament-logs?date=${date}&email=${email}`, config)).data.data
                // console.log(this.logs);
                this.$store.dispatch('loading/closeLoader')
                
            } catch (error) {
                console.log(error)
                this.$store.dispatch('loading/closeLoader')
            }
            
        }
    }

}
</script>

<style>

</style>