<template>
    <v-container>
        <v-row wrap justify="space-between">
			<v-col
				sm="12"
				md="12"
				class="mb-3"
			>
					<empresas-users-component :users="user" @getEmpresasUsers="getEmpregosUsers"/>
                    <!--<test-component/>-->
			</v-col>
		</v-row>
    </v-container>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import empresasUsersComponent from './empresasUsersComponent.vue'
// import {BASE_URL} from '../../../../services/config'
//import testComponent from './testComponent.vue'
// import axios from 'axios'
export default {
    components: {/*empregosUsersComponent*/ empresasUsersComponent},
    data(){
        return {
            // user: []
        }
    },
    created() {
        this.getEmpregosUsers()
    },
    methods: {

        getEmpregosUsers() {
            this.$store.dispatch("recruiters/getUsers")
        }
    },
    computed: {
        user() {
            return this.$store.state.recruiters.items
        }
    }
    /*watch: {
        user(user){
            console.log(user)
            return [
                {
                    'id': user.id,
                    'name': user.name,
                    'active': user.active,
                    'newsletter': user.newsletter,
                    'education': user.education == [] ? 'preenchido' : 'não preenchido'
                }
            ]
        }
    }*/
}
</script>

<style>

</style>