<template>
    <v-container>
        <v-row>
            <v-col cols="12">
                <v-card>
                    <v-card-title>
                        <v-text-field
                            v-model="search"
                            append-icon="mdi-magnify"
                            label="Search"
                            single-line
                            hide-details
                        ></v-text-field>
                    </v-card-title>
                    <v-data-table
                    :headers="headers"
                    :items="subscribeLogs"
                    :search="search">
                    </v-data-table>
                    <json-excel
                    :data="subscribeLogs"
                    :name="'mpesaLogs' + Math.floor(Math.random() * 100) + '.xls'">
                        <v-btn small color="red accent-3" dark>
                            <v-icon>
                                mdi-download
                            </v-icon>
                            Export Excel
                        </v-btn>
                    </json-excel>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import axios from 'axios'
import {BASE_URL} from '../../../../services/config'
import JsonExcel from 'vue-json-excel'
export default {
    components: {JsonExcel},
    data() {
        return {
            search: '',
            headers: [
                { text: 'email', value: 'recruiter_email' },
                { text: 'pagina de promoção', value: 'subscribe_page_promo' },
                { text: 'pagina de pacotes', value: 'subscribe_page_select' },
                { text: 'pagina de subscrição', value: 'subscribe_plan_page' },
                { text: 'pagina de metodos de pagamento', value: 'payment_method_page' },
                { text: 'Metodo de pagamento selecionado', value: 'selected_method' },
                { text: 'data e hora', value: 'created_at' },
            ],
            subscribeLogs: [],
            date: new Date().toISOString().substr(0, 10),
			menu: false,
            dateFilter: ''
        }
    },
    created() {
        this.getEmpresasViews()
    },
    methods: {
        async getEmpresasViews() {
            try {
                this.$store.dispatch('loading/showLoader')
                const config = {
                    headers: {
                        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
                    }
                }
                this.subscribeLogs = await (await axios.get(`${BASE_URL}/admin/subscription-page-logs`, config)).data.data
                this.$store.dispatch('loading/closeLoader')
            } catch (error) {
                console.log(error);
                this.$store.dispatch('loading/closeLoader')
            }
        }
    }
}
</script>

<style>

</style>