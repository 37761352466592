<template>
    <validation-observer ref="observer" v-slot="{  }">
        <v-form @submit.prevent="onSubmit">
            <v-card>
                <v-card-title>
					<!--<h4 class="grey--text font-weight-regular">concurso</h4>-->
					<v-chip small label dark :color="'green darken-1'">Empresa</v-chip>
				</v-card-title>
                <v-card-text>
                    <v-row wrap v-if="imageUploadPreview">
						<v-col cols="12" md="12" class="text-center">
							<label for="file" class="inputFile">
								<v-avatar size="120">
									<img :src="imageUploadPreview" alt="" />
								</v-avatar>
							</label>
						</v-col>
					</v-row>
					<v-row wrap v-else>
						<v-col cols="12" md="12" class="text-center">
							<label for="file" class="inputFile">
								<v-avatar size="120">
									<img :src="require('../../../../../assets/img/add-logo.png')" alt="" />
								</v-avatar>
							</label>
						</v-col>
					</v-row>
					<v-row wrap>
						<v-col cols="12" md="12">
							<v-file-input
								ref="image_upload"
								dense
								truncate-length="1"
								label="Carregar Imagem"
								accept="image"
								@change="onFileChange"
								id="file"
								v-show="hidden"
							></v-file-input>
							<!-- <input type="file" @change="onFileChange"> -->
						</v-col>
					</v-row>
                    <v-row wrap>
						<v-col cols="12" md="12">
							<validation-provider
								v-slot="{ errors }"
								name="Título"
								rules="required"
							>
								<v-text-field
									ref="nome"
									dense
									v-model="empresa.nome"
									label="nome"
									placeholder="Nome da empresa"
									:error-messages="errors"
                                    class="required"
									required
								></v-text-field>
							</validation-provider>
						</v-col>
					</v-row>
                    
                    <v-row wrap>
                        <v-col cols="12" md="12">
                            <v-autocomplete
								v-model="empresa.recruiter_id"
								:items="users"
                                item-text="email"
                                item-value="id"
								label="Email do proprietário"
								no-data-text="Nenhum proprietário encontrado"
								class="mt-0 opcional"
								/>
                        </v-col>
                    </v-row>
                    <v-row wrap>
						<v-col cols="12" md="12">
                            <v-autocomplete
								v-model="empresa.industria"
								:items="industrias"
								label="Industria"
								no-data-text="Nenhuma industria encontrada"
								class="mt-0 opcional"
								/>
                        </v-col>
                        <v-col cols="12" md="12">
                            <v-select
                              v-model="empresa.provincia"
                              dense
                              :items="provinces"
                              item-text="name"
                              item-value="map_code"
                              label="Provincia"
                              placeholder="Selecione a Provincia"
                              class="required"
                              @change="changeProvince"
                            ></v-select>
                        </v-col>
                        <v-col cols="12" md="12">
                            <v-select
                              v-model="empresa.distrito"
                              dense
                              :items="districts.data"
                              item-text="name"
                              item-value="id"
                              label="Distrito"
                              class="required"
                              placeholder="Selecione o Distrito"
                            ></v-select>
                        </v-col>
                        <v-col cols="12" md="12">
                            <v-text-field
                            v-model="empresa.localizacao"
                            label="Localização"
                            dense
                            ref="Endereço"
							placeholder="Endereço da empresa"
                            class="required"
							required
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-btn type="submit" small elevation="4" color="red accent-3" dark>
						<v-icon small class="mr-2"> mdi-check </v-icon>
						{{actionSubmit}}
					</v-btn>
					<v-btn
						:to="{ name: 'admin.empresas' }"
						exact
						outlined
						small
						elevation="4"
						color="red accent-3"
						dark
						class="mx-4"
					>
						<v-icon small class="mr-2"> mdi-cancel </v-icon>
						Cancelar
					</v-btn>
                    <v-btn
						outlined
						small
						elevation="4"
						color="red accent-3"
						dark
						@click.prevent="clear"
						class="ml-0"
					>
						<v-icon small class="mr-2"> mdi-eraser </v-icon>
						Limpar
					</v-btn>
                </v-card-actions>
            </v-card>
        </v-form>
    </validation-observer>
</template>

<script>
/* eslint-disable no-unused-vars */
/* eslint-disable vue/no-unused-components */
/* eslint-disable vue/require-prop-type-constructor */
import {
	ValidationProvider,
	ValidationObserver,
	setInteractionMode,
	extend,
} from "vee-validate";
import { VueEditor  } from 'vue2-editor';
import { required, digits, email, max, regex } from "vee-validate/dist/rules";
import Swal from "sweetalert2";


setInteractionMode("eager");

extend("digits", {
	...digits,
	message: "{_field_} needs to be {length} digits. ({_value_})",
});

extend("required", {
	...required,
	message: "{_field_} não pode estar vazio",
});

extend("max", {
	...max,
	message: "{_field_} may not be greater than {length} characters",
});

extend("regex", {
	...regex,
	message: "{_field_} {_value_} does not match {regex}",
});

extend("email", {
	...email,
	message: "Email must be valid",
});
export default {
    components: {
        ValidationProvider,
		ValidationObserver,
		VueEditor 
    },
    props: {
        empresa: {
            required: false,
            type: Object | Array,
            default: () => {
                return {
                    id: "",
                    image: "",
                    nome: "",
                    industria: "",
                    email: "",
                    telefone: "",
                    provincia: "",
                    distrito: "",
                    localizacao: "",
                    servicos: "",
                    sobre_nos: "",
                    email_cotacao: "",
                    recruiter_id: null

                }
            }
        },
        users: {
            type: Array,
            required: true
        },
        updating: {
			required: true,
			type: Boolean,
			default: false,
		},
    },
    data() {
        return {
            image_upload: null,
            hidden: false,
            districts: [],
            locals: [
                'Cabo Delgado',
                'Gaza',
                'Inhambane',
                'Manica',
                'Maputo',
                'Nampula',
                'Niassa',
                'Sofala',
                'Tete',
                'Zambézia'
            ],
            industrias: [
                'Administração e Secretariado Cliente',
                'Aquisições e Procurement',
                'Assistente',          
                'Auditoria e Consultoria',
                'Comunicação Social',
                'Construção Civil',
                'Contabilidade e Finanças',
                'Design',
                'Direito e Justiça',
                'Electricidade',
                'Gestão de Projectos',
                'Gestão e Assessoria',
                'Gestão Financeira',
                'Gestor Provincial',
                'Higiene e Segurança',
                'Hotelaria e Turismo',
                'informática e Programação',
                'Manutenção e Técnica',
                'Marketing e Publicidade',
                'Mecânica',
                'Meio Ambiente',
                'Monitoria e Avaliação',
                'Oficial Técnico',
                'Operário',
                'Recursos',
                'Humanos',
                'Relações Públicas',
                'Saúde',
                'Seguros',
                'Serviços Gerais',
                'Serviços Sociais',
                'Supervisão e Coordenação',
                'Topografia e Geologia',
                'Transportes e Logística',
                'Vendas/Comércio',
                'Outro'
                ],
        }
    },
    created() {
        this.getProvinces()
    },
    methods: {
        onSubmit() {
            const action = this.updating ? 'empresas/updateEmpresa' : 'empresas/storeEmpresa';
            this.$refs.observer.validate()
            const formData = new FormData()

            formData.append('nome', this.empresa.nome)
            formData.append('id', this.empresa.id)
            if(this.empresa.recruiter_id) {
                formData.append('recruiter_id', this.empresa.recruiter_id)
            }
            if(this.empresa.industria){
                formData.append('industria', this.empresa.industria)
            }
            if(this.empresa.provincia) {
                formData.append('provincia', this.empresa.provincia)
            }
            if(this.image_upload != null) {
                formData.append("image", this.image_upload);
            }
            if(this.empresa.distrito) {
                formData.append("district_id", this.empresa.distrito);
            }
            if(this.empresa.localizacao) {
                formData.append("localizacao", this.empresa.localizacao);
            }

            this.$store.dispatch(action, formData).then(() => {
				Swal.fire({
					title: "Sucesso!",
					text: this.updating ? "empresa atualizada com sucesso!" : "empresa criada com sucesso!",
					icon: "success",
					confirmButtonText: "Ok",
					confirmButtonColor: "#FF1744",
				}).then(() => {
					this.$router.push({ name: "admin.empresas" });
				});
			});
        },
        onFileChange(e) {
			this.image_upload = e;
		},
        clear() {
            this.empresa.nome = ""
            this.empresa.industria = ""
            this.empresa.provincia = ""
            this.empresa.recruiter_id = null
            this.$refs.observer.reset();
        },
        getProvinces() {
            this.$store.dispatch("empresas/getProvinces")
                .then(() => { 
                    if(this.empresa.provincia) {
                        this.changeProvince(this.empresa.provincia)
                    }
                });
        },
        changeProvince(id) {
            this.$store.dispatch("empresas/getDistricts", {id})
                .then(res => this.districts = res.data )
        }

    },
    computed: {
        // eslint-disable-next-line vue/return-in-computed-property
        imageUploadPreview() {
            if(this.image_upload){
                return URL.createObjectURL(this.image_upload);
            } else if (this.empresa.image) {
                return this.empresa.image
            }
            return
        },
        provinces() {
            return this.$store.state.empresas.items
        },
        actionSubmit() {
			return this.updating ? "Actualizar" : "Criar empresa";
		},
    }
}
</script>

<style>
.required label::after {
    content: " *";
    color: red;
}
.opcional label::after {
    content: " (opcional)";
    color: rgb(145, 145, 145);
}
</style>