<template>
    <v-container>
        <v-row wrap justify="space-between">
			<v-col
				sm="12"
				md="12"
				class="mb-3"
			>
					<contents-data-table :data="dataContent" @getAllContent="getAllEmpregosData"></contents-data-table>
			</v-col>
		</v-row>
    </v-container>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import { BASE_URL } from '../../../../services/config'
import contentsDataTable from './ContentsDataTable.vue'
//import testComponent from './testComponent.vue'
import axios from 'axios'
export default {
    // eslint-disable-next-line vue/no-unused-components
    components: {/*empregosUsersComponent*/ contentsDataTable},
    data(){
        return {
            dataContent: []
        }
    },
    created() {
        this.getAllEmpregosData()
    },
    methods: {

        getAllEmpregosData() {
            const config = {
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem('token')}`,
                }
            }
            this.$store.dispatch('loading/showLoader')
            axios.get(`${BASE_URL}/admin/allContents`, config)
            .then(res => {
                //console.log(res.data.data)
                this.dataContent = res.data.data
            })
            .catch(err => {
                console.log(err)
            })
            .finally(() => {this.$store.dispatch('loading/closeLoader')})
        }
    },
    watch: {
        
    }
}
</script>

<style>

</style>