<template>
    <div></div>
</template>

<script>
export default {
    props: {
        token: {
            required: true
        }
    },
    created() {
        this.setUserData()
    },
    methods: {
        setUserData() {
            this.$store.dispatch('loading/showLoader')
            const token = this.token
			sessionStorage.setItem('token', token)
            this.$store.dispatch('auth/checkAuthenticated')
                .then(() => {
                    this.$router.push({name: 'admin.change-password'})
                    this.$store.dispatch('loading/closeLoader')
                })
        }
    }
}
</script>

<style>

</style>