/* eslint-disable vue/require-v-for-key */
<template>
    <v-card>
        <v-row >
            <v-col cols="12" md="6">
                <v-btn 
                small
                elevation="4"
                dark
                color="red acent-3"
                tile
                @click="deleteWarning">
                    Apagar marcados
                </v-btn>
            </v-col>
        </v-row>
        <v-card-title>
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
            ></v-text-field>
        </v-card-title>
        <v-data-table
        v-model="selected"
        :headers="headers"
        :items="filterData"
        :search="search"
        class="elevation-1"
        show-select
        @click:row="viewContentDetails"
        >
            <template v-for="(col, index) in filters" v-slot:[`header.${index}`]="{ header }">
                {{header.text}}
                <v-menu :key="index" offset-y transition="slide-y-transition" :close-on-content-click="false" fixed style="position: absolute; right: 0">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn color="indigo" icon v-bind="attrs" v-on="on">
                            <v-icon small :color="filters[header.value].length ? 'red' : ''">
                                mdi-filter-variant
                            </v-icon>
                        </v-btn>
                    </template>
                    <div style="background-color: white; width: 280px">
                        <v-list flat dense class="pa-0">
                        <v-list-item>
                            <div v-if="filters.hasOwnProperty(header.value)">
                            <v-autocomplete multiple dense auto-select-first clearable chips small-chips color="light-blue lighten-3" :items="columnValueList(header.value)" append-icon="mdi-filter" v-model="filters[header.value]" :label="
                            filters[header.value] ? `filter_by: ${header.text}` : ''
                            " hide-details>
                                <template v-slot:selection="{ item, index }">
                                <v-chip small class="caption" v-if="index < 5">
                                    <span>
                                    {{ item }}
                                    </span>
                                </v-chip>
                                <span v-if="index === 5" class="grey--text caption">
                                    (+{{ filters[header.value].length - 5 }} others)
                                </span>
                                </template>
                            </v-autocomplete>
                            </div>
                        </v-list-item>
                        </v-list>
                    </div>
                </v-menu>
            </template>
        </v-data-table>
        <json-excel
        :data="filterData"
        :name="'contents' + Math.floor(Math.random() * 100) + '.xls'">
            <v-btn small color="red accent-3" dark>
                <v-icon>
                    mdi-download
                </v-icon>
                Export Excel
            </v-btn>
        </json-excel>
    </v-card>

</template>

<script>
/* eslint-disable no-unused-vars */
import Swal from 'sweetalert2'
import axios from 'axios'
import JsonExcel from 'vue-json-excel'
import { BASE_URL } from '../../../../services/config'
export default {
    components: {JsonExcel},
    props: {
        data: {
            required: true,
            type: Array
        }
    },
    data() {
        return {
            search: '',
            selected: [],
            emailsTets: 'asdsdas,basdbsdb,asdsads \n\n ok ok',
            objectEmails: {},
            ids: [],
            emailsStr: "",
            filters: {categoria: [], local: [],Industria: [], validade: []},
            headers: [
                {
                    text: 'id',
                    align: 'start',
                    sortable: false,
                    value: 'id',
                },
                { text: "categoria", value: "categoria"},
                { text: 'Titulo', value: 'titulo'},
                { text: "local", value: 'local'},
                { text: 'Instituição', value: 'instituicao'},
                {text: 'Industria', value: 'industria'},
                {text: 'validade', value: 'validade'},
                {text: 'publicado por', value: 'user'},
                {text: 'data de publicação', value: 'created_at'},
            ],
        }
    },
    computed: {
        filterData() {
            return this.data.filter((d) => {
                return Object.keys(this.filters).every((f) => {
                    return this.filters[f].length < 1 || this.filters[f].includes(d[f]);
                });
            });
        }
    },
    methods: {
        viewContentDetails(value) {
            value.categoria === "vaga" ? this.$router.push({name: 'admin.vagas.show', params: {id: value.id, toAprove: false}}) : this.$router.push({name: 'admin.concursos.show', params: {id: value.id, toAprove: false}});
        },
        columnValueList(val) {
            return this.data.map((d) => d[val]);
        },
        deletContents() {
           const config = {
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem('token')}`,
                }
            }
            const params = {
                ids_array: this.ids
            }
            this.$store.dispatch('loading/showLoader')
            axios.post(`${BASE_URL}/admin/destroy-multiple`, params, config)
                .then(() => {
                    this.$store.dispatch('loading/closeLoader')
                    Swal.fire({
						title: "Sucesso!",
						text: 'Anuncios removidos com sucesso!',
						icon: "success",
						confirmButtonText: "Ok",
						confirmButtonColor: "#FF1744",
					}).then(() => {
                        this.$emit("getAllContent")
                        // this.$router.go()
                    })
                })
        },
        deleteWarning() {
            const warning = `Têm certeza que deseja apagar estes anuncíos?`;

			Swal.fire({
				title: "Atenção!",
				text: warning,
				icon: "warning",
				showCancelButton: true,
				confirmButtonText: "Sim",
				cancelButtonText: "Não",
				confirmButtonColor: "#FF1744",
				cancelButtonColor: "#FF1744",
			}).then((result) => {
				if (result.isConfirmed) {
					this.deletContents()
				}
			});
        }
        
    },
    watch: {
        // selected(sel) {
        //     this.emailsStr = ""
        //     this.emails = sel.map((user) => {
        //         //this.emailsStr += `${user.email},`
        //         return user.email
        //     })
        //     //console.log(this.emails)
        // }
        selected(sel) {
            this.ids = sel.map((content) => {
                return content.id
            })
        }
    }
}
</script>

<style>
</style>