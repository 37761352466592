<template>
    <v-container>
        <validation-observer ref="observer" v-slot="{  }">
            <v-form @submit.prevent="onSubmit">
                        
                        <validation-provider
                        v-slot="{ errors }"
                        name="nome"
                        rules="required"
                        >
                            <v-text-field
                            v-model="user.name"
                            prepend-icon="mdi-job"
                            label="Nome"
                            :error-messages="errors"
                            />
                        </validation-provider>
                        <validation-provider
                        v-slot="{ errors }"
                        name="Email"
                        rules="required|email|emailMarrar"
                        >
                            <v-text-field
                            v-model="user.email"
                            prepend-icon="mdi-job"
                            label="Email"
                            :error-messages="errors"
                            />
                        </validation-provider>
                        <validation-provider
                            v-slot="{ errors }"
                            name="Tipo de conta"
                            rules="required"
                        >
                            <v-select
                            v-model="user.acount_type"
                            :items="tipo_conta"
                            :error-messages="errors"
                            prepend-icon="mdi-none"
                            label="Tipo de conta"
                            data-vv-name="select"
                            required
                            ></v-select>
                        </validation-provider>
                    
                        <v-btn type="submit" small elevation="4" color="red accent-3" dark>
						<v-icon small class="mr-2"> mdi-check </v-icon>
                            Criar conta
                        </v-btn>
                   
            </v-form>
        </validation-observer>
    </v-container>
</template>

<script>
    import { required, digits, email, max, regex } from 'vee-validate/dist/rules'
    import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'
    import Swal from "sweetalert2";

    setInteractionMode('eager')

    extend('digits', {
        ...digits,
        message: '{_field_} needs to be {length} digits. ({_value_})',
    })

    extend('required', {
        ...required,
        message: 'O campo {_field_} não pode estar vazio',
    })

    extend('max', {
        ...max,
        message: '{_field_} may not be greater than {length} characters',
    })

    extend('regex', {
        ...regex,
        message: '{_field_} {_value_} does not match {regex}',
    })

    extend('email', {
        ...email,
        message: 'Deve ser um email valido',
    })
    extend('emailMarrar', {
        validate(value) {
            var pattern = new RegExp(/([\w\d\W\D])+@marrar.co.mz/g); // fragment locator
		return pattern.test(value);
        }, message: 'Só emails com dominio da marrar são validos.'
    })
export default {
    components: { ValidationObserver, ValidationProvider},
    data() {
        return {
            tipo_conta: ["Administrador", "Gestor de conteudo"],
            role_id: ''
        }
    },
    props: {
        user: {
            required: false,
            type: Object,
            default() {
                return {
                    name: '',
                    email: '',
                    acount_type: ''
                }
            }
        }
    },
    methods: {
        onSubmit() {
			this.$refs.observer.validate();
            if(this.user.acount_type == this.tipo_conta[0]){
                this.role_id = 2
            }
            if(this.user.acount_type == this.tipo_conta[1]){
                this.role_id = 1
            }
            const params = {
                name: this.user.name,
                email: this.user.email,
                role_id: this.role_id
            }

            this.$store.dispatch("admin/newUser", params)
            .then(() => {
                Swal.fire({
					title: "Sucesso!",
					text: "Conta de utilizador criada com sucesso.",
					icon: "success",
					confirmButtonText: "Ok",
					confirmButtonColor: "#FF1744",
				}).then(() => {
                    this.user.name = ""
                    this.user.email = ""
                    this.user.acount_type = ""
                    this.$refs.observer.reset();
                    this.$store.dispatch("admin/getUsers")
					this.$emit("close-dialog")
				});
            })
        }
    },
    computed: {

    }

}
</script>

<style>

</style>