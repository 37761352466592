<template>
    <v-row justify="center">
		<v-col cols="12" sm="8" md="8">
            <concurso-form :updating="true" :concurso="content" :toAprove="toAprove"/>
        </v-col>
	</v-row>
</template>

<script>
import ConcursoForm from './partials/concursoForm.vue'
export default {
    components: {ConcursoForm},
    props: {
        id: {
            required: true
        },
        toAprove: {
            required: false,
			type: Boolean,
			default: false,
        }
    },
    data() {
        return {
            content: {
				id: "",
				image: "",
				titulo: "",
				instituicao: "",
				validade: "",
				categoria_id: "",
				local: "",
				fonte: "",
				conteudo: "",
			},
        }
    },
    created() {
        this.getConcurso(this.id)
    },
    methods: {
        getConcurso(id) {
            this.$store.dispatch("concursos/getConcurso", id)
            .then((res) => {
                this.content = res.data.data
                //this.selected_locals = this.content.locals.map((local) => local.id);
               
            })
        }
    }
    
}
</script>

<style>

</style>