<template>
  <v-container>
        <v-row wrap align="center" justify="space-between">
            <v-col cols="12" md="4">
                <v-dialog
                transition="dialog-bottom-transition"
                width="600px"
                >
                <template v-slot:activator="{ on, attrs }">
					<v-btn
						small
						elevation="4"
                        v-bind="attrs"
                        v-on="on"
						dark
						color="red acent-3"
						tile
					>
						<v-icon small class="mr-2">mdi-plus</v-icon>
						Nova publicidade
					</v-btn>
                </template>
                <template v-slot:default="dialog">
                    <v-toolbar
                    color="#e61f31"
                    dark
                    >
					<v-row>
                        <v-col cols="10">
                            Nova publicity
                        </v-col>
                        <v-col cols="2">
                            <v-btn color="#e61f31" @click="dialog.value = false">
                                <v-icon color="" large>
                                    mdi-close
                                </v-icon>
                            </v-btn>
                        </v-col>				
                    </v-row>
				</v-toolbar>
                <v-card>
                    <new-publicity v-on:close-dialog="dialog.value = false"/>
                </v-card>
                </template>
                </v-dialog>
			</v-col>

				<v-col md="3">
					
				</v-col>
        </v-row>
		<v-row wrap justify="space-between">
			<v-col
				sm="12"
				md="12"
				class="mb-3"
			>
					<publicity-data-table :publicities="pub"/>
			</v-col>
		</v-row>
    </v-container>
</template>

<script>
import NewPublicity from './newPublicityForm.vue'
import publicityDataTable from './publicityDataTable.vue'
export default {
	components: {NewPublicity, publicityDataTable},
	data() {
		return {
            pub: []
		}
	},
	created() {
		this.getPublicities()
	},
	methods: {
		getPublicities(){
            this.$store.dispatch("publicity/getPublicities")
        }
	},
	computed: {
		publicities() {
            return this.$store.state.publicity?.items
        }
	},
    watch: {
        publicities(pub) {
            this.pub = pub
            console.log(pub)
        }
    }
}
</script>

<style>

</style>