<template>
    <validation-observer ref="observer" v-slot="{  }">
        <div>
            <v-form @submit.prevent="submitNotification">
                <v-card max-height="1000px">
                    <v-card-title>
                        <span class="text-h5">{{notificationType === 1 ? 'Enviar newsletter!' : 'Notificar usuarios!'}}</span>
                    </v-card-title>
                    <v-card-subtitle>
                        {{totalUsers}} usuarios serão notificados
                    </v-card-subtitle>
                    <v-card-text>
                        <v-row>
                            <v-col cols="8">
                                <validation-provider
                                    v-slot="{ errors }"
                                    name="assunto"
                                    rules="required"
                                >
                                    <v-text-field 
                                    ref="assunto"
                                    label="Assunto" 
                                    v-model="emailSubject"
                                    placeholder="Assunto do email"
                                    :error-messages="errors"
                                    required>
                                    </v-text-field>
                                </validation-provider>
                            </v-col>
                        </v-row>
                        <v-row v-if="notificationType === 1">
                            <v-col cols="6">
                                <v-menu
                                    ref="menu"
                                    v-model="menu"
                                    :close-on-content-click="false"
                                    :return-value.sync="date"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            dense
                                            v-model="dateFilter"
                                            label="Selecione a Data"
                                            prepend-icon="mdi-calendar"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        dense
                                        v-model="dateFilter"
                                        no-title
                                        scrollable
                                    >
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="menu = false">
                                            Cancel
                                        </v-btn>
                                        <v-btn text color="primary" @click="$refs.menu.save(date)">
                                            OK
                                        </v-btn>
                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="6">
                                <v-btn
                                    exact
                                    small
                                    elevation="4"
                                    color="red accent-3"
                                    dark
                                    class="mx-4"
                                    @click="getEmpregos"
                                >
                                    Gerar newsletter
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <validation-provider
                                    v-slot="{ errors }"
                                    name="Título"
                                    rules="required"
                                >
                                    <vue-editor
                                    ref="conteudo" 
                                    placeholder="Corpo do email" 
                                    style="height: 350px;"
                                    :editor-toolbar="customToolbar"
                                    v-model="emailBody"
                                    :aria-errormessage="errors"
                                    required>
                                </vue-editor>
                                </validation-provider>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn
                            type="submite"
                            exact
                            style="margin-top: 50px;"
                            small
                            elevation="4"
                            color="red accent-3"
                            dark
                            class="mx-4"
                        >
                            <v-icon small class="mr-2"> mdi-send </v-icon>
                            Enviar notificação
                        </v-btn>
                        <v-btn
                            exact
                            style="margin-top: 50px;"
                            outlined
                            small
                            elevation="4"
                            color="red accent-3"
                            dark
                            class="mx-4"
                            @click="closeDialog"
                        >
                            <v-icon small class="mr-2"> mdi-cancel </v-icon>
                            Cancelar
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-form>
        </div>
    </validation-observer>
</template>

<script>
/* eslint-disable no-unreachable */
/* eslint-disable no-unused-vars */
import {
	ValidationProvider,
	ValidationObserver,
	setInteractionMode,
	extend,
} from "vee-validate";
import { required, digits, email, max, regex } from "vee-validate/dist/rules";
import { VueEditor  } from 'vue2-editor';
import {BASE_URL} from '../../../../../services/config'
import axios from 'axios'
import Swal from "sweetalert2";
export default {
    components: {
        ValidationProvider,
		ValidationObserver,
        VueEditor
    },
    props: {
        users_id: {
            required: false,
            type: Array
        },
        notificationType: {
            required: true,
            type: Number
        }
    },
    data() {
        return {
            emailSubject: "",
            emailBody: "",
            dateFilter: '',
            dateFilter2: '',
            objectEmails: {},
            totalUsers: [],
            filters: {active: [], newsletter: []},
            headers: [
                {
                    text: 'id',
                    align: 'start',
                    sortable: false,
                    value: 'id',
                },
                { text: "Email", value: "email"},
                { text: 'Nome', value: 'name'},
                { text: "Ativo", value: 'active'},
                { text: 'newsletter', value: 'newsletter'},
                {text: 'verificado', value: 'verified'},
                {text: 'Subscrição', value: 'subscribe'},
                { text: 'Actions', value: 'actions', sortable: false },
            ],
            customToolbar: [
				[{header: [false, 1, 2, 3, 4, 5, 6]}],
				["bold", "italic", "underline"],
				[
					{ align: "" },
					{ align: "center" },
					{ align: "right" },
					{ align: "justify" }
				],
				[{list: 'bullet'}, {list: "ordered"}, { list: "check" }],
				[{color: []}],
				["link"]
			],
            date: new Date().toISOString().substr(0, 10),
			menu: false,
            date2: new Date().toISOString().substr(0, 10),
			menu2: false,
        }
    },
    created() {
        if (this.notificationType === 1) {
            this.totalUsers = this.users_id.filter(user => user.newsletter === "ativado")
                                    .map(user => {
                                        return user.id
                                    }).length
        }
        else if (this.notificationType === 2) {
            this.totalUsers = this.users_id.map(user => {
                return user.id
            }).length
        }
    },
    computed: {
    },
    watch: {
        users_id(users) {
            if (this.notificationType === 1) {
                this.totalUsers = users.filter(user => user.newsletter === "ativado")
                                        .map(user => {
                                            return user.id
                                        }).length
            }
            else if (this.notificationType === 2) {
                this.totalUsers = users.map(user => {
                    return user.id
                }).length
            }
        }
    },
    methods: {
        getEmpregos() {
            // eslint-disable-next-line no-unused-vars
            
            this.$store.dispatch('loading/showLoader')
            const config = {
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem('token')}`,
                }
            }

            axios.get(`${BASE_URL}/admin/contents/filterByDate/1/${this.dateFilter}`, config)
                .then(res => {
                    let emailBodyGenerate = "<p>Veja as vagas disponíveis hoje</p>";
                    console.log(res.data.data)
                    res.data.data.forEach(vaga => {
                        let link = `<p>‣ <a href="https://empregos.gugla.co.mz/contents/${vaga.id}/show" rel="noopener noreferrer" target="_blank" style="color: rgb(0, 71, 178);">${vaga.titulo}</a><span style="color: rgba(0, 0, 0, 0.87);"></span></p>`
                        emailBodyGenerate += link
                    })
                    emailBodyGenerate += `<p>‣ E mais;</p><p>&nbsp;</p><p>‣ Confira estas e outras novas vagas&nbsp;<a href="https://empregos.gugla.co.mz" rel="noopener noreferrer" target="_blank">Aqui</a></p>`
                    this.emailBody = emailBodyGenerate
                })
                .finally(() => {this.$store.dispatch('loading/closeLoader')})
        },
        closeDialogNewsletter() {
            this.newslleterCopy = ""
            this.dialogNewsletter = false
        },
        submitNotification() {
            this.$store.dispatch('loading/showLoader')
            this.$refs.observer.validate();
             let id = []
            if(this.notificationType === 1) {
                id = this.users_id.filter(user => user.newsletter === "ativado")
                                    .map(user => {
                                        return user.id
                                    })
            }
            else if (this.notificationType === 2) {
                id = this.users_id.map(user => {
                    return user.id
                })
            }
            // console.log(id);
            // return
            const params = {
                candidates_id: id,
                subject: this.emailSubject,
                body: this.emailBody
            }
            const config = {
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem('token')}`,
                }
            }

            axios.post(`${BASE_URL}/admin/notify_empregos_users`, params, config)
                .then(() => {
                    Swal.fire({
                        title: "Sucesso!",
                        text: "Usuarios notificados com sucesso.",
                        icon: "success",
                        confirmButtonText: "OK",
                        confirmButtonColor: "#FF1744"
                    }).then(() => {
                        this.emailSubject = ""
                        this.emailBody = ""
                        this.$emit('closeDialog')
                    })
                })
                .finally(() => {this.$store.dispatch('loading/closeLoader')})

        },
        closeDialog(){
            this.users_id = []
            this.emailSubject = ""
            this.emailBody = ""
            this.$emit('closeDialog')
        }

    }
}
</script>

<style>

</style>