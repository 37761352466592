<template>
    <v-container>
        <v-row>
            <v-col cols="12">
                <v-card>
                    <v-card-title>
                        <v-text-field
                            v-model="search"
                            append-icon="mdi-magnify"
                            label="Search"
                            single-line
                            hide-details
                        ></v-text-field>
                    </v-card-title>
                    <v-data-table
                    :headers="headers"
                    :items="empresasLeads"
                    :search="search">
                    </v-data-table>
                    <json-excel
                    :data="empresasLeads"
                    :name="'viewsConcursos' + Math.floor(Math.random() * 100) + '.xls'">
                        <v-btn small color="red accent-3" dark>
                            <v-icon>
                                mdi-download
                            </v-icon>
                            Export Excel
                        </v-btn>
                    </json-excel>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import axios from 'axios'
import {BASE_URL} from '../../../../services/config'
import JsonExcel from 'vue-json-excel'
export default {
    components: {JsonExcel},
    data() {
        return {
            search: '',
            headers: [
                { text: 'data e hora', value: 'created_at' },
                { text: 'nome',value: 'nome'},
                { text: 'email', value: 'email' },
                { text: 'telefone', value: 'telefone'},
                { text: 'comentario', value: 'comentario'},
                { text: 'nome da empresa', value: 'empresa'},
            ],
            empresasLeads: [],
            date: new Date().toISOString().substr(0, 10),
			menu: false,
            dateFilter: ''
        }
    },
    created() {
        this.getEmpresasViews()
    },
    methods: {
        async getEmpresasViews() {
            try {
                this.$store.dispatch('loading/showLoader')
                const config = {
                    headers: {
                        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
                    }
                }
                this.empresasLeads = await (await axios.get(`${BASE_URL}/admin/empresa/views/details`, config)).data.data.leads
                this.$store.dispatch('loading/closeLoader')
            } catch (error) {
                console.log(error);
                this.$store.dispatch('loading/closeLoader')
            }
        }
    }
}
</script>

<style>

</style>