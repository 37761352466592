<template>
	<v-card
		
		tile
	>
		<v-app-bar
		color="#e61f31"
		dark
		>
		<v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

		<v-toolbar-title>Gugla</v-toolbar-title>
		<v-spacer></v-spacer>
		<v-toolbar-items>
			<v-menu
			offset-y
			>
				<template v-slot:activator="{ on, attrs }">
					<v-btn v-bind="attrs" v-on="on" depressed rounded text small>
						<div>
							{{user.email}}
						</div>
					</v-btn>
				</template>
				<v-list>
					<v-list-item @click.prevent="logout">
						<v-list-item-icon><v-icon>mdi-logout</v-icon></v-list-item-icon>
						<v-list-item-title>Logout</v-list-item-title>
					</v-list-item>
				</v-list>
			</v-menu>

		</v-toolbar-items>
		</v-app-bar>

		<v-navigation-drawer
		color="#e61f31"
		v-model="drawer"
		fixed
		clipped
		app
		enable-resize-watcher
		height="100vh"
		>
		<!--temporary-->
		<v-list
			nav
			dense
		>
			<v-list-item>
			
				<v-app-bar elevation="0" color="#e61f31">
				
				
					<v-app-bar-nav-icon style="color:white;" @click="drawer = !drawer"></v-app-bar-nav-icon>
				
				<v-toolbar-title style="color:white;">Gugla</v-toolbar-title>
				</v-app-bar>

			</v-list-item>
			<v-list-item :to="{name: 'admin.dashboard'}">
				<v-list-item-title style="color:white;">Dashboard</v-list-item-title>
			</v-list-item>
			<v-list-group
			no-action>
				<template v-slot:activator>
						<v-list-item-title style="color:white;">Gestão de conteudo</v-list-item-title>
				</template>

				<v-list-item :to="{name : 'admin.vagas'}">
					<v-list-item-icon>
					<v-icon color="white">mdi-briefcase</v-icon>
					</v-list-item-icon>
					<v-list-item-title style="color:white;">Vagas</v-list-item-title>
				</v-list-item>
				<v-list-item :to="{name: 'admin.vagas-toaprove'}">
					<v-list-item-icon>
						<v-icon color="white">mdi-briefcase</v-icon>
					</v-list-item-icon>
					<v-list-item-title style="color:white; font-size: 0.7em;">Vagas pendentes</v-list-item-title>
				</v-list-item>
				
				<v-list-item :to="{name: 'admin.concursos'}">
					<v-list-item-icon>
						<v-icon color="white">mdi-briefcase-outline</v-icon>
					</v-list-item-icon>
					<v-list-item-title style="color:white;">Concursos</v-list-item-title>
				</v-list-item>
				<v-list-item :to="{name: 'admin.concursos-toaprove'}">
					<v-list-item-icon>
						<v-icon color="white">mdi-briefcase-outline</v-icon>
					</v-list-item-icon>
					<v-list-item-title style="color:white; font-size: 0.7em;">Concursos pendentes</v-list-item-title>
				</v-list-item>


				<v-list-item :to="{name: 'admin.allContents'}">
					<v-list-item-icon>
					<v-icon color="white">mdi-content-duplicate</v-icon>
					</v-list-item-icon>
					<v-list-item-title style="color:white;">Conteudo</v-list-item-title>
				</v-list-item>
				<v-list-item :to="{name: 'admin.publicity'}">
					<v-list-item-icon>
					<v-icon color="white">mdi-waze</v-icon>
					</v-list-item-icon>
					<v-list-item-title style="color:white;">Publicidade</v-list-item-title>
				</v-list-item>
			</v-list-group>

			<v-list-group
			no-action>
				<template v-slot:activator>
					<v-list-item-content>
						<v-list-item-title style="color:white;">Usuarios</v-list-item-title>
					</v-list-item-content>
				</template>

				<v-list-item :to="{name : 'admin.admin-users'}">
					<v-list-item-icon>
						<v-icon color="white">mdi-account-cog</v-icon>
					</v-list-item-icon>
					<v-list-item-title style="color:white;">Administração</v-list-item-title>
				</v-list-item>

				<v-list-item :to="{name: 'admin.empregos-users'}">
					<v-list-item-icon>
						<v-icon color="white">mdi-briefcase</v-icon>
					</v-list-item-icon>
					<v-list-item-title style="color:white;">Empregos</v-list-item-title>
				</v-list-item>
				<v-list-item :to="{name: 'admin.empresas-users'}">
						<v-list-item-icon>
							<v-icon color="white">mdi-domain</v-icon>
						</v-list-item-icon>
						<v-list-item-title style="color:white;">Empresas</v-list-item-title>
					</v-list-item>

				<!-- <v-list-group no-action sub-group>
					<template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title style="color:white;">Empresas</v-list-item-title>
            </v-list-item-content>
          </template>
					<v-list-item :to="{name: 'admin.empresas-users'}">
						<v-list-item-icon>
							<v-icon color="white">mdi-account-eye</v-icon>
						</v-list-item-icon>
						<v-list-item-title style="color:white;">Recrutadores</v-list-item-title>
					</v-list-item>

					<v-list-item :to="{name: 'admin.empresas'}">
						<v-list-item-icon>
							<v-icon color="white">mdi-domain</v-icon>
						</v-list-item-icon>
						<v-list-item-title style="color:white;">Empresas</v-list-item-title>
					</v-list-item>
					<v-list-item :to="{name: 'admin.empresas.not-aproved'}">
						<v-list-item-icon>
							<v-icon color="white">mdi-domain</v-icon>
						</v-list-item-icon>
						<v-list-item-title style="color:white; font-size: 0.7em;">Empresas pendentes</v-list-item-title>
					</v-list-item>
				</v-list-group> -->

			</v-list-group>
			<v-list-group
			no-action>
				<template v-slot:activator>
					<v-list-item-content>
						<v-list-item-title style="color:white;">Empresas</v-list-item-title>
					</v-list-item-content>
				</template>
					<v-list-item :to="{name: 'admin.empresas'}">
						<v-list-item-icon>
							<v-icon color="white">mdi-domain</v-icon>
						</v-list-item-icon>
						<v-list-item-title style="color:white;">Empresas</v-list-item-title>
					</v-list-item>
					<v-list-item :to="{name: 'admin.empresas.not-aproved'}">
						<v-list-item-icon>
							<v-icon color="white">mdi-domain-remove</v-icon>
						</v-list-item-icon>
						<v-list-item-title style="color:white; font-size: 0.7em;">Empresas pendentes</v-list-item-title>
					</v-list-item>
					<v-list-item :to="{name: 'admin.careers'}">
						<v-list-item-icon>
							<v-icon color="white">mdi-page-previous-outline</v-icon>
						</v-list-item-icon>
						<v-list-item-title style="color:white; font-size: 0.7em;">Pagínas de careiras</v-list-item-title>
					</v-list-item>
			</v-list-group>
			<v-list-group no-action>
				<template v-slot:activator>
					<v-list-item-content>
						<v-list-item-title style="color:white;">logs</v-list-item-title>
					</v-list-item-content>
				</template>
				<v-list-item :to="{name: 'admin.payments-logs'}">
					<v-list-item-title style="color:white;">Registos de pagamentos</v-list-item-title>
				</v-list-item>
				<v-list-item :to="{name: 'admin.mpesa'}">
					<v-list-item-title style="color:white;">Mpesa Logs</v-list-item-title>
				</v-list-item>
				
				<v-list-item :to="{name: 'admin.concursos-views'}">
					<v-list-item-title style="color:white;">Concursos Views</v-list-item-title>
				</v-list-item>
				<v-list-item :to="{name: 'admin.empresas.views'}">
					<v-list-item-title style="color:white;">Empresas Views</v-list-item-title>
				</v-list-item>
				<v-list-item :to="{name: 'admin.empresas.leads'}">
					<v-list-item-title style="color:white;">Empresas Leads</v-list-item-title>
				</v-list-item>
				<v-list-item :to="{name: 'admin.empresas.details'}">
					<v-list-item-title style="color:white;">Empresas detalhes</v-list-item-title>
				</v-list-item>
				<v-list-item :to="{name: 'admin.subscription'}">
					<v-list-item-title style="color:white;">pagina de subscrição</v-list-item-title>
				</v-list-item>
			</v-list-group>
			<v-list-group no-action>
				<template v-slot:activator> 
					<v-list-item-content>
						<v-list-item-title style="color:white;">Configurações</v-list-item-title>
					</v-list-item-content>
				</template>
				<v-list-item :to="{name: 'admin.payment-plans'}">
					<v-list-item-title style="color:white;">Planos de pagamentos</v-list-item-title>
				</v-list-item>
				<v-list-item :to="{name: 'admin.categorias'}">
					<v-list-item-title style="color:white;">Catgorias</v-list-item-title>
				</v-list-item>
			</v-list-group>
			<v-list-item :to="{name: 'admin.change-password'}">
				<v-list-item-title style="color:white;">Alterar senha</v-list-item-title>
			</v-list-item>
		</v-list>
		</v-navigation-drawer>
	</v-card>
</template>


<script>
export default {
    data() {
        return {
			drawer: true,
			group: null,
        }
    },
	methods: {
		logout() {
			this.$store.dispatch('auth/logout')
			.then(() => {
				this.$router.push({name: 'admin.login'})
			})
			.catch(() => {
				this.$router.push({name: 'admin.login'})
			})
		}
	},
	computed: {
		user() {
			const user = this.$store.state.auth.user

			return user
		}
	}
}
</script>

<style>

</style>