<template>
    <v-container>
        <validation-observer ref="observer" v-slot="{  }">
            <v-form @submit.prevent="onSubmit">
                        
                        <validation-provider
                        v-slot="{ errors }"
                        name="Titulo"
                        rules="required"
                        >
                            <v-text-field
                            v-model="publicity.titulo"
                            label="Titulo/Nome da empresa"
                            prepend-icon="mdi-image-text"
                            placeholder="Empresa ou nome sugestivo para os banners"
                            :error-messages="errors"
                            />
                        </validation-provider>
                        <validation-provider
                        v-slot="{ errors }"
                        name="Url"
                        rules="required"
                        >
                            <v-text-field
                            v-model="publicity.url"
                            prepend-icon="mdi-link-variant-plus"
                            label="Url"
                            placeholder="Link para onde os banners devem apontar"
                            :error-messages="errors"
                            />
                        </validation-provider>
                        <validation-provider
                        v-slot="{error}"
                        name="Imagem web"
                        rules="required">
                            <v-file-input
                            accept="image/*"
                            label="Banner desktop"
                            prepend-icon="mdi-camera-image"
                            placeholder="imagem para apresentação em dispositivos de tela maior"
                            @change="onFileWeb"
                            :error-messages="error">
                            </v-file-input>
                        </validation-provider>
                         <validation-provider
                        v-slot="{error}"
                        name="Imagem mobile"
                        rules="required">
                            <v-file-input
                            accept="image/*"
                            label="Banner mobile"
                            prepend-icon="mdi-camera-image"
                            placeholder="imagem para apresentação em dispositivos de tela menor"
                            @change="onFileMobile"
                            :error-messages="error">
                            </v-file-input>
                        </validation-provider>
                        <validation-provider
                            v-slot="{ errors }"
                            name="É necessario indicar em que aplicações os banners aparecem"
                            rules="required"
                        >
                        
                        <v-select
                            v-model="selected"
                            :items="items"
                            :error-messages="errors"
                            attach
                            chips
							item-text="name"
							item-value="id"
                            label="Aplicações"
                            prepend-icon="mdi-code-tags-check"
                            placeholder="Em que aplicação a publicidade deve aparecer?"
                            multiple
                        ></v-select>
                        </validation-provider>
                        <v-btn type="submit" small elevation="4" color="red accent-3" dark>
						<v-icon small class="mr-2"> mdi-check </v-icon>
                            Adicionar publicidade
                        </v-btn>
                   
            </v-form>
        </validation-observer>
    </v-container>
</template>

<script>
    import { required, digits, email, max, regex } from 'vee-validate/dist/rules'
    import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'
    // eslint-disable-next-line no-unused-vars
    import Swal from "sweetalert2";

    setInteractionMode('eager')

    extend('digits', {
        ...digits,
        message: '{_field_} needs to be {length} digits. ({_value_})',
    })

    extend('required', {
        ...required,
        message: 'O campo {_field_} não pode estar vazio',
    })

    extend('max', {
        ...max,
        message: '{_field_} may not be greater than {length} characters',
    })

    extend('regex', {
        ...regex,
        message: '{_field_} {_value_} does not match {regex}',
    })

    extend('email', {
        ...email,
        message: 'Deve ser um email valido',
    })
    extend('emailMarrar', {
        validate(value) {
            var pattern = new RegExp(/([\w\d\W\D])+@marrar.co.mz/g); // fragment locator
		return pattern.test(value);
        }, message: 'Só emails com dominio da marrar são validos.'
    })
export default {
    components: { ValidationObserver, ValidationProvider},
    data() {
        return {
            tipo_conta: ["Administrador", "Gestor de conteudo"],
            image_web: null,
            image_mobile: null,
            items: [{id:1, name:'empregos'}, {id: 2, name: 'empresas'}],
            selected: [],
        }
    },
    props: {
        publicity: {
            required: false,
            type: Object,
            default() {
                return {
                    titulo: '',
                    url: '',
                }
            }
        }
    },
    methods: {
        onSubmit() {
            const formData = new FormData();
            formData.append('titulo', this.publicity.titulo);
            formData.append('url', this.publicity.url);
            formData.append('image_web', this.image_web);
            formData.append('image_mobile', this.image_mobile);
            formData.append('categorias', JSON.stringify(this.selected));

            this.$store.dispatch("publicity/newPublicity", formData)
            .then(() => {
                Swal.fire({
					title: "Sucesso!",
					text: "Conta de utilizador criada com sucesso.",
					icon: "success",
					confirmButtonText: "Ok",
					confirmButtonColor: "#FF1744",
				}).then(() => {
                    this.publicity.titulo = ""
                    this.publicity.url = ""
                    this.image_web = null
                    this.image_mobile = null
                    this.selected = []
                    this.$refs.observer.reset();
                    this.$store.dispatch("publicity/getPublicities")
					this.$emit("close-dialog")
				});
            })
        },
        click(item){
            console.log(item)
        },
        onFileWeb(e) {
			this.image_web = e;
		},
        onFileMobile(e) {
            this.image_mobile = e
        }
    },
    computed: {

    }

}
</script>

<style>

</style>