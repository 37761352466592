<template>
  <v-card>
    <v-card-title>
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Pesquisar"
                single-line
                hide-details
            ></v-text-field>
        </v-card-title>
      <v-data-table
        :headers="headers"
        :items="desserts"
        :search="search"
        sort-by="calories"
        class="elevation-1"
      >
        <template v-slot:top>
          <v-toolbar
            flat
          >
            <v-toolbar-title>Categorias</v-toolbar-title>
            <v-divider
              class="mx-4"
              inset
              vertical
            ></v-divider>
            <v-spacer></v-spacer>
            <v-dialog
              v-model="dialog"
              max-width="500px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="red accent-3"
                  dark
                  class="mb-2"
                  v-bind="attrs"
                  v-on="on"
                >
                  Adicionar Categoria
                </v-btn>
              </template>
              <v-card width="400px">
                <v-card-title>
                  <span class="text-h5">{{ formTitle }}</span>
                </v-card-title>
    
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col
                        cols="12"
                        sm="12"
                        md="12"
                      >
                        <v-text-field
                          v-model="editedItem.name"
                          label="Nome da categoria"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
    
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="close"
                  >
                    Cancelar
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="save"
                  >
                    Adicionar
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="text-h5">Tem certeza que deseja apagar está categoria?</v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeDelete">Cancelar</v-btn>
                  <v-btn color="blue darken-1" text @click="deleteItemConfirm">Apagar</v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:item.actions="{ item }">
          <!-- <v-icon
            small
            class="mr-2"
            @click="editItem(item)"
          >
            mdi-pencil
          </v-icon> -->
          <v-icon
            small
            @click="deleteItem(item)"
          >
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
  </v-card>
  </template>
  <script>
//   import Swal from 'sweetalert2'
  import { BASE_URL } from '../../../../services/config'
  import axios from 'axios'
import Swal from 'sweetalert2'
  export default {
    data: () => ({
      dialog: false,
      search: '',
      dialogDelete: false,
      headers: [
        {
          text: 'ID',
          align: 'start',
          sortable: false,
          value: 'id',
        },
        { text: 'Categoria', value: 'name' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      desserts: [],
      editedIndex: -1,
      editedItem: {
        id: '',
        name: '',
      },
      defaultItem: {
        id: '',
        name: '',
      },
    }),

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'Nova categoria' : 'Editar Categoria'
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },

    created () {
      this.getContentCategories()
    },

    methods: {
      async getContentCategories() {
        this.$store.dispatch('loading/showLoader')
        const config = {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('token')}`,
            }
        }
        const categorias = (await axios.get(`${BASE_URL}/admin/content-category`, config)).data.data
        this.desserts = categorias
        console.log(this.desserts);
        this.$store.dispatch('loading/closeLoader')
      },


      deleteItem (item) {
        this.editedIndex = this.desserts.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      async deleteItemConfirm () {
        // this.desserts.splice(this.editedIndex, 1)
        const category = this.desserts[this.editedIndex]
        // console.log(category);
        this.$store.dispatch('loading/showLoader')
        const config = {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          }
        }
        try {
          // console.log(this.editedIndex);
          await axios.delete(`${BASE_URL}/admin/content-category/${category.id}`, config)
          await Swal.fire(
              {
                // position: "top-end",
                icon: "success",
                title: "Categoria apagada com sucesso",
                showConfirmButton: false,
                timer: 1500
              }
            )
            this.$store.dispatch('loading/closeLoader')
            this.getContentCategories()

        } catch (error) {
          console.log(error);
          await Swal.fire(
              {
                // position: "top-end",
                icon: "warning",
                title: "Oppsss, algo deu errado!",
                showConfirmButton: false,
                timer: 1500
              }
            )
            this.$store.dispatch('loading/closeLoader')
        }
        this.closeDelete()
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      async save () {
        if (this.editedIndex > -1) {
          Object.assign(this.desserts[this.editedIndex], this.editedItem)
        } else {
          // console.log(this.editedItem);
          this.$store.dispatch('loading/showLoader')
          try {
            const config = {
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem('token')}`,
                }
            }
            await axios.post(`${BASE_URL}/admin/content-category`, this.editedItem, config)
            await Swal.fire(
              {
                // position: "top-end",
                icon: "success",
                title: "Categoria adicionada com sucesso",
                showConfirmButton: false,
                timer: 1500
              }
            )
            this.$store.dispatch('loading/closeLoader')
            this.getContentCategories()
          } catch (error) {
            console.log(error);
            await Swal.fire(
              {
                // position: "top-end",
                icon: "warning",
                title: "Oppsss, algo deu errado!",
                showConfirmButton: false,
                timer: 1500
              }
            )
          }

        }
        this.close()
      },
    },
  }
</script>