<template>
  <div>
    <v-row>
      <v-col cols="12" class="mb-6">
        <v-btn
          :to="{
            name: toAprove ? 'admin.empresas.not-aproved' : 'admin.empresas',
          }"
          class="ml-0"
          tile
          elevation="4"
          dark
          color="red acent-3"
          small
        >
          <v-icon left small> mdi-arrow-left </v-icon>Voltar</v-btn
        >
      </v-col>
    </v-row>
    <v-row wrap align="center" justify="space-between">
      <v-col cols="12" md="12">
        <v-card class="mx-auto" outlined>
          <v-list-item three-line>
            <v-list-item-content>
              <div class="text-overline">{{ empresa.industria }}</div>
              <v-list-item-title class="text-h4 mb-1">
                {{ empresa.nome }}
              </v-list-item-title>
              <div class="text-overline mb-4">
                {{ empresa.district ? empresa.district.province.name : "" }},
                {{ empresa.localizacao }}
              </div>
            </v-list-item-content>
            <v-list-item-avatar tile size="80" color="grey">
              <v-img :src="empresa.image"></v-img>
            </v-list-item-avatar>
          </v-list-item>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-container fill-height>
        <v-row wrap justify="center" align="center">
          <v-col cols="12" md="12">
            <v-container fill-height fluid>
              <v-row wrap justify="center" align="center">
                <v-col>
                  <v-tabs
                    v-model="tab"
                    background-color="#FF4500"
                    icons-and-text
                    dark
                    grow
                  >
                    <v-tabs-slider color="#2F4858"></v-tabs-slider>
                    <!-- <v-tabs-slider color="#E61F31"></v-tabs-slider> -->
                    <v-tab key="services_tab">
                      <div class="caption">Serviços</div>
                      <v-icon>mdi-account</v-icon>
                    </v-tab>
                    <v-tab key="sobreNos_tab">
                      <div class="caption">Sobre Nós</div>
                      <v-icon>mdi-information</v-icon>
                    </v-tab>
                    <v-tab key="contactos_tab">
                      <div>Contactos</div>
                      <v-icon>mdi-phone</v-icon>
                    </v-tab>
                  </v-tabs>
                </v-col>
              </v-row>
              <v-row wrap justify="center" align="center">
                <v-col>
                  <v-tabs-items v-model="tab">
                    <v-tab-item key="services_tab">
                      <v-row wrap>
                        <v-col
                          cols="12"
                          md="4"
                          v-for="(item, index) in servicos"
                          :key="index"
                        >
                          <v-hover>
                            <v-card height="100%" elevation="0" outlined>
                              <v-img
                                src="https://www.questionpro.com/blog/wp-content/uploads/2018/04/customer-service-what-is-min.jpg"
                                aspect-ratio="2.75"
                                height="125px"
                              ></v-img>

                              <v-card-title>
                                <h5 class="mb-0">{{ item.nome }}</h5>
                              </v-card-title>
                              <v-card-text class="pb-0" v-html="item.descricao">
                              </v-card-text>
                            </v-card>
                          </v-hover>
                        </v-col>
                      </v-row>
                    </v-tab-item>
                    <v-tab-item key="sobreNos_tab">
                      <v-row>
                        <v-col cols="12" md="12">
                          <v-card max-width="100%" elevation="0" outlined>
                            <v-card-title> Sobre Empresa </v-card-title>

                            <v-card-text>
                              <p v-html="empresa.sobre_nos"></p>
                            </v-card-text>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-tab-item>
                    <v-tab-item key="contactos_tab">
                      <v-row>
                        <v-col cols="12" md="12">
                          <v-card max-width="100%" elevation="0" outlined>
                            <v-list two-line>
                              <v-list-item>
                                <v-list-item-icon>
                                  <v-icon color="#FF5252"> mdi-phone </v-icon>
                                </v-list-item-icon>

                                <v-list-item-content>
                                  <v-list-item-title
                                    v-for="(item, index) in telefones"
                                    :key="index"
                                  >
                                    (+258) {{ item.telefone }}
                                  </v-list-item-title>
                                  <v-list-item-subtitle>
                                    Telefone
                                  </v-list-item-subtitle>
                                </v-list-item-content>
                              </v-list-item>

                              <v-divider inset></v-divider>

                              <v-list-item>
                                <v-list-item-icon>
                                  <v-icon color="#FF5252"> mdi-email </v-icon>
                                </v-list-item-icon>

                                <v-list-item-content>
                                  <v-list-item-title>
                                    {{ empresa.email_cotacao }}
                                  </v-list-item-title>
                                  <v-list-item-subtitle>
                                    E-mail Cotação
                                  </v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-icon>
                                  <v-icon color="#FF5252"
                                    >mdi-message-text</v-icon
                                  >
                                </v-list-item-icon>
                              </v-list-item>

                              <v-divider inset></v-divider>

                              <v-list-item>
                                <v-list-item-icon>
                                  <v-icon color="#FF5252">
                                    mdi-map-marker
                                  </v-icon>
                                </v-list-item-icon>

                                <v-list-item-content>
                                  <v-list-item-title>
                                    {{
                                      empresa.district
                                        ? empresa.district.province.name
                                        : ""
                                    }}, {{ empresa.localizacao }}
                                  </v-list-item-title>
                                  <v-list-item-subtitle>
                                    Localização
                                  </v-list-item-subtitle>
                                </v-list-item-content>
                              </v-list-item>
                            </v-list>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-tab-item>
                  </v-tabs-items>
                </v-col>
              </v-row>
            </v-container>
          </v-col>
        </v-row>
      </v-container>
    </v-row>
    <v-row>
      <v-col cols="2" class="">
        <v-btn
          v-if="empresa.id"
          :to="{ name: 'admin.empresas.edit', params: { id: empresa.id } }"
          tile
          small
          elevation="4"
          dark
          color="red accent-3"
          ><v-icon small class="mr-2">mdi-pencil-outline</v-icon> Editar</v-btn
        >
      </v-col>
      <!-- <v-col v-if="toAprove" cols="3">
        <v-btn
          class="ml-0"
          tile
          elevation="4"
          dark
          color="red acent-3"
          small
          @click="aproveEmpresa(empresa.id)"
        >
          Aprovar empresa
        </v-btn>
      </v-col> -->
      <v-col v-if="toAprove" cols="3">
        <template>
            <div class="text-center">
              <v-dialog v-model="dialog" width="500">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="ml-0"
                    tile
                    elevation="4"
                    dark
                    color="red acent-3"
                    small
                    v-bind="attrs"
                    v-on="on"
                  >
                    Aprovar empresa *
                  </v-btn>
                </template>

                <v-card>
                  <v-card-title class="text-h5 red acent-3">
                    Aprovar empresa
                  </v-card-title>
                    <v-card-text>
                        <v-checkbox
                        v-model="aprove"
                        readonly
                        color="primary"
                        label="Aprovar empresa"
                    >
                    </v-checkbox>
                    <v-select
                        :items="months"
                        v-model="subscribeMonths"
                        label="Selecione os meses da subscrição"
                    ></v-select>
                  </v-card-text>
                  <v-divider></v-divider>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="aproveEmpresa(empresa.id)">
                      Aprovar empresa
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </div>
        </template>
      </v-col>
      <v-col v-else cols="3">
        <v-btn
          class="ml-0"
          tile
          elevation="4"
          dark
          color="red acent-3"
          small
          @click="disaproveEmpresa(empresa.id)"
        >
          Retornar a aguarda pagamento
        </v-btn>
      </v-col>
      <v-col cols="3">
        <v-btn
          class="ml-0"
          tile
          elevation="4"
          dark
          color="red acent-3"
          small
          @click="removeEmpresa(empresa.id)"
        >
          Eliminar empresa
        </v-btn>
      </v-col>
    </v-row>
    <!-- <v-row wrap>
            <v-col cols="12" md="4" v-for="(item, index) in empresa.services" :key="index">
                <v-card class="mx-auto" max-width="344" >
                <v-card-title> {{ item.nome}} </v-card-title>
                <v-card-subtitle>
                    Descrição do serviço
                </v-card-subtitle>
                <v-expand-transition>
                    <div v-if="item.descricao">
                    <v-divider></v-divider>
                    <v-card-text>
                        {{ item.descricao }}
                    </v-card-text>
                    </div>
                </v-expand-transition>
                </v-card>
            </v-col>
        </v-row>
        <v-row wrap align="center" justify="center" v-if="empresa.sobre_nos">
            <v-card-title>
                <h4 class="heading-4 font-weight-bold accent--text">SOBRE A EMPRESA</h4>
            </v-card-title>
            <v-col cols="12" md="10">
                <v-card-text>
                    {{ empresa.sobre_nos }}
                </v-card-text>
            </v-col>
        </v-row> -->
  </div>
</template>

<script>
import Swal from "sweetalert2";
export default {
  props: {
    id: {
      required: true,
    },
    toAprove: {
      required: true,
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      servicos: "",
      tab: null,
      sobre_nos: false,
      services: false,
      telefones: "",
      model: "tab-2",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
      show: true,
      aprove:true,
      dialog: false,
      months: [1, 3, 12],
      subscribeMonths: null,
      empresa: {
        id: "",
        image: "",
        nome: "",
        industria: "",
        email: "",
        telefone: "",
        provincia: "",
        distrito: "",
        localizacao: "",
        servicos: "",
        sobre_nos: "",
        email_cotacao: "",
      },
    };
  },
  created() {
    this.tabs = 0;
    this.getEmpresa(this.id);
  },
  methods: {
    async getEmpresa(id) {
      this.empresa = await (
        await this.$store.dispatch("empresas/getEmpresa", id)
      ).data;
    },
    aproveEmpresa(id) {
      this.$store
        .dispatch("empresas/aproveEmpresa", { id: id, estado_id: 2, subscribe_month: this.subscribeMonths })
        .then(() => {
          Swal.fire({
            title: "Sucesso!",
            text: "empresa aprovada com sucesso!",
            icon: "success",
            confirmButtonText: "Ok",
            confirmButtonColor: "#FF1744",
          }).then(() => {
            this.getEmpresa(id);
            this.toAprove = false;
          });
        });
    },
    async disaproveEmpresa(id) {
      try {
        await this.$store.dispatch("empresas/disaproveEmpresa", { id: id });
        await Swal.fire({
          title: "Sucesso!",
          text: "empresa retornada ao estado aguarda pagamento",
          icon: "success",
          confirmButtonText: "Ok",
          confirmButtonColor: "#FF1744",
        });
        this.getEmpresa(id);
        this.toAprove = true;
      } catch (error) {
        console.log(error);
      }
    },
    async removeEmpresa(id) {
      // deleteEmpresa
      try {
        await this.$store.dispatch("empresas/deleteEmpresa", { id });
        await Swal.fire({
          title: "Sucesso!",
          text: "empresa apagada com sucesso!",
          icon: "success",
          confirmButtonText: "Ok",
          confirmButtonColor: "#FF1744",
        });
        this.$router.push({
          name: this.toAprove ? "admin.empresas.not-aproved" : "admin.empresas",
        });
        this.toAprove = false;
      } catch (error) {
        console.log(error);
      }
    },
  },
  watch: {
    // empresa(emp) {
    //     this.servicos = JSON.parse(emp.servicos)
    // }
    empresa(empresa) {
      this.telefones = JSON.parse(empresa.telefone);
      if (this.empresa.services.length > 0) {
        this.servicos = this.empresa.services;
      } else {
        this.servicos = JSON.parse(empresa.servicos);
      }
    },
  },
};
</script>

<style></style>
