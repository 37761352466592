<template>
    <v-row justify="center">
        <v-col cols="12">
            <v-row wrap class="mb-5">
				<v-col cols="12" md="10">
					<p class="text-subtitle-1 text-md-h5">{{ content.titulo }}</p>
					<v-divider class="my-3"></v-divider>
					<v-chip small  dark :color="'green darken-1'"
						>Instituição:
						{{ content.instituicao }}
						</v-chip
					>
					<v-chip small outlined dark :color="'green darken-1'"
						>Local: 
						<span v-if="content.local">
							{{ content.local }}
						</span>
						<span v-else>
							<span
								v-for="local in content.locals"
								:key="local.id"
								class="mr-1"
							>
								{{ local.name }}
							</span>
						</span>
					</v-chip>
					<v-chip small light outlined v-if="content.industria" :color="'green darken-1'">
						Categoria: {{content.industria}}

					</v-chip>
					<v-chip
						small
						outlined
						dark
						:color="expired ? 'red darken-1' : 'green darken-1'"
						>Validade: {{ content.validade | formatDate }}</v-chip
					>
					<v-chip small outlined dark :color="'green darken-1'"
						>Fonte:
						<span
							class="font-weight-thin font-italic text-caption text-disabled"
							>{{ content.fonte }}</span
						></v-chip
					>
					<v-divider class="my-3"></v-divider>
				</v-col>
			</v-row>
            <v-row wrap class="mb-5">
				<v-col>
					<v-img v-if="content.anexo_conteudo" >
						<img :src="content.anexo_conteudo" class="anexo" alt=""/>
					</v-img>
					<div v-else class="text-pre-wrap text-body-2 text-md-body-1" v-html="content.conteudo_formatado ? content.conteudo_formatado : content.conteudo"></div>
					<div v-if="active_candidatar" v-html="candidatura"></div>
					<div v-if="active_candidatar" v-html="dica"></div>
					<!--<div v-html="content.conteudo"></div>-->
				</v-col>
			</v-row>
        </v-col>
    </v-row>
</template>

<script>
export default {
    props: {
		content: {
			required: true,
			type: Object,
		},
	},
	data() {
		return {
			active_candidatar: false,
			candidatura: '',
			dica: ''
		}
	},

	computed: {
		expired() {
			let today = new Date();
			let deadlineDate = new Date(this.content.validade);

			return today > deadlineDate ? true : false;
		},
	},
	watch: {
		content(content) {
			//const endereco = '<h4>Processo de candidatra</h4><p>Endereço: </p>'
			//const email = '<h4>Processo de candidatra</h4><p>Envie um email para <a href="mailto:email@email.com" rel="noopener noreferrer" target="_blank">email@email.com</a></p>'
			//const link = '<h4>Processo de candidatra</h4><p>Através do website <a href="www.site.com" rel="noopener noreferrer" target="_blank">www.site.com</a></p>'
			if(content?.como_candidatar){
				this.active_candidatar = true
				console.log(content.como_candidatar)
				if(content.como_candidatar.method == "Entregar candidatura no endereço físico") {
					console.log(1)
					this.candidatura = `<h4>Processo de candidatra</h4><p><ul><li>Endereço: ${content.como_candidatar.desc}</li></ul></p>`
					if(content.como_candidatar.dica) {
						this.dica = `<h4>Dica</h4><p><ul><li>${content.como_candidatar.dica}</li></ul></p>`
					}
				}
				if(content.como_candidatar.method == "Aceder o link") {
					this.candidatura = `<h4>Processo de candidatra</h4><p><ul><li>Através do website <a href="${content.como_candidatar.desc}" rel="noopener noreferrer" target="_blank">${content.como_candidatar.desc}</a></li></ul></p>`
					if(content.como_candidatar.dica) {
						this.dica = `<h4>Dica</h4><p><ul><li>${content.como_candidatar.dica}</li></ul></p>`
					}
				}
				if(content.como_candidatar.method == "Enviar candidatura por email"){
					this.candidatura = `<h4>Processo de candidatra</h4><p><ul><li>Envie um email para <a href="mailto:${content.como_candidatar.desc}" rel="noopener noreferrer" target="_blank">${content.como_candidatar.desc}</a></li></ul></p>`
					if(content.como_candidatar.dica) {
						this.dica = `<h4>Dica</h4><p><ul><li>${content.como_candidatar.dica}</li></ul></p>`
					}
				}
				
			}
		}
	},
	filters: {
		formatDate: (date) => {
			if (date) {
				let newDate = new Date(date);
				let newFormatedDate =
					newDate.getDate() +
					"-" +
					(newDate.getMonth() + 1) +
					"-" +
					newDate.getFullYear();
				return newFormatedDate;
			}

			return "";
		},
	},
}
</script>

<style>
.anexo {
    width: 100%;
}
</style>