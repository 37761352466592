var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){return [_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('validation-provider',{attrs:{"name":"Titulo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Titulo/Nome da empresa","prepend-icon":"mdi-image-text","placeholder":"Empresa ou nome sugestivo para os banners","error-messages":errors},model:{value:(_vm.publicity.titulo),callback:function ($$v) {_vm.$set(_vm.publicity, "titulo", $$v)},expression:"publicity.titulo"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Url","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"prepend-icon":"mdi-link-variant-plus","label":"Url","placeholder":"Link para onde os banners devem apontar","error-messages":errors},model:{value:(_vm.publicity.url),callback:function ($$v) {_vm.$set(_vm.publicity, "url", $$v)},expression:"publicity.url"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Imagem web","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var error = ref.error;
return [_c('v-file-input',{attrs:{"accept":"image/*","label":"Banner desktop","prepend-icon":"mdi-camera-image","placeholder":"imagem para apresentação em dispositivos de tela maior","error-messages":error},on:{"change":_vm.onFileWeb}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Imagem mobile","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var error = ref.error;
return [_c('v-file-input',{attrs:{"accept":"image/*","label":"Banner mobile","prepend-icon":"mdi-camera-image","placeholder":"imagem para apresentação em dispositivos de tela menor","error-messages":error},on:{"change":_vm.onFileMobile}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"É necessario indicar em que aplicações os banners aparecem","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.items,"error-messages":errors,"attach":"","chips":"","item-text":"name","item-value":"id","label":"Aplicações","prepend-icon":"mdi-code-tags-check","placeholder":"Em que aplicação a publicidade deve aparecer?","multiple":""},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})]}}],null,true)}),_c('v-btn',{attrs:{"type":"submit","small":"","elevation":"4","color":"red accent-3","dark":""}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v(" mdi-check ")]),_vm._v(" Adicionar publicidade ")],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }