<template>
    <v-card>
        <v-card-actions>
            <v-btn 
                small
                elevation="4"
                dark
                color="red acent-3"
                tile
                @click="notifyEmpresa">
                Notificar empresas
            </v-btn>
        </v-card-actions>
        <v-row>
            <v-dialog
                v-model="eempresaDialog"
                persistent
                width="800px"
            >
                <empresa-notification :empresas_id="selected" @closeDialog="()=>{eempresaDialog=false}"/>
            </v-dialog>
        </v-row>
        <v-card-title>
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
            ></v-text-field>
        </v-card-title>
        <v-data-table
        v-model="selected"
        :headers="headers"
        :items="empresas"
        :search="search"
        show-select
        @click:row="viewEmpresaDetails"
        ></v-data-table>
        <json-excel
        :data="empresas"
        :name="'empresas' + Math.floor(Math.random() * 100) + '.xls'">
            <v-btn small color="red accent-3" dark>
                <v-icon>
                    mdi-download
                </v-icon>
                Export Excel
            </v-btn>
        </json-excel>
    </v-card>

</template>

<script>
import empresaNotification from './partials/empresasNotification.vue'
import JsonExcel from 'vue-json-excel'
import Swal from "sweetalert2";
export default {
    components: {JsonExcel, empresaNotification},
    props: {
        empresas: {
            required: true,
            type: Array
        },
        toAprove: {
            required: true,
			type: Boolean,
			default: false,
        }
    },
    data() {
        return {
            search: '',
            selected: [],
            eempresaDialog: false,
            headers: [
                {
                    text: 'id',
                    align: 'start',
                    filterable: false,
                    value: 'id',
                },
                { text: 'nome',value: 'nome'},
                { text: 'industria', value: 'industria' },
                { text: 'localização', value: 'localizacao'},
                { text: 'proprietario', value: 'recruiter_name' },
                { text: 'data de expiração', value: 'expire_at' },
                { text: 'data de aprovação', value: 'aproved_at' },
            ],
        }
    },
    methods: {
        viewEmpresaDetails(value) {
            this.$router.push({name: 'admin.empresas.view', params: {id: value.id, toAprove: this.toAprove}})
        },
        notifyEmpresa () {
            if (this.selected.length !== 0) {
                this.eempresaDialog = true
            } else {
                Swal.fire({
					title: "Aviso!",
					text: "Por favor selecione a(s) empresa(s) para poder enviar uma notificação.",
					icon: "warning",
					confirmButtonText: "Ok",
					confirmButtonColor: "#FF1744",
				})
            }
        }
    }
}
</script>

<style>

</style>