<template>
        <v-card>
        <v-card-title>
            <v-row>
                <v-col cols="4" style="margin-top: 30px;">
                    <v-menu
                        ref="menu"
                        v-model="menu"
                        :close-on-content-click="false"
                        :return-value.sync="date"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                dense
                                v-model="dateFilter"
                                label="Selecione a Data"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                            ></v-text-field>
                        </template>
                        <v-date-picker
                            dense
                            v-model="dateFilter"
                            no-title
                            scrollable
                        >
                            <v-spacer></v-spacer>
                            <v-btn text color="primary" @click="menu = false">
                                Cancel
                            </v-btn>
                            <v-btn text color="primary" @click="$refs.menu.save(date)">
                                OK
                            </v-btn>
                        </v-date-picker>
                    </v-menu> 
                </v-col>
                <v-col cols="3" style="margin-top: 30px;">
                    <v-btn small color="red accent-3" dark @click="emitGetConcurso()">Filtrar</v-btn>
                </v-col>
            </v-row>
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
            ></v-text-field>
        </v-card-title>
        <v-data-table
        :headers="headers"
        :items="content"
        :search="search"
        @click:row="viewEmpresaDetails"
        >
        </v-data-table>
        <json-excel
        :data="content"
        :name="'viewsConcursos' + Math.floor(Math.random() * 100) + '.xls'">
            <v-btn small color="red accent-3" dark>
                <v-icon>
                    mdi-download
                </v-icon>
                Export Excel
            </v-btn>
        </json-excel>
    </v-card>
</template>

<script>
import JsonExcel from 'vue-json-excel'
export default {
    components: {JsonExcel},
    props: {
        content: {
            required: true,
            type: Array
        },
    },
    data() {
        return {
            search: '',
            headers: [
                {
                    text: 'ID concurso',
                    align: 'start',
                    filterable: false,
                    value: 'content_id',
                },
                { text: 'nome',value: 'name'},
                { text: 'email/contacto', value: 'email' },
                { text: 'subscrição', value: 'subscribe'},
                { text: 'Tipo de concurso', value: 'content_status' },
                { text: 'data de visualização', value: 'viewed_at'},
                { text: 'estado', value: 'status' }
            ],
            json_fields: {
                'id do concurso': 'content_id',
                nome: 'name',
                email: 'email',
                'subscrição': 'subscribe',
                'tipo do concurso': 'content_status',
                'data de visualização': 'viewed_at'
            },
            date: new Date().toISOString().substr(0, 10),
			menu: false,
            dateFilter: ''
        }
    },
    methods: {
        viewEmpresaDetails(value) {
            this.$router.push({name: 'admin.concursos.show', params: {id: value.content_id}})
        },
        emitGetConcurso() {
            console.log(this.dateFilter);
            this.$emit('emitGetConcurso', this.dateFilter)
        }
    }
}
</script>

<style>

</style>