var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-btn',{attrs:{"small":"","elevation":"4","dark":"","color":"red acent-3","tile":""},on:{"click":_vm.deleteWarning}},[_vm._v(" Apagar marcados ")])],1)],1),_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.filterData,"search":_vm.search,"show-select":""},on:{"click:row":_vm.viewContentDetails},scopedSlots:_vm._u([_vm._l((_vm.filters),function(col,index){return {key:("header." + index),fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),_c('v-menu',{key:index,staticStyle:{"position":"absolute","right":"0"},attrs:{"offset-y":"","transition":"slide-y-transition","close-on-content-click":false,"fixed":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"indigo","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":"","color":_vm.filters[header.value].length ? 'red' : ''}},[_vm._v(" mdi-filter-variant ")])],1)]}}],null,true)},[_c('div',{staticStyle:{"background-color":"white","width":"280px"}},[_c('v-list',{staticClass:"pa-0",attrs:{"flat":"","dense":""}},[_c('v-list-item',[(_vm.filters.hasOwnProperty(header.value))?_c('div',[_c('v-autocomplete',{attrs:{"multiple":"","dense":"","auto-select-first":"","clearable":"","chips":"","small-chips":"","color":"light-blue lighten-3","items":_vm.columnValueList(header.value),"append-icon":"mdi-filter","label":_vm.filters[header.value] ? ("filter_by: " + (header.text)) : '',"hide-details":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index < 5)?_c('v-chip',{staticClass:"caption",attrs:{"small":""}},[_c('span',[_vm._v(" "+_vm._s(item)+" ")])]):_vm._e(),(index === 5)?_c('span',{staticClass:"grey--text caption"},[_vm._v(" (+"+_vm._s(_vm.filters[header.value].length - 5)+" others) ")]):_vm._e()]}}],null,true),model:{value:(_vm.filters[header.value]),callback:function ($$v) {_vm.$set(_vm.filters, header.value, $$v)},expression:"filters[header.value]"}})],1):_vm._e()])],1)],1)])]}}})],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),_c('json-excel',{attrs:{"data":_vm.filterData,"name":'contents' + Math.floor(Math.random() * 100) + '.xls'}},[_c('v-btn',{attrs:{"small":"","color":"red accent-3","dark":""}},[_c('v-icon',[_vm._v(" mdi-download ")]),_vm._v(" Export Excel ")],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }