/* eslint-disable vue/require-v-for-key */
<template>
    <v-card>
        <v-card-title>
            <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>
        </v-card-title>
        <v-data-table :headers="headers" :items="user" :search="search"></v-data-table>
    </v-card>
</template>

<script>
// import Swal from "sweetalert2";
// import { BASE_URL } from '../../../services/config'
// import axios from 'axios'


export default {
    components: {
    },
    data() {
        return {
            search: '',
            headers: [
                {
                    text: 'id',
                    align: 'start',
                    sortable: false,
                    value: 'id',
                },
                { text: "Nome", value: "name" },
                { text: "Email", value: "email" },
                { text: "Contacto", value: "contacto" },
                { text: 'Data do Registo', value: 'created_at' },
                { text: 'Data de Subscrição', value: 'subscribe' },
                { text: 'Convidado', value: 'convidado' },
                { text: 'Ativo', value: 'active' },
            ],
            // user: []

        }
    },
    computed: {
        user() {
            return this.$store.state.recruiters.lastusers
        }
    },
    created() {
        this.getEmpresasUsers()
    },
    methods: {
        getEmpresasUsers() {
            this.$store.dispatch("recruiters/getLast20Users")
        }
    },
    watch: {

    }
}
</script>

<style></style>