/* eslint-disable no-unused-vars */
import axios from "axios"
import { BASE_URL } from '../../../services/config'

const RESOURCE = '/admin/empresa'
const RECRUITERS = '/recruiters'

const actions = {
    getEmpresas({commit}, params) {
        const config = {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('token')}`,
            }
        }
        commit('loading/CHANGE_LOADING', true, { root: true })
        return new Promise((resolve, reject) => {
            axios.get(`${BASE_URL}${RESOURCE}`, config)
                .then(res => {
                    commit('GET_EMPRESAS', res.data)
                    console.log(res.data);
                    resolve(res)
                })
                .catch(err => reject(err))
                .finally(() => commit('loading/CHANGE_LOADING', false, { root: true }))
        })
        
    },
    getEmpresasNotAproved({commit}, params) {
        const config = {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('token')}`,
            }
        }
        commit('loading/CHANGE_LOADING', true, { root: true })
        return new Promise((resolve, reject) => {
            axios.get(`${BASE_URL}${RESOURCE}/not-aproved`, config)
                .then(res => {
                    commit('GET_EMPRESAS', res.data)
                    console.log(res.data);
                    resolve(res)
                })
                .catch(err => reject(err))
                .finally(() => commit('loading/CHANGE_LOADING', false, { root: true }))
        })
    },
    getEmpresa({commit}, id) {
        const config = {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('token')}`,
            }
        }
        commit('loading/CHANGE_LOADING', true, { root: true })
        return new Promise((resolve, reject) => {
            axios.get(`${BASE_URL}${RESOURCE}/${id}`, config)
                .then(res => {
                    resolve(res.data)
                })
                .catch(err => reject(err))
                .finally(() => commit('loading/CHANGE_LOADING', false, { root: true }))
        })
    },
    storeEmpresa({commit}, params) {
        commit('loading/CHANGE_LOADING', true, { root: true })
        const config = {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('token')}`,
            }
        }
        return new Promise((resolve, reject) => {
            axios.post(`${BASE_URL}${RESOURCE}/create-empresa`, params, config)
            .then(res => {
                resolve(res)
            })
            .catch(err => {
                reject(err)
            })
            .finally(() => {commit('loading/CHANGE_LOADING', false, { root: true })})
        })
    },
    updateEmpresa({commit}, params) {
        commit('loading/CHANGE_LOADING', true, { root: true })
        const config = {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('token')}`,
            }
        }
        return new Promise((resolve, reject) => {
            axios.post(`${BASE_URL}${RESOURCE}/${params.get('id')}`, params, config)
                .then(res => resolve(res))
                .catch(err => reject(err))
                .finally(() => commit('loading/CHANGE_LOADING', false, { root: true }))
        })
    },
    getProvinces({commit}) {
        commit('loading/CHANGE_LOADING', true, { root: true })
        const config = {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('token')}`,
            }
        }
        return new Promise((resolve, reject) => {
            axios.get(`${BASE_URL}/admin/provinces`, config)
                .then(res => {
                    commit('GET_PROVINCES', res.data)
                    resolve(res)
                    console.log(res)
                })
                .catch(err => reject(err))
                .finally(() => commit('loading/CHANGE_LOADING', false, { root: true }))
        })
    },
    getDistricts({commit}, params) {
        commit('loading/CHANGE_LOADING', true, { root: true })
        const config = {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('token')}`,
            }
        }
        return new Promise((resolve, reject) => {
            axios.get(`${BASE_URL}/admin/provinces/${params.id}`, config)
                .then(res => resolve(res))
                .catch(err => reject(err))
                .finally(() => commit('loading/CHANGE_LOADING', false, { root: true }))
        })
    },
    verifyUser({commit}, params) {
        commit('loading/CHANGE_LOADING', true, { root: true })
        const config = {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('token')}`,
            }
        }
        return new Promise((resolve, reject) => {
            axios.post(`${BASE_URL}/admin/empresas-users/${params.id}`, params, config)
                .then(res => resolve(res))
                .catch(err => reject(err))
                .finally(() => commit('loading/CHANGE_LOADING', false, { root: true }))
        })
    },
    getPlans({commit}) {
        commit('loading/CHANGE_LOADING', true, { root: true })
        const config = {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('token')}`,
            }
        }
        return new Promise((resolve, reject) => {
            axios.get(`${BASE_URL}/admin/planos`, config)
            .then(res => resolve(res))
            .catch(err => reject(err))
            .finally(() => commit('loading/CHANGE_LOADING', false, { root: true }))
        })
    },
    updateSubscription({commit}, params) {
        const config = {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('token')}`,
            }
        }
        return new Promise((resolve, reject) => {
            axios.post(`${BASE_URL}/admin/update_subscribe/${params.id}`, params, config)
                .then(res => resolve(res))
                .catch(err => reject(err))
            // resolve('ok')
        })
    },
    createPackage({commit}, params){
        commit('loading/CHANGE_LOADING', true, { root: true })
        const config = {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('token')}`,
            }
        }
        return new Promise((resolve, reject) => {
            axios.post(`${BASE_URL}/admin/packages`,params, config)
            .then(res => resolve(res))
            .catch(err => reject(err))
            .finally(() => commit('loading/CHANGE_LOADING', false, { root: true }))
        })
    },
    deletePackage({commit}, id) {
        commit('loading/CHANGE_LOADING', true, { root: true })
        const config = {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('token')}`,
            }
        }
        return new Promise((resolve, reject) => {
            axios.delete(`${BASE_URL}/admin/packages/${id}`, config)
            .then(res => resolve(res))
            .catch(err => reject(err))
            .finally(() => commit('loading/CHANGE_LOADING', false, { root: true }))
        })
    },
    updatePackage({commit}, params){
        commit('loading/CHANGE_LOADING', true, { root: true })
        const config = {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('token')}`,
            }
        }
        return new Promise((resolve, reject) => {
            axios.post(`${BASE_URL}/admin/packages/${params.id}`,params, config)
            .then(res => resolve(res))
            .catch(err => reject(err))
            .finally(() => commit('loading/CHANGE_LOADING', false, { root: true }))
        })
    },
    aproveEmpresa({commit}, params) {
        commit('loading/CHANGE_LOADING', true, { root: true })
        const config = {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('token')}`,
            }
        }
        return new Promise((resolve, reject) => {
            axios.post(`${BASE_URL}${RESOURCE}/aprove-empresa/${params.id}`, params, config)
                .then(res => resolve(res))
                .catch(err => reject(err))
                .finally(() => commit('loading/CHANGE_LOADING', false, { root: true }))
        })
    },
    disaproveEmpresa({commit}, params) {
        commit('loading/CHANGE_LOADING', true, { root: true })
        const config = {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('token')}`,
            }
        }
        return new Promise((resolve, reject) => {
            axios.get(`${BASE_URL}${RESOURCE}/disapprove-empresa/${params.id}`, config)
                .then(res => resolve(res))
                .catch(err => reject(err))
                .finally(() => commit('loading/CHANGE_LOADING', false, { root: true }))
        })
    },
    deleteEmpresa({commit}, params) {
        commit('loading/CHANGE_LOADING', true, { root: true })
        const config = {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('token')}`,
            }
        }
        return new Promise((resolve, reject) => {
            axios.delete(`${BASE_URL}${RESOURCE}/${params.id}`, config)
                .then(res => resolve(res))
                .catch(err => reject(err))
                .finally(() => commit('loading/CHANGE_LOADING', false, { root: true }))
        })
    },
    createCareerPage({commit}, params) {
        commit('loading/CHANGE_LOADING', true, { root: true })
        const config = {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('token')}`,
            }
        }
        return new Promise((resolve, reject) => {
            axios.post(`${BASE_URL}/admin/careers/create`, params, config)
                .then(res => resolve(res))
                .catch(err => reject(err))
                .finally(() => commit('loading/CHANGE_LOADING', false, { root: true }))
        })
    },
    updateCareerPage({commit}, params){
        commit('loading/CHANGE_LOADING', true, { root: true })
        const config = {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('token')}`,
            }
        }
        return new Promise((resolve, reject) => {
            axios.post(`${BASE_URL}/admin/careers/${params.get('id')}`, params, config)
                .then(res => resolve(res))
                .catch(err => reject(err))
                .finally(() => commit('loading/CHANGE_LOADING', false, { root: true }))
        })
    },
    deleteCareerPage({commit}, params) {
        commit('loading/CHANGE_LOADING', true, { root: true })
        const config = {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('token')}`,
            }
        }
        return new Promise((resolve, reject) => {
            axios.delete(`${BASE_URL}/admin/careers/${params.id}`, config)
                .then(res => resolve(res))
                .catch(err => reject(err))
                .finally(() => commit('loading/CHANGE_LOADING', false, { root: true }))
        })
    }
}

export default actions