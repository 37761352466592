<template>
    <v-container>
        <v-row wrap align="center" justify="space-between">
            <v-col cols="12" md="6">
				<v-btn
					router
					small
					elevation="4"
                    :to="{name: 'admin.empresas.create'}"
					dark
					color="red acent-3"
                    tile
				>
					Criar empresa
					<v-icon small class="mr-2">mdi-plus</v-icon>
			
				</v-btn>
            </v-col>
        </v-row>
        <v-row wrap justify="space-between">
            <v-col cols="12">
                <empresa-table :empresas="empresas" :toAprove="false"/>
            </v-col>
            <!-- <v-col
				sm="12"
				md="6"
				v-for="empresa in empresas.data"
				:content="empresa"
				:key="empresa.id"
				class="mb-3"
			>
                <empresa-card :empresa="empresa" :toAprove="false"/>
            </v-col> -->
        </v-row>
        <!-- <v-row>
			<v-col xs="12">
				<v-pagination
					v-model="empresas.current_page"
					:length="empresas.last_page"
					@input="handleChangePage"
					color="error"
					total-visible="7"
				></v-pagination>
			</v-col>
		</v-row> -->
    </v-container>
</template>

<script>
import empresaCard from './empresaCardComponent.vue'
import empresaTable from './empresaTableComponent.vue'
export default {
    // eslint-disable-next-line vue/no-unused-components
    components: {empresaCard, empresaTable},
    data() {
        return {
        }
    },
    created() {
        this.getEmpresas()
    },
    computed: {
        empresas() {
            return this.$store.state.empresas.items
        }
    },
    methods: {
        getEmpresas() {
            this.$store.dispatch("empresas/getEmpresas",{
                page: 1
            })
        },
        handleChangePage(page){
            this.getEmpresas(page)
        }
    }
}
</script>

<style>

</style>