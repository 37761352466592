/* eslint-disable no-unreachable */
/* eslint-disable vue/require-v-for-key */
<template>
    <v-card>
        <v-card-actions>
            <v-btn 
                small
                elevation="4"
                dark
                color="red acent-3"
                tile
                @click="()=>{dialogNewslleterNotification = true}">
                Enviar newsletter
            </v-btn>
            <v-btn 
            small
            elevation="4"
            dark
            color="red acent-3"
            tile
            @click="notifyUsers">
                Notificar usuarios
            </v-btn>
            <v-btn 
                small
                elevation="4"
                dark
                color="red acent-3"
                tile
                @click="()=>{dialogNewsletter = true}">
                Gerar newsletter
            </v-btn>
        </v-card-actions>
        <!-- newsletter to send on whatsapp, facebook or linkedIn -->
        <v-row>
            <v-dialog 
                v-model="dialogNewsletter"
                persistent
                width="800px"
            >
                <v-card max-height="1000px">
                    <v-card-title>
                        <span class="text-h5">Newsletter</span>
                    </v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col cols="6">
                               <v-menu
                                    ref="menu"
                                    v-model="menu2"
                                    :close-on-content-click="false"
                                    :return-value.sync="date"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            dense
                                            v-model="dateFilter2"
                                            label="Selecione a Data"
                                            prepend-icon="mdi-calendar"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        dense
                                        v-model="dateFilter2"
                                        no-title
                                        scrollable
                                    >
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="menu2 = false">
                                            Cancel
                                        </v-btn>
                                        <v-btn text color="primary" @click="$refs.menu.save(date)">
                                            OK
                                        </v-btn>
                                    </v-date-picker>
                                </v-menu> 
                            </v-col>
                            <v-col cols="6">
                                <v-btn
                                    exact
                                    small
                                    elevation="4"
                                    color="red accent-3"
                                    dark
                                    class="mx-4"
                                    @click="getEmpregosCopy"
                                >
                                    Gerar newsletter
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="10">
                                <v-textarea
                                    dense
                                    clear-icon="mdi-close-circle"
                                    placeholder="newsletter para ser enviado pelo whatsapp, facebook ou linkedIn."
                                    v-model="newslleterCopy"
                                    value="This is clearable text."
                                    style="padding:10px; max-height:400px;"
                                ></v-textarea>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn
                            exact
                            small
                            elevation="4"
                            color="red accent-3"
                            dark
                            class="mx-4"
                            @click="closeDialogNewsletter"
                        >
                            Fechar
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
        <!-- Newsletter to send to users on the app -->
        <v-row>
            <validation-observer ref="observer" v-slot="{  }">
                <v-dialog
                v-model="dialogNewslleterNotification"
                persistent
                width="800px"
                >
                    <notification-form :users_id="users" :notificationType="1" @closeDialog="()=>{dialogNewslleterNotification=false}"/>
                </v-dialog>
            </validation-observer>
        </v-row>
        <v-row>
            <validation-observer ref="observer" v-slot="{  }">
                <v-dialog
                v-model="dialogEmail"
                persistent
                width="800px"
                >
                    <notification-form :notificationType="2" v-bind:users_id.sync="selected" @closeDialog="()=>{dialogEmail=false}"/>
                </v-dialog>
            </validation-observer>
        </v-row>
        <v-card-title>
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
            ></v-text-field>
        </v-card-title>
        <v-data-table
        v-model="selected"
        :headers="headers"
        :items="filterUsers"
        :search="search"
        class="elevation-1"
        show-select
        >
            <template v-for="(col, index) in filters" v-slot:[`header.${index}`]="{ header }">
                {{header.text}}
                <v-menu :key="index" offset-y transition="slide-y-transition" :close-on-content-click="false" fixed style="position: absolute; right: 0">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn color="indigo" icon v-bind="attrs" v-on="on">
                            <v-icon small :color="filters[header.value].length ? 'red' : ''">
                                mdi-filter-variant
                            </v-icon>
                        </v-btn>
                    </template>
                    <div style="background-color: white; width: 280px">
                        <v-list flat dense class="pa-0">
                        <v-list-item>
                            <div v-if="filters.hasOwnProperty(header.value)">
                            <v-autocomplete multiple dense auto-select-first clearable chips small-chips color="light-blue lighten-3" :items="columnValueList(header.value)" append-icon="mdi-filter" v-model="filters[header.value]" :label="
                            filters[header.value] ? `filter_by: ${header.text}` : ''
                            " hide-details>
                                <template v-slot:selection="{ item, index }">
                                <v-chip small class="caption" v-if="index < 5">
                                    <span>
                                    {{ item }}
                                    </span>
                                </v-chip>
                                <span v-if="index === 5" class="grey--text caption">
                                    (+{{ filters[header.value].length - 5 }} others)
                                </span>
                                </template>
                            </v-autocomplete>
                            </div>
                        </v-list-item>
                        </v-list>
                    </div>
                </v-menu>
            </template>
            <template v-slot:top>
                <v-toolbar flat>
                    <v-dialog v-model="dialogDelete" max-width="500px">
                        <v-card>
                            <v-card-title>Tem certeza de que deseja excluir este utilizador?</v-card-title>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="blue darken-1" text @click="closeDelete">Cancelar</v-btn>
                                <v-btn class="ma-1"
                                color="error"
                                text @click="deleteUser">Apagar</v-btn>
                                <v-spacer></v-spacer>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-toolbar>
            </template>
            <template v-slot:item.actions="{ item }">
                <v-icon
                    @click="deleteItem(item)"
                >
                    mdi-delete-forever
                </v-icon>
            </template>
        </v-data-table>
    </v-card>

</template>

<script>
/* eslint-disable no-unreachable */
import {
	// ValidationProvider,
	ValidationObserver,
	setInteractionMode,
	extend,
} from "vee-validate";
import { required, digits, email, max, regex } from "vee-validate/dist/rules";
// import { VueEditor  } from 'vue2-editor';
import Swal from "sweetalert2";
import notificationForm from './partials/notificationForm.vue'
import {BASE_URL} from '../../../../services/config'
import axios from 'axios'

setInteractionMode("eager");

extend("digits", {
	...digits,
	message: "{_field_} needs to be {length} digits. ({_value_})",
});

extend("required", {
	...required,
	message: "{_field_} não pode estar vazio",
});

extend("max", {
	...max,
	message: "{_field_} may not be greater than {length} characters",
});

extend("regex", {
	...regex,
	message: "{_field_} {_value_} does not match {regex}",
});

extend("email", {
	...email,
	message: "Email must be valid",
});
extend("url", {
	validate(value) {
		var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
		'((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
		'((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
		'(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
		'(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
		'(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
		return pattern.test(value);
	}, message: 'Introduz um link valido.'
});

export default {
    components: {
        // ValidationProvider,
		ValidationObserver,
        // VueEditor,
        notificationForm},
        
    props: {
        users: {
            required: true,
            type: Array
        }
    },
    data() {
        return {
            dialogEmail: false,
            dialogNewsletter: false,
            dialogNewslleterNotification: false,
            dialogDelete: false,
            emailSubject: "",
            emailBody: "",
            search: '',
            menu: false,
            id: null,
            dateFilter: '',
            menu2: false,
            dateFilter2: '',
            newslleterCopy: '',
            date: new Date().toISOString().substr(0, 10),
            selected: [],
            emailsTets: 'asdsdas,basdbsdb,asdsads \n\n ok ok',
            objectEmails: {},
            emails: [],
            emailsStr: "",
            filters: {active: [], newsletter: [],education: [], profissionalExperience: [], language: [], anexos: []},
            headers: [
                {
                    text: 'id',
                    align: 'start',
                    sortable: false,
                    value: 'id',
                },
                { text: "Email", value: "email"},
                { text: 'Nome', value: 'name'},
                { text: "Ativo", value: 'active'},
                { text: 'newsletter', value: 'newsletter'},
                {text: 'Educação', value: 'education'},
                {text: 'Experiencia profissiona', value: 'profissionalExperience'},
                {text: 'Idiomas', value: 'language'},
                {text: 'anexos', value: 'anexos'},
                { text: 'Delete', value: 'actions', sortable: false }
            ],
            
			customToolbar: [
				[{header: [false, 1, 2, 3, 4, 5, 6]}],
				["bold", "italic", "underline"],
				[
					{ align: "" },
					{ align: "center" },
					{ align: "right" },
					{ align: "justify" }
				],
				[{list: 'bullet'}, {list: "ordered"}, { list: "check" }],
				[{color: []}],
				["link"]
			]
        }
    },
    computed: {
        filterUsers() {
            return this.users.filter((d) => {
                return Object.keys(this.filters).every((f) => {
                    return this.filters[f].length < 1 || this.filters[f].includes(d[f]);
                });
            });
        }
    },
    methods: {
        columnValueList(val) {
            return this.users.map((d) => d[val]);
        },
        exportUsers() {
            let limite = 50
            for(let i = 0; i < this.emails.length; i++) {
                if(i + 1 == limite) {
                    this.emailsStr += "\n\n"
                    limite += 50
                }
                this.emailsStr += `${this.emails[i]}, `
            }
            const currentdate = new Date();
            let blob = new Blob([this.emailsStr], {"typy": "text/plain"});
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            // eslint-disable-next-line no-unused-vars
            link.download = 'users-' + currentdate.getDay() + "/" + currentdate.getMonth() 
                            + "/" + currentdate.getFullYear() + "@" 
                            + currentdate.getHours() + ":" 
                            + currentdate.getMinutes() + ":" + currentdate.getSeconds() + ".txt";
            link.click();
        },
        getEmpregosCopy() {
            this.$store.dispatch('loading/showLoader')
            const config = {
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem('token')}`,
                }
            }
            axios.get(`${BASE_URL}/admin/contents/filterByDate/1/${this.dateFilter2}`, config)
                .then(res => {
                    let emailBodyGenerate = "Veja as vagas disponíveis hoje\n\n";
                    res.data.data.forEach(vaga => {
                        let link = `‣  ${vaga.titulo} - https://empregos.gugla.co.mz/contents/${vaga.id}/show \n`
                        emailBodyGenerate += link
                    })

                    emailBodyGenerate += `‣  E mais; \n\n Confira estas e outras novas vagas acessando https://empregos.gugla.co.mz`
                    this.newslleterCopy = emailBodyGenerate
                })
                .finally(() => {this.$store.dispatch('loading/closeLoader')})
        },
        closeDialogNewsletter() {
            this.newslleterCopy = ""
            this.dialogNewsletter = false
        },
        notifyUsers(){
            const id = this.selected.map(sel => {
                return sel.id
            })
            if(id.length !== 0){
                this.dialogEmail = true
            } else {
                // console.log("nenhum selecionado")
                Swal.fire({
					title: "Aviso!",
					text: "Por favor selecione os usuarios para poder enviar uma notificação.",
					icon: "warning",
					confirmButtonText: "Ok",
					confirmButtonColor: "#FF1744",
				})
            }
        },
        closeDelete () {
            this.dialogDelete = false
        },
        async deleteUser () {
        //    console.log(this.id);
           try {
                this.dialogDelete = false
                await this.$store.dispatch('empregos/destroyUser', {id: this.id})
                await Swal.fire({
                    title: "Sucesso!",
					text: "Usuario apagado.",
					icon: "success",
					confirmButtonText: "Ok",
					confirmButtonColor: "#FF1744",
                })
                this.$store.dispatch("empregos/getUsers")
           } catch (error) {
                console.log(error);
           }
        },
        deleteItem(item) {
            this.id = item.id
            this.dialogDelete = true 
        }
    },
    watch: {
        selected(sel) {
            this.emailsStr = ""
            this.emails = sel.map((user) => {
                //this.emailsStr += `${user.email},`
                return user.email
            })
            //console.log(this.emails)
        }
    }
}
</script>

<style>
</style>