<template>
  <v-data-table
    :headers="headers"
    :items="packages"
    sort-by="calories"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar
        flat
      >
        <v-toolbar-title>PACOTES DE SUBSCRIÇÃO</v-toolbar-title>
        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>
        <v-spacer></v-spacer>
        <v-dialog
          v-model="dialog"
          max-width="500px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="red accent-3"
              dark
              class="mb-2"
              v-bind="attrs"
              v-on="on"
            >
              Novo Pacote
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
									<v-col cols="12">
										<v-select
										:items="plans"
										item-text="name"
										item-value="id"
										label="Selecione o plano de subscrição"
										v-model="editedItem.subscription_plan_id"></v-select>
									</v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-text-field
                      v-model="editedItem.desc"
                      label="Descrição"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-text-field
                      v-model="editedItem.days"
                      label="Dias"
											type="number"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-text-field
                      v-model="editedItem.price"
                      label="Preço"
											type="number"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-checkbox v-model="editedItem.promo" label="Promoção"></v-checkbox>
                  </v-col>
                  
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="close"
              >
                Cancelar
              </v-btn>
              <v-btn
                color="red accent-3"
                text
                @click="save"
              >
                GUARDAR
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5">Deseja apagar este pacote?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete">Cancelar</v-btn>
              <v-btn color="blue darken-1" text @click="deleteItemConfirm">SIM</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon
        small
        class="mr-2"
        @click="editItem(item)"
      >
        mdi-pencil
      </v-icon>
      <v-icon
        small
        @click="deleteItem(item)"
      >
        mdi-delete
      </v-icon>
    </template>
    <!-- <template v-slot:no-data>
      <v-btn
        color="primary"
        @click="initialize"
      >
        Reset
      </v-btn>
    </template> -->
  </v-data-table>
</template>

<script>
    import Swal from 'sweetalert2'
  export default {
    data: () => ({
      dialog: false,
      dialogDelete: false,
      packages: [],
			plans: [],
			plan_id: null,
      headers: [
        {
          text: 'ID',
          align: 'start',
          sortable: false,
          value: 'id',
        },
        { text: 'Plano', value: 'subscription_plan' },
        { text: 'Pacote', value: 'desc' },
        { text: 'Dias', value: 'days' },
        { text: 'Preço', value: 'price' },
        { text: 'Promoção', value: 'promo' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      desserts: [],
      editedIndex: -1,
      editedItem: {
        id: '',
        desc: '',
        price: null,
        days: null,
        subscription_plan_id: null,
        promo: false
      },
      defaultItem: {
        id: '',
        desc: '',
        price: null,
        days: null,
        subscription_plan_id: null,
        promo: false,
      },
    }),

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'Adicionar pacote' : 'Editar pacote'
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },

    created () {
      // this.initialize()
      this.getPackages()
    },

    methods: {
			getPackages(){
				this.$store.dispatch("empresas/getPlans")
        .then(res => {
					this.plans = res.data.data.plans
          this.packages = res.data.data.package
          console.log(this.plans);
        })
			},

      editItem (item) {
        this.editedIndex = this.packages.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      deleteItem (item) {
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      deleteItemConfirm () {
        this.$store.dispatch("empresas/deletePackage", this.editedItem.id)
					.then(() => {
						Swal.fire({
								title: "Sucesso!",
								text: "Pacote adicionado com sucesso",
								icon: "success",
								confirmButtonText: "Ok",
								confirmButtonColor: "#FF1744",
							}).then(() => {
								this.getPackages()
								this.closeDelete()
							})
					})
        
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      save () {
        if (this.editedIndex > -1) {
          // console.log(this.editedItem);
          // return;
          // Object.assign(this.packages[this.editedIndex], this.editedItem)
          // eslint-disable-next-line no-unreachable
          this.$store.dispatch("empresas/updatePackage", this.editedItem)
						.then(() => {
							Swal.fire({
								title: "Sucesso!",
								text: "Pacote editado com sucesso",
								icon: "success",
								confirmButtonText: "Ok",
								confirmButtonColor: "#FF1744",
							}).then(() => {
								this.getPackages()
								this.close()
							})
						})
          // console.log(this.editedItem);
					// console.log('editing');
        } else {
          // this.desserts.push(this.editedItem)
					// console.log(this.editedItem);
          // console.log(this.editedItem);
          // return;
          // eslint-disable-next-line no-unreachable
					this.$store.dispatch("empresas/createPackage", this.editedItem)
						.then(() => {
							Swal.fire({
								title: "Sucesso!",
								text: "Pacote adicionado com sucesso",
								icon: "success",
								confirmButtonText: "Ok",
								confirmButtonColor: "#FF1744",
							}).then(() => {
								this.getPackages()
								this.close()
							})
						})
        }
        
      },
    },
  }
</script>

<style>

</style>