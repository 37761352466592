<template>
    <v-row justify="center">
		<v-col cols="12" sm="8" md="8">
            <vaga-form :updating="true" :vaga="content" :toAprove="toAprove"/>
        </v-col>
	</v-row>
</template>

<script>
//import vagaForm from './partials/vagaForm.vue'
import VagaForm from "./partials/vagaForm.vue"
export default {
  components: { VagaForm },
    
    props: {
        id: {
            required: true
        },
        toAprove: {
            required: false,
			type: Boolean,
			default: false,
        }
    },
    data() {
        return {
            content: {
				id: "",
				image: "",
				titulo: "",
				instituicao: "",
				validade: "",
				categoria_id: "",
				local: "",
				fonte: "",
				conteudo: "",
			},
        }
    },
    created() {
        this.getVaga(this.id)
    },
    methods: {
        getVaga(id) {
            this.$store.dispatch("vagas/getVaga", id)
            .then((res) => {
                this.content = res.data.data
            })
        }
    }
}
</script>

<style>

</style>