<template>
    <v-container>
        <v-row wrap align="center" justify="space-between">
            <v-col cols="12" md="4">
                <v-dialog
                transition="dialog-bottom-transition"
                width="600px"
                >
                <template v-slot:activator="{ on, attrs }">
					<v-btn
						small
						elevation="4"
                        v-bind="attrs"
                        v-on="on"
						dark
						color="red acent-3"
						tile
					>
						<v-icon small class="mr-2">mdi-plus</v-icon>
						Novo usuario
					</v-btn>
                </template>
                <template v-slot:default="dialog">
                    <v-toolbar
                    color="#e61f31"
                    dark
                    >
					<v-row>
                        <v-col cols="10">
                            Novo utilizador
                        </v-col>
                        <v-col cols="2">
                            <v-btn color="#e61f31" @click="dialog.value = false">
                                <v-icon color="" large>
                                    mdi-close
                                </v-icon>
                            </v-btn>
                        </v-col>				
                    </v-row>
				</v-toolbar>
                <v-card>
                    <new-user v-on:close-dialog="dialog.value = false"/>
                </v-card>
                </template>
                </v-dialog>
			</v-col>

				<v-col md="3">
					
				</v-col>
        </v-row>
        <v-row wrap justify="space-between">
			<v-col
				sm="12"
				md="12"
				class="mb-3"
			>
					<users-component :users="users"/>
			</v-col>
		</v-row>
    </v-container>
    
</template>

<script>
import UsersComponent from "./usersComponent.vue"
import NewUser from "./newUserForm.vue"
export default {
    components: {UsersComponent, NewUser},
    data() {
        return {

        }
    },
    created() {
        this.getUsers()
    },
    methods: {
        getUsers(){
            this.$store.dispatch("admin/getUsers")
        }
    },
    computed: {
        users() {
            return this.$store.state.admin.items
        }
    }
}
</script>

<style>

</style>