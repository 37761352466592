<template>
    <v-container>
        <v-row wrap align="center" justify="space-between">
            <v-col cols="12" md="6">
				<v-btn
					router
					small
					elevation="4"
                    :to="{name: 'admin.careers.create'}"
					dark
					color="red acent-3"
                    tile
				>
					Criar pagina de careiras
					<v-icon small class="mr-2">mdi-plus</v-icon>
			
				</v-btn>
            </v-col>
        </v-row>
		<v-row wrap>
			<v-col cols="12" md="3" v-for="career in careers" :key="career.id">
                <career-card :career="career"/>
            </v-col>
		</v-row>
    </v-container>
</template>

<script>
/*eslint-disable no-unused-vars*/
import {BASE_URL} from '../../../services/config'
import axios from 'axios'
import careerCard from './careerCard.vue'
export default {
	components: {careerCard},
	data() {
		return {
			careers: []
		}
	},
	created() {
		this.getCareers()
	},
	methods: {
		async getCareers() {
			try {
                const config = {
                    headers: {
                        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
                    }
                }
                this.$store.dispatch('loading/showLoader')
				this.careers = await (await axios.get(`${BASE_URL}/admin/careers`, config)).data.data
                this.$store.dispatch('loading/closeLoader')
			} catch (error) {
				console.log(error)
			}
		}
	}

}
</script>

<style>

</style>