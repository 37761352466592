<template>
    <validation-observer ref="observer" v-slot="{  }">
        <v-form @submit.prevent="onSubmit">
            <v-card>
                <v-card-title>
					<!--<h4 class="grey--text font-weight-regular">concurso</h4>-->
					<v-chip small label dark :color="'green darken-1'">concurso</v-chip>
				</v-card-title>
                <v-card-text>
					<v-row>
						<v-col cols="6">
							<v-file-input
								ref="Anexar imagem"
								dense
								show-size
								small-chips
								truncate-length="20"
								label="Anexo"
								accept="image/*"
								@change="onAnexoImage"
							></v-file-input>
						</v-col>
						<v-col cols="4">
							<v-btn
								depressed
								color="primary"
								@click="preencher()"
							>
								Auto Preenchimento
							</v-btn>
						</v-col>
					</v-row>
					<v-row wrap v-if="empresa.image">
						<v-col cols="12" md="12" class="text-center">
							<v-avatar size="120">
								<img :src="empresa.image" alt="" />
							</v-avatar>
						</v-col>

					</v-row>
				<!-- upload image manually -->
                    <!-- <v-row wrap v-if="imageUploadPreview">
						<v-col cols="12" md="12" class="text-center">
							<v-avatar size="120">
								<img :src="imageUploadPreview" alt="" />
							</v-avatar>
						</v-col>
					</v-row>
					<v-row wrap v-else>
						<v-col cols="12" md="12" class="text-center">
							<label for="file" class="inputFile">
								<v-avatar size="120">
									<img :src="require('../../../../../assets/img/add-camera.png')" alt="" />
								</v-avatar>
							</label>
						</v-col>
					</v-row>
					<v-row wrap>
						<v-col cols="12" md="12">
							<v-file-input
								ref="image_upload"
								dense
								truncate-length="1"
								label="Carregar Imagem"
								accept="image"
								@change="onFileChange"
								id="file"
								v-show="hidden"
							></v-file-input>
						</v-col>
					</v-row> -->
					<!-- end -->
                    <v-row wrap>
						<v-col cols="12" md="12">
							<validation-provider
								v-slot="{ errors }"
								name="Título"
								rules="required"
							>
								<v-text-field
									ref="titulo"
									dense
									v-model="concurso.titulo"
									label="Título"
									placeholder="Título do anúncio"
									:error-messages="errors"
									required
								></v-text-field>
							</validation-provider>
						</v-col>
					</v-row>
                    <v-row wrap>
						<!-- <v-col cols="12" md="6">
							<validation-provider
								v-slot="{ errors }"
								name="Instituição"
								rules="required"
							>
								<v-text-field
									ref="instituicao"
									dense
									v-model="concurso.instituicao"
									label="Instituição"
									placeholder="Nome da instituição"
									:error-messages="errors"
									required
								></v-text-field>
							</validation-provider>
						</v-col> -->
						<v-col cols="12" md="6">
							<validation-provider
								v-slot="{ errors }"
								name="Intituicao"
								rules="required"
							>
								<!-- <v-select
									ref="Instituição"
									dense
									:error-messages="errors"
									v-model="selected_empresa"
									:items="empresas"
									item-text="nome"
									item-value="id"
									label="Instituição"
									class="mt-0"
								></v-select> -->
								<v-autocomplete
								v-model="selected_empresa"
								:items="empresas"
								item-text="nome"
								item-value="id"
								label="Instituição"
								:error-messages="errors"
								no-data-text="Nenhuma Instituição encontrada"
								class="mt-0"
								/>
							</validation-provider>
						</v-col>
						<v-col cols="12" md="6">
							<v-menu
								ref="menu"
								v-model="menu"
								:close-on-content-click="false"
								:return-value.sync="date"
								transition="scale-transition"
								offset-y
								min-width="auto"
							>
								<template v-slot:activator="{ on, attrs }">
									<v-text-field
										dense
										v-model="concurso.validade"
										label="Validade"
										prepend-icon="mdi-calendar"
										readonly
										v-bind="attrs"
										v-on="on"
									></v-text-field>
								</template>
								<v-date-picker
									dense
									v-model="concurso.validade"
									no-title
									scrollable
								>
									<v-spacer></v-spacer>
									<v-btn text color="primary" @click="menu = false">
										Cancel
									</v-btn>
									<v-btn text color="primary" @click="$refs.menu.save(date)">
										OK
									</v-btn>
								</v-date-picker>
							</v-menu>
						</v-col>
					</v-row>
                    <v-row wrap>
						<!-- <v-col cols="12" md="6">
							<validation-provider
							v-slot="{ errors }"
							name="fonte"
							rules="required"
							>
								
								<v-text-field
									ref="Fonte"
									dense
									:error-messages="errors"
									v-model="concurso.fonte"
									label="Fonte"
									placeholder="Fonte do anúncio"
								></v-text-field>
							</validation-provider>
						</v-col> -->
						<v-col cols="12" md="12">
							<validation-provider
								v-slot="{ errors }"
								name="Local"
								rules="required"
							>
								<v-select
									ref="local"
									dense
									:error-messages="errors"
									v-model="selected_locals"
									:items="locals"
									item-text="name"
									item-value="id"
									multiple
									chips
									label="Local"
									class="mt-0"
								></v-select>
							</validation-provider>
						</v-col>
					</v-row>
					<!-- <v-row wrap>
						<v-col md="12">
							<validation-provider
								v-slot="{ errors }"
								name="industrias"
								rules="required"
							>
								<v-autocomplete
								v-model="concurso.industria"
								:items="industrias"
								
								label="Industria"
								:error-messages="errors"
								no-data-text="Nenhuma industria encontrada"
								class="mt-0"
								/>
							</validation-provider>
						</v-col>
					</v-row> -->
					<v-row wrap>
						<v-col md="12">
							<validation-provider
								v-slot="{ errors }"
								name="Categorias"
								rules="required"
							>
								<v-autocomplete
								v-model="concurso.content_category_id"
								:items="categories"
								item-text="name"
								item-value="id"
								
								label="Categorias"
								:error-messages="errors"
								no-data-text="Nenhuma categoria encontrada"
								class="mt-0"
								/>
							</validation-provider>
						</v-col>
					</v-row>
                    <v-row  wrap v-if="!anexoConteudoUploadPreview">
						<v-col md="12">
							
							<template>
								<v-card>
									<v-tabs
									v-model="tab"
									background-color=""
									centered
									
									icons-and-text>
										<v-tabs-slider></v-tabs-slider>
										<v-tab href="#tab-1">
											Conteudo não formatado
										</v-tab>
										<v-tab href="#tab-2">
											Conteudo formatado
										</v-tab>
									</v-tabs>
									<v-tabs-items v-model="tab">
										<v-tab-item value="tab-1">
											<v-textarea
												ref="conteudo"
												auto-grow
												dense
												clearable
												clear-icon="mdi-close-circle"
												label="Descriçao"
												v-model="concurso.conteudo"
												value="This is clearable text."
												style="padding:10px; max-height:300px;"
											></v-textarea>
										</v-tab-item>
										<v-tab-item value="tab-2">
											<vue-editor
											ref="conteudo" 
											placeholder="Descrição do anuncio..." 
											style="height: 300px; margin-top: 50px;"
											:editor-toolbar="customToolbar"
											v-model="concurso.conteudo_formatado">
											</vue-editor>
										</v-tab-item>

									</v-tabs-items>
								</v-card>
							</template>
							<!--<vue-editor 
							ref="conteudo" 
							placeholder="Descrição do anuncio..." 
							style="height: 250px;"
							:editor-toolbar="customToolbar"
							v-model="concurso.conteudo"></vue-editor>-->
						</v-col>
					</v-row>
					
                    <v-row wrap v-if="anexoConteudoUploadPreview">
						<v-col cols="12" md="12" class="text-center">
							<v-img>
								<img placeholder="Anexo" :src="anexoConteudoUploadPreview" class="anexo" alt="" />
							</v-img>
						</v-col>
					</v-row>
                    <v-row wrap style="margin-top:50px;">
						<v-col cols="12" md="12">
							<v-file-input
								ref="anexo_conteudo"
								dense
								show-size
								small-chips
								truncate-length="20"
								label="Anexo"
								accept="image/*"
								@change="onAnexoConteudoFileChange"
							></v-file-input>
						</v-col>
						<v-col cols="6">
							<v-checkbox
							v-model="concurso.is_recomended"
							label="Concurso recomendado"
							></v-checkbox>
						</v-col>
						<v-col cols="6">
							<v-checkbox
							v-model="concurso.not_free"
							label="Concorso pago"
							></v-checkbox>
						</v-col>
					</v-row>
                </v-card-text>
                <v-card-actions>
					<v-btn type="submit" small elevation="4" color="red accent-3" dark>
						<v-icon small class="mr-2"> mdi-check </v-icon>
						{{ actionSubmit }}
					</v-btn>
					<v-btn
						:to="{ name: !toAprove ? 'admin.concursos' : 'admin.concursos-toaprove' }"
						exact
						outlined
						small
						elevation="4"
						color="red accent-3"
						dark
						class="mx-4"
					>
						<v-icon small class="mr-2"> mdi-cancel </v-icon>
						Cancelar
					</v-btn>
					<v-btn
						outlined
						small
						elevation="4"
						color="red accent-3"
						dark
						@click.prevent="clean"
						class="ml-0"
					>
						<v-icon small class="mr-2"> mdi-eraser </v-icon>
						Limpar
					</v-btn>
				</v-card-actions>
            </v-card>
        </v-form>
    </validation-observer>
</template>

<script>
import {
	ValidationProvider,
	ValidationObserver,
	setInteractionMode,
	extend,
} from "vee-validate";
import { VueEditor  } from 'vue2-editor';
import { required, digits, email, max, regex } from "vee-validate/dist/rules";
import Swal from "sweetalert2";
import axios from "axios";
import { BASE_URL } from "../../../../../services/config";


setInteractionMode("eager");

extend("digits", {
	...digits,
	message: "{_field_} needs to be {length} digits. ({_value_})",
});

extend("required", {
	...required,
	message: "{_field_} não pode estar vazio",
});

extend("max", {
	...max,
	message: "{_field_} may not be greater than {length} characters",
});

extend("regex", {
	...regex,
	message: "{_field_} {_value_} does not match {regex}",
});

extend("email", {
	...email,
	message: "Email must be valid",
});
export default {
    components: {
        ValidationProvider,
		ValidationObserver,
		VueEditor 
    },
    props: {
        concurso: {
            required: false,
            // eslint-disable-next-line vue/require-prop-type-constructor
            type: Object | Array,
            default: () => {
                return {
                    id: "",
					image: "",
					titulo: "",
					instituicao: "",
					is_recomended: false,
					not_free: true,
					candidaturas: false,
					validade: "",
					categoria_id: 2,
					local: "",
					locals: [],
					fonte: "-",
					conteudo: "",
					conteudo_formatado: "",
					anexo_conteudo: "",
					industria: "",
					content_category_id: ""
                }
            }
        },
        updating: {
			required: true,
			type: Boolean,
			default: false,
		},
        toAprove: {
            required: false,
			type: Boolean,
			default: false,
        }
    },
    data() {
        return {
			selected_locals: [],
			selected_empresa: null,
			empresa: {
				nome: '',
				image: '',
				id: null
			},
            date: new Date().toISOString().substr(0, 10),
			menu: false,
			tab: null,
            image_upload: null,
            anexo_conteudo_upload: null,
			image: null,
			hidden: false,
			basetest: 'test',
            /*locals: [
                'Cabo Delgado',
                'Gaza',
                'Inhambane',
                'Manica',
                'Maputo',
                'Nampula',
                'Niassa',
                'Sofala',
                'Tete',
                'Zambeze'
            ],*/
			customToolbar: [
				[{header: [false, 1, 2, 3, 4, 5, 6]}],
				["bold", "italic", "underline"],
				[
					{ align: "" },
					{ align: "center" },
					{ align: "right" },
					{ align: "justify" }
				],
				[{list: 'bullet'}, {list: "ordered"}, { list: "check" }],
				[{color: []}],
				["link"]
			],
			industrias: [
            'Administração e Secretariado Cliente',
            'Aquisições e Procurement',
            'Assistente',          
            'Auditoria e Consultoria',
            'Comunicação Social',
            'Construção Civil',
            'Contabilidade e Finanças',
            'Design',
            'Direito e Justiça',
            'Electricidade',
            'Gestão de Projectos',
            'Gestão e Assessoria',
            'Gestão Financeira',
            'Gestor Provincial',
            'Higiene e Segurança',
            'Hotelaria e Turismo',
            'informática e Programação',
            'Manutenção e Técnica',
            'Marketing e Publicidade',
            'Mecânica',
            'Meio Ambiente',
            'Monitoria e Avaliação',
            'Oficial Técnico',
            'Operário',
            'Recursos Humanos',
            'Relações Públicas',
            'Saúde',
            'Seguros',
            'Serviços Gerais',
            'Serviços Sociais',
            'Supervisão e Coordenação',
            'Topografia e Geologia',
            'Transportes e Logística',
            'Vendas/Comércio',
			'Outro'
			],
			categories: [],
        }
    },
	created() {
		this.getCreateContent()
		this.getCategories()
		//console.log(this.content)
	},
    methods: {
		async preencher() {
			var reader = new FileReader();
			return new Promise((resolve, reject) => {
				reader.onerror = () => {
					reader.abort();
					reject("Problem parsing file")
				}
				reader.onload = () => {
					resolve(reader.result)
				}
				reader.readAsDataURL(this.image);

			}).then(res => {
				// console.log(res);
				axios.post('https://ia.marrar.co.mz/api/openai-concurso', {
					"base64image": res
				}).then(response => {
					// console.log(res);
					this.concurso.titulo = response.data.titulo
					this.concurso.instituicao = response.data.instituicao
					this.concurso.validade = response.data.data_validade
					this.concurso.conteudo_formatado = response.data.descricao
					let locals = this.$store.state.local.items.locals.filter(loc => loc.name.includes(response.data.local))
					console.log(locals)
					this.concurso.locals = locals
					this.selected_locals = locals.map(loc => loc.id)
				})
				
			})
			
			
			
		},
        onSubmit() {
            this.$refs.observer.validate();

			
			let action = this.updating
				? "concursos/updateConcurso"
				: "concursos/storeConcurso";

            const formData = new FormData();

			formData.append("id", this.concurso.id);
			formData.append("titulo", this.concurso.titulo);
			formData.append("instituicao", this.empresa.nome);
			formData.append("conteudo", this.concurso.conteudo);
			formData.append("conteudo_formatado", this.concurso.conteudo_formatado);
			formData.append("validade", this.concurso.validade);
			formData.append("categoria_id", this.concurso.categoria_id);
			formData.append('is_recomended', this.concurso.is_recomended);
			formData.append('not_free', this.concurso.not_free);
			formData.append('candidaturas', this.concurso.candidaturas);
			formData.append("locals", JSON.stringify(this.selected_locals));
			formData.append("fonte", this.concurso.fonte);
			formData.append("industria", this.concurso.industria);
			formData.append("image", this.empresa.image);
			formData.append("content_category_id", this.concurso.content_category_id);

			// if (this.image_upload != null) {
			// 	formData.append("image", this.image_upload);
			// } else {
			// 	formData.append("image", this.concurso.image);
			// }

			if (this.anexo_conteudo_upload != null) {
				formData.append("anexo_conteudo", this.anexo_conteudo_upload);
			} else {
				formData.append("anexo_conteudo", this.concurso.anexo_conteudo);
			}

            this.$store.dispatch(action, formData).then(() => {
				Swal.fire({
					title: "Sucesso!",
					text: this.updating
						? "Actualizado com sucesso."
						: "Submetido com sucesso.",
					icon: "success",
					confirmButtonText: "Ok",
					confirmButtonColor: "#FF1744",
				}).then(() => {
					this.$router.push({ name: !this.toAprove ? "admin.concursos" : "admin.concursos-toaprove"});
				});
			});
			
        },
        onFileChange(e) {
			this.image_upload = e;
		},

		onAnexoConteudoFileChange(e) {
			this.anexo_conteudo_upload = e;
		},
		onAnexoImage(e) {
			this.image = e;
		},
        clean() {
			this.concurso.titulo = "";
			this.concurso.instituicao = "";
			this.concurso.validade = "";
			this.concurso.local = "";
			this.concurso.fonte = "";
			this.concurso.conteudo = "";
			this.$refs.observer.reset();
		},
		getCreateContent() {
			this.$store.dispatch("concursos/getCreateContent");
		},
		async getCategories() {
			this.$store.dispatch('loading/showLoader')
			try {
				const config = {
					headers: {
						Authorization: `Bearer ${sessionStorage.getItem('token')}`,
					}
				}
				this.categories = (await axios.get(`${BASE_URL}/admin/content-category`, config)).data.data
				console.log(this.categories);
				this.$store.dispatch('loading/closeLoader')
			} catch (error) {
				await Swal.fire(
					{
						// position: "top-end",
						icon: "warning",
						title: "Oppsss, algo deu errado!",
						showConfirmButton: false,
						timer: 1500
					}
				)
				this.$store.dispatch('loading/closeLoader')
			}

		}
    },
    computed: {
		
        actionSubmit() {
			return this.updating ? "Actualizar" : "Salvar";
		},
		// eslint-disable-next-line vue/no-dupe-keys
		locals() {
			return this.$store.state.local.items.locals;
		},
		empresas() {
			return this.$store.state.local.items.empresas;
		},
		// eslint-disable-next-line vue/return-in-computed-property
		imageUploadPreview() {
			if (this.image_upload) {
				return URL.createObjectURL(this.image_upload);
			} else if (this.concurso.image) {
				return this.concurso.image;
			}
			return
		},
		// eslint-disable-next-line vue/return-in-computed-property
		anexoConteudoUploadPreview() {
			if (this.anexo_conteudo_upload) {
				return URL.createObjectURL(this.anexo_conteudo_upload);
			} else if (this.concurso.anexo_conteudo) {
				return this.concurso.anexo_conteudo;
			}
			return;
		},
		// eslint-disable-next-line vue/return-in-computed-property
		/*content_local() {
			if(this.concurso.locals) {
				// eslint-disable-next-line vue/no-side-effects-in-computed-properties
				this.selected_locals = this.concurso.locals.map((local) => local.id)
				console.log('ok')
				return
			}
			console.log('no')
			return
		}*/
    },

	watch: {
		concurso(concurso){
			console.log(concurso.locals);
			this.selected_locals = concurso.locals.map((local) => local.id)
		},
		selected_empresa(selected){
			this.empresa = this.empresas.find(em => em.id === selected)
			console.log(this.empresa)
		}
	},
}
</script>

<style>
.inputFile {
    cursor: pointer;
}

</style>