<template>
    <!-- <v-container> -->
    <v-row>
        <v-col cols="4">
            <v-card>
                <v-card-text>
                    <users-chart v-if="mount" :appName="'Gugla Empregos'" :objectData="empregosData" />
                </v-card-text>
            </v-card>
        </v-col>
        <v-col cols="4">
            <v-card>
                <v-card-text>
                    <users-chart v-if="mount" :appName="'Gugla Empresas'" :objectData="empresasData" />
                </v-card-text>
            </v-card>
        </v-col>
        <v-col cols="3" style="margin-left: 50px;">
            <v-card>
                <v-card-text>
                    <v-list v-if="favorite_content">
                        <v-list-item>
                            <v-list-item-title>{{ favorite_content.favorite_content }} concursos <v-icon
                                    color="yellow">mdi-star</v-icon> </v-list-item-title>
                            <!-- <v-list-item-icon> -->

                            <!-- </v-list-item-icon>  -->
                        </v-list-item>
                        <v-divider></v-divider>
                        <v-list-item>
                            <v-list-item-title>{{ favorite_content.recruiters_mark_favorite }} marcaram <v-icon
                                    color="yellow">mdi-star</v-icon></v-list-item-title>
                        </v-list-item>
                        <v-divider></v-divider>
                        <v-list-item>
                            <v-list-item-title>{{ total_access }} acessaram hoje <v-icon
                                    color="green">mdi-account-badge-outline</v-icon></v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-card-text>
            </v-card>
        </v-col>
        <v-col cols="6">
            <v-card>
                <v-card-text>
                    <span>Subscrição no Gugla Empresas</span>
                </v-card-text>
                <v-card-text>
                    <v-row justify="center" align="space-around">
                        <v-col cols="6">
                            <pie-chart v-if="mount" :appName="'Subscrições dos concursos'"
                                :objectData="empresasUsersSubscribe" />
                        </v-col>
                        <v-col cols="6">
                            <doughnut-chart v-if="mount" :appName="'Subscrições dos concursos'"
                                :objectData="recruiters_subscribeIndays" />
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-col>
        <v-col cols="12">

            <v-card>
                <v-card-text>
                    <span>Ultimos 20 utilizadores registados</span>
                </v-card-text>
                <v-card-text>
                    <v-row justify="center" align="space-around">
                        <v-col cols="12">

                            <empresas-users-component />
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-col>
        <!-- <v-col cols="6" style="margin-top: 300px;">
                <v-card>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12">
                                <favorite-content v-if="mount" :appName="'Concursos favoritos'" :objectData="favorite_content"/>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col> -->
    </v-row>
    <!-- </v-container> -->
</template>

<script>
import axios from 'axios'
import { BASE_URL } from '../../../services/config'
import usersChart from './charts/usersCharts.vue'
import PieChart from './charts/PieChart.vue'
import doughnutChart from './charts/doughnutChart.vue'
import empresasUsersComponent from './empresasUsersComponent.vue'
// import favoriteContent from './charts/favoriteContent.vue'
export default {
    components: { usersChart, PieChart, doughnutChart, empresasUsersComponent },
    data() {
        return {
            empregosData: {},
            empresasData: {},
            empresasUsersSubscribe: {},
            recruiters_subscribeIndays: {},
            favorite_content: {},
            total_access: 0,
            mount: false
        }
    },
    created() {
        this.getDatas()
    },
    methods: {
        async getDatas() {
            try {
                this.$store.dispatch('loading/showLoader')
                const config = {
                    headers: {
                        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
                    }
                }
                const data = await (await axios.get(`${BASE_URL}/admin/dashboard`, config)).data.data
                this.empregosData = data.empregos
                this.empresasData = data.empresas
                this.total_access = data.empresas.today_access
                this.empresasUsersSubscribe = data.empresas_subscribe
                this.recruiters_subscribeIndays = data.recruiters_subscribeIndays
                this.favorite_content = data.favorite_content
                // console.log(this.empregosData);
                // console.log(this.empresasData);
                this.mount = true
                this.$store.dispatch('loading/closeLoader')
            } catch (error) {
                console.log(error);
            }
        }
    }

}
</script>

<style></style>